@charset "UTF-8";
/*
Template Name: Hyper - Responsive Bootstrap 5 Admin Dashboard
Version: 3.0.0
Author: CoderThemes
Email: support@coderthemes.com
File: Main Css File
*/
/*!
 * Bootstrap v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #3d73dd;
  --bs-indigo: #727cf5;
  --bs-purple: #536de6;
  --bs-pink: #ff679b;
  --bs-red: #ff5b5b;
  --bs-orange: #fd7e14;
  --bs-yellow: #f9c851;
  --bs-green: #10c469;
  --bs-teal: #02a8b5;
  --bs-cyan: #35b8e0;
  --bs-white: #fff;
  --bs-gray: #98a6ad;
  --bs-gray-dark: #343a40;
  --bs-primary: #536de6;
  --bs-secondary: #6c757d;
  --bs-success: #10c469;
  --bs-info: #35b8e0;
  --bs-warning: #f9c851;
  --bs-danger: #ff5b5b;
  --bs-light: #eef2f7;
  --bs-dark: #323a46;
  --bs-font-sans-serif: "Nunito", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)); }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  background-color: #fafbfe;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: #eef2f7;
  background-color: currentColor;
  border: 0;
  opacity: 1; }

hr:not([size]) {
  height: 1px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.75rem;
  font-weight: 500;
  line-height: 1.1; }

h1, .h1 {
  font-size: calc(1.35rem + 1.2vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.25rem; } }

h2, .h2 {
  font-size: calc(1.3125rem + 0.75vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 1.875rem; } }

h3, .h3 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.5rem; } }

h4, .h4 {
  font-size: 1.125rem; }

h5, .h5 {
  font-size: 0.9375rem; }

h6, .h6 {
  font-size: 0.75rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.75rem; }

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #536de6;
  text-decoration: none; }
  a:hover {
    color: #475dc4; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 87.5%; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 87.5%;
  color: #35b8e0;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #323a46;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.95rem;
  padding-bottom: 0.95rem;
  color: #98a6ad;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.125rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.1; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.1; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.1; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.1; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.1; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.1; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 6px; }

.initialism {
  font-size: 0.75rem;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1.5rem;
  font-size: 1.125rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
  font-size: 0.75rem;
  color: #98a6ad; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.75rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.75rem;
  color: #98a6ad; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 12px);
  padding-left: var(--bs-gutter-x, 12px);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

.row {
  --bs-gutter-x: 24px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; } }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.375rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.375rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.75rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.75rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1.5rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1.5rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 2.25rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 2.25rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 4.5rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 4.5rem; }

@media (min-width: 576px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.375rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.375rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.75rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.75rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 2.25rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 2.25rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 4.5rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 4.5rem; } }

@media (min-width: 768px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.375rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.375rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.75rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.75rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1.5rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1.5rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 2.25rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 2.25rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 4.5rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 4.5rem; } }

@media (min-width: 992px) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.375rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.375rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.75rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.75rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 2.25rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 2.25rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 4.5rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 4.5rem; } }

@media (min-width: 1200px) {
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.375rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.375rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.75rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.75rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 2.25rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 2.25rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 4.5rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 4.5rem; } }

@media (min-width: 1400px) {
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.375rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.375rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.75rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.75rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 2.25rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 2.25rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 4.5rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 4.5rem; } }

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #6c757d;
  --bs-table-striped-bg: #f1f3fa;
  --bs-table-active-color: #6c757d;
  --bs-table-active-bg: rgba(222, 226, 230, 0.4);
  --bs-table-hover-color: #6c757d;
  --bs-table-hover-bg: #f1f3fa;
  width: 100%;
  margin-bottom: 1.5rem;
  color: #6c757d;
  vertical-align: top;
  border-color: #eef2f7; }
  .table > :not(caption) > * > * {
    padding: 0.95rem 0.95rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: #edeff1; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.5rem 0.5rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-bg: #dde2fa;
  --bs-table-striped-bg: #323a46;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #999fb2;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #d0d5ed;
  --bs-table-hover-color: #323a46;
  color: #323a46;
  border-color: #ccd1e8; }

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #323a46;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #9c9fa5;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #d5d6d9;
  --bs-table-hover-color: #323a46;
  color: #323a46;
  border-color: #d0d2d5; }

.table-success {
  --bs-table-bg: #cff3e1;
  --bs-table-striped-bg: #323a46;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #90a9a3;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #c3e5d5;
  --bs-table-hover-color: #323a46;
  color: #323a46;
  border-color: #bfe1d2; }

.table-info {
  --bs-table-bg: #d7f1f9;
  --bs-table-striped-bg: #323a46;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #95a8b1;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #cbe3ec;
  --bs-table-hover-color: #323a46;
  color: #323a46;
  border-color: #c7dfe7; }

.table-warning {
  --bs-table-bg: #fef4dc;
  --bs-table-striped-bg: #323a46;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #acaaa0;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #efe6d1;
  --bs-table-hover-color: #323a46;
  color: #323a46;
  border-color: #eae1cd; }

.table-danger {
  --bs-table-bg: #ffdede;
  --bs-table-striped-bg: #323a46;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #ad9ca1;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #f0d2d3;
  --bs-table-hover-color: #323a46;
  color: #323a46;
  border-color: #ebcecf; }

.table-light {
  --bs-table-bg: #eef2f7;
  --bs-table-striped-bg: #323a46;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #a3a8b0;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #e0e4ea;
  --bs-table-hover-color: #323a46;
  color: #323a46;
  border-color: #dbe0e5; }

.table-dark {
  --bs-table-bg: #323a46;
  --bs-table-striped-bg: white;
  --bs-table-striped-color: #323a46;
  --bs-table-active-bg: #848990;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #414954;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #474e59; }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.45rem + 1px);
  padding-bottom: calc(0.45rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: calc(1.2501rem + 0.0012vw); }
  @media (min-width: 1200px) {
    .col-form-label-lg {
      font-size: 1.251rem; } }

.col-form-label-sm {
  padding-top: calc(0.28rem + 1px);
  padding-bottom: calc(0.28rem + 1px);
  font-size: 0.875rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #98a6ad; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.45rem 0.9rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: #6c757d;
    background-color: #fff;
    border-color: #c8cbcf;
    outline: 0;
    box-shadow: none; }
  .form-control::-webkit-date-and-time-value {
    height: 1.5em; }
  .form-control::placeholder {
    color: #adb5bd;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #eef2f7;
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.45rem 0.9rem;
    margin: -0.45rem -0.9rem;
    margin-inline-end: 0.9rem;
    color: #6c757d;
    background-color: #eef2f7;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #e2e6eb; }
  .form-control::-webkit-file-upload-button {
    padding: 0.45rem 0.9rem;
    margin: -0.45rem -0.9rem;
    margin-inline-end: 0.9rem;
    color: #6c757d;
    background-color: #eef2f7;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::-webkit-file-upload-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #e2e6eb; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.45rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #6c757d;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + (0.56rem + 2px));
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }
  .form-control-sm::file-selector-button {
    padding: 0.28rem 0.8rem;
    margin: -0.28rem -0.8rem;
    margin-inline-end: 0.8rem; }
  .form-control-sm::-webkit-file-upload-button {
    padding: 0.28rem 0.8rem;
    margin: -0.28rem -0.8rem;
    margin-inline-end: 0.8rem; }

.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
  padding: 0.5rem 1rem;
  font-size: calc(1.2501rem + 0.0012vw);
  border-radius: 0.3rem; }
  @media (min-width: 1200px) {
    .form-control-lg {
      font-size: 1.251rem; } }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }
  .form-control-lg::-webkit-file-upload-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + (0.9rem + 2px)); }

textarea.form-control-sm {
  min-height: calc(1.5em + (0.56rem + 2px)); }

textarea.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px)); }

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.45rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    height: 1.5em;
    border-radius: 0.25rem; }
  .form-control-color::-webkit-color-swatch {
    height: 1.5em;
    border-radius: 0.25rem; }

.form-select {
  display: block;
  width: 100%;
  padding: 0.45rem 2.5rem 0.45rem 0.9rem;
  -moz-padding-start: calc(0.9rem - 3px);
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.9rem center;
  background-size: 16px 12px;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #c8cbcf;
    outline: 0;
    box-shadow: none; }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 0.9rem;
    background-image: none; }
  .form-select:disabled {
    color: #98a6ad;
    background-color: #eef2f7; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #6c757d; }

.form-select-sm {
  padding-top: 0.28rem;
  padding-bottom: 0.28rem;
  padding-left: 0.8rem;
  font-size: 0.875rem; }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: calc(1.2501rem + 0.0012vw); }
  @media (min-width: 1200px) {
    .form-select-lg {
      font-size: 1.251rem; } }

.form-check {
  display: block;
  min-height: 1.35rem;
  padding-left: 1.612em;
  margin-bottom: 0; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.612em; }

.form-check-input {
  width: 1.112em;
  height: 1.112em;
  margin-top: 0.194em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #dee2e6;
  appearance: none;
  color-adjust: exact;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-check-input {
      transition: none; } }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #c8cbcf;
    outline: 0;
    box-shadow: none; }
  .form-check-input:checked {
    background-color: #536de6;
    border-color: #536de6; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #536de6;
    border-color: #536de6;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23dee2e6'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23c8cbcf'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1.2rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fafbfe, none; }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fafbfe, none; }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #536de6;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #cbd3f8; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #536de6;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #cbd3f8; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.9rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control {
    padding: 1rem 0.9rem; }
    .form-floating > .form-control::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus {
    z-index: 3; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 3; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.45rem 0.9rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  text-align: center;
  white-space: nowrap;
  background-color: #eef2f7;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.2501rem + 0.0012vw);
  border-radius: 0.3rem; }
  @media (min-width: 1200px) {
    .input-group-lg > .form-control,
    .input-group-lg > .form-select,
    .input-group-lg > .input-group-text,
    .input-group-lg > .btn {
      font-size: 1.251rem; } }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3.4rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #10c469; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.4rem 0.8rem;
  margin-top: .1rem;
  font-size: 0.8125rem;
  color: #fff;
  background-color: rgba(16, 196, 105, 0.9);
  border-radius: 0.2rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #10c469;
  padding-right: calc(1.5em + 0.9rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2310c469' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.225rem) center;
  background-size: calc(0.75em + 0.45rem) calc(0.75em + 0.45rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #10c469;
    box-shadow: 0 0 0 0.1rem rgba(16, 196, 105, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.9rem);
  background-position: top calc(0.375em + 0.225rem) right calc(0.375em + 0.225rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #10c469; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: calc(0.75em + 4.075rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2310c469' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.9rem center, center right 3.4rem;
    background-size: 16px 12px, calc(0.75em + 0.45rem) calc(0.75em + 0.45rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #10c469;
    box-shadow: 0 0 0 0.1rem rgba(16, 196, 105, 0.25); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #10c469; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #10c469; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.1rem rgba(16, 196, 105, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #10c469; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1; }
  .was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated
  .input-group .form-select:valid:focus,
  .input-group .form-select.is-valid:focus {
    z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #ff5b5b; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.4rem 0.8rem;
  margin-top: .1rem;
  font-size: 0.8125rem;
  color: #fff;
  background-color: rgba(255, 91, 91, 0.9);
  border-radius: 0.2rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ff5b5b;
  padding-right: calc(1.5em + 0.9rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff5b5b'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff5b5b' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.225rem) center;
  background-size: calc(0.75em + 0.45rem) calc(0.75em + 0.45rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #ff5b5b;
    box-shadow: 0 0 0 0.1rem rgba(255, 91, 91, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.9rem);
  background-position: top calc(0.375em + 0.225rem) right calc(0.375em + 0.225rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #ff5b5b; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: calc(0.75em + 4.075rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff5b5b'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff5b5b' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.9rem center, center right 3.4rem;
    background-size: 16px 12px, calc(0.75em + 0.45rem) calc(0.75em + 0.45rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #ff5b5b;
    box-shadow: 0 0 0 0.1rem rgba(255, 91, 91, 0.25); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #ff5b5b; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #ff5b5b; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.1rem rgba(255, 91, 91, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #ff5b5b; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2; }
  .was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated
  .input-group .form-select:invalid:focus,
  .input-group .form-select.is-invalid:focus {
    z-index: 3; }

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.45rem 0.9rem;
  font-size: 0.9rem;
  border-radius: 0.15rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #6c757d; }
  .btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba(83, 109, 230, 0.25); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65; }

.btn-primary {
  color: #fff;
  background-color: #536de6;
  border-color: #536de6; }
  .btn-primary:hover {
    color: #fff;
    background-color: #475dc4;
    border-color: #4257b8; }
  .btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #fff;
    background-color: #475dc4;
    border-color: #4257b8;
    box-shadow: 0 0 0 0.15rem rgba(109, 131, 234, 0.5); }
  .btn-check:checked + .btn-primary,
  .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #4257b8;
    border-color: #3e52ad; }
    .btn-check:checked + .btn-primary:focus,
    .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(109, 131, 234, 0.5); }
  .btn-primary:disabled, .btn-primary.disabled {
    color: #fff;
    background-color: #536de6;
    border-color: #536de6; }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64; }
  .btn-check:focus + .btn-secondary, .btn-secondary:focus {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64;
    box-shadow: 0 0 0 0.15rem rgba(130, 138, 145, 0.5); }
  .btn-check:checked + .btn-secondary,
  .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #565e64;
    border-color: #51585e; }
    .btn-check:checked + .btn-secondary:focus,
    .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(130, 138, 145, 0.5); }
  .btn-secondary:disabled, .btn-secondary.disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }

.btn-success {
  color: #fff;
  background-color: #10c469;
  border-color: #10c469; }
  .btn-success:hover {
    color: #fff;
    background-color: #0ea759;
    border-color: #0d9d54; }
  .btn-check:focus + .btn-success, .btn-success:focus {
    color: #fff;
    background-color: #0ea759;
    border-color: #0d9d54;
    box-shadow: 0 0 0 0.15rem rgba(52, 205, 128, 0.5); }
  .btn-check:checked + .btn-success,
  .btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #0d9d54;
    border-color: #0c934f; }
    .btn-check:checked + .btn-success:focus,
    .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(52, 205, 128, 0.5); }
  .btn-success:disabled, .btn-success.disabled {
    color: #fff;
    background-color: #10c469;
    border-color: #10c469; }

.btn-info {
  color: #fff;
  background-color: #35b8e0;
  border-color: #35b8e0; }
  .btn-info:hover {
    color: #fff;
    background-color: #2d9cbe;
    border-color: #2a93b3; }
  .btn-check:focus + .btn-info, .btn-info:focus {
    color: #fff;
    background-color: #2d9cbe;
    border-color: #2a93b3;
    box-shadow: 0 0 0 0.15rem rgba(83, 195, 229, 0.5); }
  .btn-check:checked + .btn-info,
  .btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #2a93b3;
    border-color: #288aa8; }
    .btn-check:checked + .btn-info:focus,
    .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(83, 195, 229, 0.5); }
  .btn-info:disabled, .btn-info.disabled {
    color: #fff;
    background-color: #35b8e0;
    border-color: #35b8e0; }

.btn-warning {
  color: #323a46;
  background-color: #f9c851;
  border-color: #f9c851; }
  .btn-warning:hover {
    color: #323a46;
    background-color: #fad06b;
    border-color: #face62; }
  .btn-check:focus + .btn-warning, .btn-warning:focus {
    color: #323a46;
    background-color: #fad06b;
    border-color: #face62;
    box-shadow: 0 0 0 0.15rem rgba(219, 179, 79, 0.5); }
  .btn-check:checked + .btn-warning,
  .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    color: #323a46;
    background-color: #fad374;
    border-color: #face62; }
    .btn-check:checked + .btn-warning:focus,
    .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(219, 179, 79, 0.5); }
  .btn-warning:disabled, .btn-warning.disabled {
    color: #323a46;
    background-color: #f9c851;
    border-color: #f9c851; }

.btn-danger {
  color: #fff;
  background-color: #ff5b5b;
  border-color: #ff5b5b; }
  .btn-danger:hover {
    color: #fff;
    background-color: #d94d4d;
    border-color: #cc4949; }
  .btn-check:focus + .btn-danger, .btn-danger:focus {
    color: #fff;
    background-color: #d94d4d;
    border-color: #cc4949;
    box-shadow: 0 0 0 0.15rem rgba(255, 116, 116, 0.5); }
  .btn-check:checked + .btn-danger,
  .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #cc4949;
    border-color: #bf4444; }
    .btn-check:checked + .btn-danger:focus,
    .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(255, 116, 116, 0.5); }
  .btn-danger:disabled, .btn-danger.disabled {
    color: #fff;
    background-color: #ff5b5b;
    border-color: #ff5b5b; }

.btn-light {
  color: #323a46;
  background-color: #eef2f7;
  border-color: #eef2f7; }
  .btn-light:hover {
    color: #323a46;
    background-color: #f1f4f8;
    border-color: #f0f3f8; }
  .btn-check:focus + .btn-light, .btn-light:focus {
    color: #323a46;
    background-color: #f1f4f8;
    border-color: #f0f3f8;
    box-shadow: 0 0 0 0.15rem rgba(210, 214, 220, 0.5); }
  .btn-check:checked + .btn-light,
  .btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    color: #323a46;
    background-color: #f1f5f9;
    border-color: #f0f3f8; }
    .btn-check:checked + .btn-light:focus,
    .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(210, 214, 220, 0.5); }
  .btn-light:disabled, .btn-light.disabled {
    color: #323a46;
    background-color: #eef2f7;
    border-color: #eef2f7; }

.btn-dark {
  color: #fff;
  background-color: #323a46;
  border-color: #323a46; }
  .btn-dark:hover {
    color: #fff;
    background-color: #2b313c;
    border-color: #282e38; }
  .btn-check:focus + .btn-dark, .btn-dark:focus {
    color: #fff;
    background-color: #2b313c;
    border-color: #282e38;
    box-shadow: 0 0 0 0.15rem rgba(81, 88, 98, 0.5); }
  .btn-check:checked + .btn-dark,
  .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #282e38;
    border-color: #262c35; }
    .btn-check:checked + .btn-dark:focus,
    .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.15rem rgba(81, 88, 98, 0.5); }
  .btn-dark:disabled, .btn-dark.disabled {
    color: #fff;
    background-color: #323a46;
    border-color: #323a46; }

.btn-outline-primary {
  color: #536de6;
  border-color: #536de6; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #536de6;
    border-color: #536de6; }
  .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.15rem rgba(83, 109, 230, 0.5); }
  .btn-check:checked + .btn-outline-primary,
  .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #fff;
    background-color: #536de6;
    border-color: #536de6; }
    .btn-check:checked + .btn-outline-primary:focus,
    .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(83, 109, 230, 0.5); }
  .btn-outline-primary:disabled, .btn-outline-primary.disabled {
    color: #536de6;
    background-color: transparent; }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.15rem rgba(108, 117, 125, 0.5); }
  .btn-check:checked + .btn-outline-secondary,
  .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-check:checked + .btn-outline-secondary:focus,
    .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
    color: #6c757d;
    background-color: transparent; }

.btn-outline-success {
  color: #10c469;
  border-color: #10c469; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #10c469;
    border-color: #10c469; }
  .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
    box-shadow: 0 0 0 0.15rem rgba(16, 196, 105, 0.5); }
  .btn-check:checked + .btn-outline-success,
  .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #fff;
    background-color: #10c469;
    border-color: #10c469; }
    .btn-check:checked + .btn-outline-success:focus,
    .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(16, 196, 105, 0.5); }
  .btn-outline-success:disabled, .btn-outline-success.disabled {
    color: #10c469;
    background-color: transparent; }

.btn-outline-info {
  color: #35b8e0;
  border-color: #35b8e0; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #35b8e0;
    border-color: #35b8e0; }
  .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
    box-shadow: 0 0 0 0.15rem rgba(53, 184, 224, 0.5); }
  .btn-check:checked + .btn-outline-info,
  .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
    color: #fff;
    background-color: #35b8e0;
    border-color: #35b8e0; }
    .btn-check:checked + .btn-outline-info:focus,
    .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(53, 184, 224, 0.5); }
  .btn-outline-info:disabled, .btn-outline-info.disabled {
    color: #35b8e0;
    background-color: transparent; }

.btn-outline-warning {
  color: #f9c851;
  border-color: #f9c851; }
  .btn-outline-warning:hover {
    color: #323a46;
    background-color: #f9c851;
    border-color: #f9c851; }
  .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
    box-shadow: 0 0 0 0.15rem rgba(249, 200, 81, 0.5); }
  .btn-check:checked + .btn-outline-warning,
  .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
    color: #323a46;
    background-color: #f9c851;
    border-color: #f9c851; }
    .btn-check:checked + .btn-outline-warning:focus,
    .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(249, 200, 81, 0.5); }
  .btn-outline-warning:disabled, .btn-outline-warning.disabled {
    color: #f9c851;
    background-color: transparent; }

.btn-outline-danger {
  color: #ff5b5b;
  border-color: #ff5b5b; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #ff5b5b;
    border-color: #ff5b5b; }
  .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
    box-shadow: 0 0 0 0.15rem rgba(255, 91, 91, 0.5); }
  .btn-check:checked + .btn-outline-danger,
  .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
    color: #fff;
    background-color: #ff5b5b;
    border-color: #ff5b5b; }
    .btn-check:checked + .btn-outline-danger:focus,
    .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(255, 91, 91, 0.5); }
  .btn-outline-danger:disabled, .btn-outline-danger.disabled {
    color: #ff5b5b;
    background-color: transparent; }

.btn-outline-light {
  color: #eef2f7;
  border-color: #eef2f7; }
  .btn-outline-light:hover {
    color: #323a46;
    background-color: #eef2f7;
    border-color: #eef2f7; }
  .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
    box-shadow: 0 0 0 0.15rem rgba(238, 242, 247, 0.5); }
  .btn-check:checked + .btn-outline-light,
  .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #323a46;
    background-color: #eef2f7;
    border-color: #eef2f7; }
    .btn-check:checked + .btn-outline-light:focus,
    .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(238, 242, 247, 0.5); }
  .btn-outline-light:disabled, .btn-outline-light.disabled {
    color: #eef2f7;
    background-color: transparent; }

.btn-outline-dark {
  color: #323a46;
  border-color: #323a46; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #323a46;
    border-color: #323a46; }
  .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
    box-shadow: 0 0 0 0.15rem rgba(50, 58, 70, 0.5); }
  .btn-check:checked + .btn-outline-dark,
  .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
    color: #fff;
    background-color: #323a46;
    border-color: #323a46; }
    .btn-check:checked + .btn-outline-dark:focus,
    .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.15rem rgba(50, 58, 70, 0.5); }
  .btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #323a46;
    background-color: transparent; }

.btn-link {
  font-weight: 400;
  color: #536de6;
  text-decoration: none; }
  .btn-link:hover {
    color: #475dc4; }
  .btn-link:disabled, .btn-link.disabled {
    color: #adb5bd; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.2501rem + 0.0012vw);
  border-radius: 0.15rem; }
  @media (min-width: 1200px) {
    .btn-lg, .btn-group-lg > .btn {
      font-size: 1.251rem; } }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  border-radius: 0.15rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.2125em;
    vertical-align: 0.2125em;
    content: "";
    border-top: 0.25em solid;
    border-right: 0.25em solid transparent;
    border-bottom: 0;
    border-left: 0.25em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.25rem 0;
  margin: 0;
  font-size: 0.9rem;
  color: #6c757d;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e7ebf0;
  border-radius: 0.25rem; }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: 0.125rem; }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.2125em;
  vertical-align: 0.2125em;
  content: "";
  border-top: 0;
  border-right: 0.25em solid transparent;
  border-bottom: 0.25em solid;
  border-left: 0.25em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.2125em;
  vertical-align: 0.2125em;
  content: "";
  border-top: 0.25em solid transparent;
  border-right: 0;
  border-bottom: 0.25em solid transparent;
  border-left: 0.25em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.2125em;
  vertical-align: 0.2125em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.2125em;
  vertical-align: 0.2125em;
  content: "";
  border-top: 0.25em solid transparent;
  border-right: 0.25em solid;
  border-bottom: 0.25em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e7ebf0; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.375rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #6c757d;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #2d343f;
    background-color: #f4f6fb; }
  .dropdown-item.active, .dropdown-item:active {
    color: #323a46;
    text-decoration: none;
    background-color: #f2f5f9; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #98a6ad;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: inherit;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.375rem 1.5rem;
  color: #6c757d; }

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: #e7ebf0; }
  .dropdown-menu-dark .dropdown-item {
    color: #dee2e6; }
    .dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.15); }
    .dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
      color: #323a46;
      background-color: #f2f5f9; }
    .dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
      color: #adb5bd; }
  .dropdown-menu-dark .dropdown-divider {
    border-color: #e7ebf0; }
  .dropdown-menu-dark .dropdown-item-text {
    color: #dee2e6; }
  .dropdown-menu-dark .dropdown-header {
    color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.675rem;
  padding-left: 0.675rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.6rem;
  padding-left: 0.6rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #536de6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: #475dc4; }
  .nav-link.disabled {
    color: #98a6ad;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #eef2f7 #eef2f7 #dee2e6;
      isolation: isolate; }
    .nav-tabs .nav-link.disabled {
      color: #98a6ad;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #565e64;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #536de6; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: 0.23675rem;
  padding-bottom: 0.23675rem;
  margin-right: 1rem;
  font-size: calc(1.2501rem + 0.0012vw);
  white-space: nowrap; }
  @media (min-width: 1200px) {
    .navbar-brand {
      font-size: 1.251rem; } }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: calc(1.2501rem + 0.0012vw);
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.15rem;
  transition: box-shadow 0.15s ease-in-out; }
  @media (min-width: 1200px) {
    .navbar-toggler {
      font-size: 1.251rem; } }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.15rem; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: #676f77; }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: #343a40; }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: #adb5bd; }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: #676f77;
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23676f77' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: #676f77; }
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #eef2f7;
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1.5rem 1.5rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.5rem; }

.card-header {
  padding: 0.75rem 1.5rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid #eef2f7; }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.5rem;
  background-color: #fff;
  border-top: 1px solid #eef2f7; }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -0.75rem;
  margin-left: -0.75rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-group > .card {
  margin-bottom: 12px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 0.9rem;
  color: #6c757d;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: #4b62cf;
    background-color: #eef0fd;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125); }
    .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234b62cf'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      transform: rotate(180deg); }
  .accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236c757d'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: #c8cbcf;
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba(83, 109, 230, 0.25); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .accordion-item:first-of-type {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem; }

.accordion-body {
  padding: 1rem 1.25rem; }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button {
    border-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #ced4da;
    content: var(--bs-breadcrumb-divider, "󰅂") /* rtl: var(--bs-breadcrumb-divider, "󰅂") */; }

.breadcrumb-item.active {
  color: #adb5bd; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  color: #323a46;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: #323a46;
    background-color: #eef2f7;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    color: #475dc4;
    background-color: #eef2f7;
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba(83, 109, 230, 0.25); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #536de6;
  border-color: #536de6; }

.page-item.disabled .page-link {
  color: #98a6ad;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6; }

.page-link {
  padding: 0.375rem 0.75rem; }

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: calc(1.2501rem + 0.0012vw); }
  @media (min-width: 1200px) {
    .pagination-lg .page-link {
      font-size: 1.251rem; } }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.75rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.9375rem 1.25rem; }

.alert-primary {
  color: #32418a;
  background-color: #dde2fa;
  border-color: #cbd3f8; }
  .alert-primary .alert-link {
    color: #28346e; }

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8; }
  .alert-secondary .alert-link {
    color: #34383c; }

.alert-success {
  color: #0a763f;
  background-color: #cff3e1;
  border-color: #b7edd2; }
  .alert-success .alert-link {
    color: #085e32; }

.alert-info {
  color: #206e86;
  background-color: #d7f1f9;
  border-color: #c2eaf6; }
  .alert-info .alert-link {
    color: #1a586b; }

.alert-warning {
  color: #957831;
  background-color: #fef4dc;
  border-color: #fdefcb; }
  .alert-warning .alert-link {
    color: #776027; }

.alert-danger {
  color: #993737;
  background-color: #ffdede;
  border-color: #ffcece; }
  .alert-danger .alert-link {
    color: #7a2c2c; }

.alert-light {
  color: #8f9194;
  background-color: #fcfcfd;
  border-color: #fafbfd; }
  .alert-light .alert-link {
    color: #727476; }

.alert-dark {
  color: #1e232a;
  background-color: #d6d8da;
  border-color: #c2c4c8; }
  .alert-dark .alert-link {
    color: #181c22; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.675rem;
  background-color: #eef2f7;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #536de6;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > li::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: #6c757d;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #6c757d;
    text-decoration: none;
    background-color: #f1f3fa; }
  .list-group-item-action:active {
    color: #6c757d;
    background-color: #eef2f7; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  color: #323a46;
  background-color: #fff;
  border: 1px solid #eef2f7; }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #f5f7fc; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #536de6;
    border-color: #536de6; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #32418a;
  background-color: #cbd3f8; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #32418a;
    background-color: #b7bedf; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #32418a;
    border-color: #32418a; }

.list-group-item-secondary {
  color: #41464b;
  background-color: #d3d6d8; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #41464b;
    background-color: #bec1c2; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #41464b;
    border-color: #41464b; }

.list-group-item-success {
  color: #0a763f;
  background-color: #b7edd2; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #0a763f;
    background-color: #a5d5bd; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0a763f;
    border-color: #0a763f; }

.list-group-item-info {
  color: #206e86;
  background-color: #c2eaf6; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #206e86;
    background-color: #afd3dd; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #206e86;
    border-color: #206e86; }

.list-group-item-warning {
  color: #957831;
  background-color: #fdefcb; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #957831;
    background-color: #e4d7b7; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #957831;
    border-color: #957831; }

.list-group-item-danger {
  color: #993737;
  background-color: #ffcece; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #993737;
    background-color: #e6b9b9; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #993737;
    border-color: #993737; }

.list-group-item-light {
  color: #8f9194;
  background-color: #fafbfd; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #8f9194;
    background-color: #e1e2e4; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #8f9194;
    border-color: #8f9194; }

.list-group-item-dark {
  color: #1e232a;
  background-color: #c2c4c8; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1e232a;
    background-color: #afb0b4; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1e232a;
    border-color: #1e232a; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #323a46;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23323a46'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.65; }
  .btn-close:hover {
    color: #323a46;
    text-decoration: none;
    opacity: 0.8; }
  .btn-close:focus {
    outline: 0;
    box-shadow: none;
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  border-radius: 0.25rem; }
  .toast:not(.showing):not(.show) {
    opacity: 0; }
  .toast.hide {
    display: none; }

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: 12px; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #98a6ad;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }
  .toast-header .btn-close {
    margin-right: -0.375rem;
    margin-left: 0.75rem; }

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid transparent;
  border-radius: 0.2rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #323a46; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.7; }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 0 solid #dee2e6;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem; }
  .modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 0 solid #dee2e6;
  border-bottom-right-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }
  .modal-footer > * {
    margin: 0.25rem; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }
  .modal-fullscreen .modal-footer {
    border-radius: 0; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; } }

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
    bottom: 0; }
    .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
      top: -1px;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #343a40; }

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
      right: -1px;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #343a40; }

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
    top: 0; }
    .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
      bottom: -1px;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #343a40; }

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
      left: -1px;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #343a40; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.4rem 0.8rem;
  color: #f1f3fa;
  text-align: center;
  background-color: #343a40;
  border-radius: 0.2rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: 0.3rem; }
  .popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem; }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #ced4da; }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff; }

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #ced4da; }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff; }

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #ced4da; }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff; }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f1f3fa; }

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #ced4da; }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff; }

.popover-header {
  padding: 0.7rem 0.8rem;
  margin-bottom: 0;
  font-size: 0.9rem;
  background-color: #f1f3fa;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #6c757d; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem; }
  .offcanvas-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin-top: -0.5rem;
    margin-right: -0.5rem;
    margin-bottom: -0.5rem; }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5; }

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto; }

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 0 solid transparent;
  transform: translateX(-100%); }

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 0 solid transparent;
  transform: translateX(100%); }

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 0 solid transparent;
  transform: translateY(-100%); }

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 0 solid transparent;
  transform: translateY(100%); }

.offcanvas.show {
  transform: none; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.link-primary {
  color: #536de6; }
  .link-primary:hover, .link-primary:focus {
    color: #475dc4; }

.link-secondary {
  color: #6c757d; }
  .link-secondary:hover, .link-secondary:focus {
    color: #5c636a; }

.link-success {
  color: #10c469; }
  .link-success:hover, .link-success:focus {
    color: #0ea759; }

.link-info {
  color: #35b8e0; }
  .link-info:hover, .link-info:focus {
    color: #2d9cbe; }

.link-warning {
  color: #f9c851; }
  .link-warning:hover, .link-warning:focus {
    color: #fad06b; }

.link-danger {
  color: #ff5b5b; }
  .link-danger:hover, .link-danger:focus {
    color: #d94d4d; }

.link-light {
  color: #eef2f7; }
  .link-light:hover, .link-light:focus {
    color: #f1f4f8; }

.link-dark {
  color: #323a46; }
  .link-dark:hover, .link-dark:focus {
    color: #2b313c; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: 1px solid #dee2e6 !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: 1px solid #dee2e6 !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #536de6 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #10c469 !important; }

.border-info {
  border-color: #35b8e0 !important; }

.border-warning {
  border-color: #f9c851 !important; }

.border-danger {
  border-color: #ff5b5b !important; }

.border-light {
  border-color: #eef2f7 !important; }

.border-dark {
  border-color: #323a46 !important; }

.border-white {
  border-color: #fff !important; }

.border-0 {
  border-width: 0 !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.375rem !important; }

.gap-2 {
  gap: 0.75rem !important; }

.gap-3 {
  gap: 1.5rem !important; }

.gap-4 {
  gap: 2.25rem !important; }

.gap-5 {
  gap: 4.5rem !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.375rem !important; }

.m-2 {
  margin: 0.75rem !important; }

.m-3 {
  margin: 1.5rem !important; }

.m-4 {
  margin: 2.25rem !important; }

.m-5 {
  margin: 4.5rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.375rem !important;
  margin-left: 0.375rem !important; }

.mx-2 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important; }

.mx-3 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-4 {
  margin-right: 2.25rem !important;
  margin-left: 2.25rem !important; }

.mx-5 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.375rem !important;
  margin-bottom: 0.375rem !important; }

.my-2 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important; }

.my-3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-4 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important; }

.my-5 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.375rem !important; }

.mt-2 {
  margin-top: 0.75rem !important; }

.mt-3 {
  margin-top: 1.5rem !important; }

.mt-4 {
  margin-top: 2.25rem !important; }

.mt-5 {
  margin-top: 4.5rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.375rem !important; }

.me-2 {
  margin-right: 0.75rem !important; }

.me-3 {
  margin-right: 1.5rem !important; }

.me-4 {
  margin-right: 2.25rem !important; }

.me-5 {
  margin-right: 4.5rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.375rem !important; }

.mb-2 {
  margin-bottom: 0.75rem !important; }

.mb-3 {
  margin-bottom: 1.5rem !important; }

.mb-4 {
  margin-bottom: 2.25rem !important; }

.mb-5 {
  margin-bottom: 4.5rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.375rem !important; }

.ms-2 {
  margin-left: 0.75rem !important; }

.ms-3 {
  margin-left: 1.5rem !important; }

.ms-4 {
  margin-left: 2.25rem !important; }

.ms-5 {
  margin-left: 4.5rem !important; }

.ms-auto {
  margin-left: auto !important; }

.m-n1 {
  margin: -0.375rem !important; }

.m-n2 {
  margin: -0.75rem !important; }

.m-n3 {
  margin: -1.5rem !important; }

.m-n4 {
  margin: -2.25rem !important; }

.m-n5 {
  margin: -4.5rem !important; }

.mx-n1 {
  margin-right: -0.375rem !important;
  margin-left: -0.375rem !important; }

.mx-n2 {
  margin-right: -0.75rem !important;
  margin-left: -0.75rem !important; }

.mx-n3 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important; }

.mx-n4 {
  margin-right: -2.25rem !important;
  margin-left: -2.25rem !important; }

.mx-n5 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important; }

.my-n1 {
  margin-top: -0.375rem !important;
  margin-bottom: -0.375rem !important; }

.my-n2 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important; }

.my-n3 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important; }

.my-n4 {
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important; }

.my-n5 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important; }

.mt-n1 {
  margin-top: -0.375rem !important; }

.mt-n2 {
  margin-top: -0.75rem !important; }

.mt-n3 {
  margin-top: -1.5rem !important; }

.mt-n4 {
  margin-top: -2.25rem !important; }

.mt-n5 {
  margin-top: -4.5rem !important; }

.me-n1 {
  margin-right: -0.375rem !important; }

.me-n2 {
  margin-right: -0.75rem !important; }

.me-n3 {
  margin-right: -1.5rem !important; }

.me-n4 {
  margin-right: -2.25rem !important; }

.me-n5 {
  margin-right: -4.5rem !important; }

.mb-n1 {
  margin-bottom: -0.375rem !important; }

.mb-n2 {
  margin-bottom: -0.75rem !important; }

.mb-n3 {
  margin-bottom: -1.5rem !important; }

.mb-n4 {
  margin-bottom: -2.25rem !important; }

.mb-n5 {
  margin-bottom: -4.5rem !important; }

.ms-n1 {
  margin-left: -0.375rem !important; }

.ms-n2 {
  margin-left: -0.75rem !important; }

.ms-n3 {
  margin-left: -1.5rem !important; }

.ms-n4 {
  margin-left: -2.25rem !important; }

.ms-n5 {
  margin-left: -4.5rem !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.375rem !important; }

.p-2 {
  padding: 0.75rem !important; }

.p-3 {
  padding: 1.5rem !important; }

.p-4 {
  padding: 2.25rem !important; }

.p-5 {
  padding: 4.5rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.375rem !important;
  padding-left: 0.375rem !important; }

.px-2 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important; }

.px-3 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-4 {
  padding-right: 2.25rem !important;
  padding-left: 2.25rem !important; }

.px-5 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important; }

.py-2 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important; }

.py-3 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-4 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important; }

.py-5 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.375rem !important; }

.pt-2 {
  padding-top: 0.75rem !important; }

.pt-3 {
  padding-top: 1.5rem !important; }

.pt-4 {
  padding-top: 2.25rem !important; }

.pt-5 {
  padding-top: 4.5rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.375rem !important; }

.pe-2 {
  padding-right: 0.75rem !important; }

.pe-3 {
  padding-right: 1.5rem !important; }

.pe-4 {
  padding-right: 2.25rem !important; }

.pe-5 {
  padding-right: 4.5rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.375rem !important; }

.pb-2 {
  padding-bottom: 0.75rem !important; }

.pb-3 {
  padding-bottom: 1.5rem !important; }

.pb-4 {
  padding-bottom: 2.25rem !important; }

.pb-5 {
  padding-bottom: 4.5rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.375rem !important; }

.ps-2 {
  padding-left: 0.75rem !important; }

.ps-3 {
  padding-left: 1.5rem !important; }

.ps-4 {
  padding-left: 2.25rem !important; }

.ps-5 {
  padding-left: 4.5rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important; }

.fs-2 {
  font-size: calc(1.3125rem + 0.75vw) !important; }

.fs-3 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-4 {
  font-size: 1.125rem !important; }

.fs-5 {
  font-size: 0.9375rem !important; }

.fs-6 {
  font-size: 0.75rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  color: #536de6 !important; }

.text-secondary {
  color: #6c757d !important; }

.text-success {
  color: #10c469 !important; }

.text-info {
  color: #35b8e0 !important; }

.text-warning {
  color: #f9c851 !important; }

.text-danger {
  color: #ff5b5b !important; }

.text-light {
  color: #eef2f7 !important; }

.text-dark {
  color: #323a46 !important; }

.text-white {
  color: #fff !important; }

.text-body {
  color: #6c757d !important; }

.text-muted {
  color: #98a6ad !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  color: inherit !important; }

.bg-primary {
  background-color: #536de6 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

.bg-success {
  background-color: #10c469 !important; }

.bg-info {
  background-color: #35b8e0 !important; }

.bg-warning {
  background-color: #f9c851 !important; }

.bg-danger {
  background-color: #ff5b5b !important; }

.bg-light {
  background-color: #eef2f7 !important; }

.bg-dark {
  background-color: #323a46 !important; }

.bg-body {
  background-color: #fafbfe !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: 0.2rem !important; }

.rounded-2 {
  border-radius: 0.25rem !important; }

.rounded-3 {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.375rem !important; }
  .gap-sm-2 {
    gap: 0.75rem !important; }
  .gap-sm-3 {
    gap: 1.5rem !important; }
  .gap-sm-4 {
    gap: 2.25rem !important; }
  .gap-sm-5 {
    gap: 4.5rem !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.375rem !important; }
  .m-sm-2 {
    margin: 0.75rem !important; }
  .m-sm-3 {
    margin: 1.5rem !important; }
  .m-sm-4 {
    margin: 2.25rem !important; }
  .m-sm-5 {
    margin: 4.5rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important; }
  .mx-sm-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important; }
  .mx-sm-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important; }
  .mx-sm-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important; }
  .my-sm-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important; }
  .my-sm-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important; }
  .my-sm-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.375rem !important; }
  .mt-sm-2 {
    margin-top: 0.75rem !important; }
  .mt-sm-3 {
    margin-top: 1.5rem !important; }
  .mt-sm-4 {
    margin-top: 2.25rem !important; }
  .mt-sm-5 {
    margin-top: 4.5rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.375rem !important; }
  .me-sm-2 {
    margin-right: 0.75rem !important; }
  .me-sm-3 {
    margin-right: 1.5rem !important; }
  .me-sm-4 {
    margin-right: 2.25rem !important; }
  .me-sm-5 {
    margin-right: 4.5rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.375rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.75rem !important; }
  .mb-sm-3 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-4 {
    margin-bottom: 2.25rem !important; }
  .mb-sm-5 {
    margin-bottom: 4.5rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.375rem !important; }
  .ms-sm-2 {
    margin-left: 0.75rem !important; }
  .ms-sm-3 {
    margin-left: 1.5rem !important; }
  .ms-sm-4 {
    margin-left: 2.25rem !important; }
  .ms-sm-5 {
    margin-left: 4.5rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .m-sm-n1 {
    margin: -0.375rem !important; }
  .m-sm-n2 {
    margin: -0.75rem !important; }
  .m-sm-n3 {
    margin: -1.5rem !important; }
  .m-sm-n4 {
    margin: -2.25rem !important; }
  .m-sm-n5 {
    margin: -4.5rem !important; }
  .mx-sm-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important; }
  .mx-sm-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important; }
  .mx-sm-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-sm-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important; }
  .mx-sm-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important; }
  .my-sm-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important; }
  .my-sm-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important; }
  .my-sm-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-sm-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important; }
  .my-sm-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important; }
  .mt-sm-n1 {
    margin-top: -0.375rem !important; }
  .mt-sm-n2 {
    margin-top: -0.75rem !important; }
  .mt-sm-n3 {
    margin-top: -1.5rem !important; }
  .mt-sm-n4 {
    margin-top: -2.25rem !important; }
  .mt-sm-n5 {
    margin-top: -4.5rem !important; }
  .me-sm-n1 {
    margin-right: -0.375rem !important; }
  .me-sm-n2 {
    margin-right: -0.75rem !important; }
  .me-sm-n3 {
    margin-right: -1.5rem !important; }
  .me-sm-n4 {
    margin-right: -2.25rem !important; }
  .me-sm-n5 {
    margin-right: -4.5rem !important; }
  .mb-sm-n1 {
    margin-bottom: -0.375rem !important; }
  .mb-sm-n2 {
    margin-bottom: -0.75rem !important; }
  .mb-sm-n3 {
    margin-bottom: -1.5rem !important; }
  .mb-sm-n4 {
    margin-bottom: -2.25rem !important; }
  .mb-sm-n5 {
    margin-bottom: -4.5rem !important; }
  .ms-sm-n1 {
    margin-left: -0.375rem !important; }
  .ms-sm-n2 {
    margin-left: -0.75rem !important; }
  .ms-sm-n3 {
    margin-left: -1.5rem !important; }
  .ms-sm-n4 {
    margin-left: -2.25rem !important; }
  .ms-sm-n5 {
    margin-left: -4.5rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.375rem !important; }
  .p-sm-2 {
    padding: 0.75rem !important; }
  .p-sm-3 {
    padding: 1.5rem !important; }
  .p-sm-4 {
    padding: 2.25rem !important; }
  .p-sm-5 {
    padding: 4.5rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important; }
  .px-sm-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important; }
  .px-sm-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important; }
  .px-sm-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important; }
  .py-sm-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important; }
  .py-sm-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important; }
  .py-sm-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.375rem !important; }
  .pt-sm-2 {
    padding-top: 0.75rem !important; }
  .pt-sm-3 {
    padding-top: 1.5rem !important; }
  .pt-sm-4 {
    padding-top: 2.25rem !important; }
  .pt-sm-5 {
    padding-top: 4.5rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.375rem !important; }
  .pe-sm-2 {
    padding-right: 0.75rem !important; }
  .pe-sm-3 {
    padding-right: 1.5rem !important; }
  .pe-sm-4 {
    padding-right: 2.25rem !important; }
  .pe-sm-5 {
    padding-right: 4.5rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.375rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.75rem !important; }
  .pb-sm-3 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-4 {
    padding-bottom: 2.25rem !important; }
  .pb-sm-5 {
    padding-bottom: 4.5rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.375rem !important; }
  .ps-sm-2 {
    padding-left: 0.75rem !important; }
  .ps-sm-3 {
    padding-left: 1.5rem !important; }
  .ps-sm-4 {
    padding-left: 2.25rem !important; }
  .ps-sm-5 {
    padding-left: 4.5rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.375rem !important; }
  .gap-md-2 {
    gap: 0.75rem !important; }
  .gap-md-3 {
    gap: 1.5rem !important; }
  .gap-md-4 {
    gap: 2.25rem !important; }
  .gap-md-5 {
    gap: 4.5rem !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.375rem !important; }
  .m-md-2 {
    margin: 0.75rem !important; }
  .m-md-3 {
    margin: 1.5rem !important; }
  .m-md-4 {
    margin: 2.25rem !important; }
  .m-md-5 {
    margin: 4.5rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important; }
  .mx-md-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important; }
  .mx-md-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important; }
  .mx-md-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important; }
  .my-md-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important; }
  .my-md-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important; }
  .my-md-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.375rem !important; }
  .mt-md-2 {
    margin-top: 0.75rem !important; }
  .mt-md-3 {
    margin-top: 1.5rem !important; }
  .mt-md-4 {
    margin-top: 2.25rem !important; }
  .mt-md-5 {
    margin-top: 4.5rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.375rem !important; }
  .me-md-2 {
    margin-right: 0.75rem !important; }
  .me-md-3 {
    margin-right: 1.5rem !important; }
  .me-md-4 {
    margin-right: 2.25rem !important; }
  .me-md-5 {
    margin-right: 4.5rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.375rem !important; }
  .mb-md-2 {
    margin-bottom: 0.75rem !important; }
  .mb-md-3 {
    margin-bottom: 1.5rem !important; }
  .mb-md-4 {
    margin-bottom: 2.25rem !important; }
  .mb-md-5 {
    margin-bottom: 4.5rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.375rem !important; }
  .ms-md-2 {
    margin-left: 0.75rem !important; }
  .ms-md-3 {
    margin-left: 1.5rem !important; }
  .ms-md-4 {
    margin-left: 2.25rem !important; }
  .ms-md-5 {
    margin-left: 4.5rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .m-md-n1 {
    margin: -0.375rem !important; }
  .m-md-n2 {
    margin: -0.75rem !important; }
  .m-md-n3 {
    margin: -1.5rem !important; }
  .m-md-n4 {
    margin: -2.25rem !important; }
  .m-md-n5 {
    margin: -4.5rem !important; }
  .mx-md-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important; }
  .mx-md-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important; }
  .mx-md-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-md-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important; }
  .mx-md-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important; }
  .my-md-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important; }
  .my-md-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important; }
  .my-md-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-md-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important; }
  .my-md-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important; }
  .mt-md-n1 {
    margin-top: -0.375rem !important; }
  .mt-md-n2 {
    margin-top: -0.75rem !important; }
  .mt-md-n3 {
    margin-top: -1.5rem !important; }
  .mt-md-n4 {
    margin-top: -2.25rem !important; }
  .mt-md-n5 {
    margin-top: -4.5rem !important; }
  .me-md-n1 {
    margin-right: -0.375rem !important; }
  .me-md-n2 {
    margin-right: -0.75rem !important; }
  .me-md-n3 {
    margin-right: -1.5rem !important; }
  .me-md-n4 {
    margin-right: -2.25rem !important; }
  .me-md-n5 {
    margin-right: -4.5rem !important; }
  .mb-md-n1 {
    margin-bottom: -0.375rem !important; }
  .mb-md-n2 {
    margin-bottom: -0.75rem !important; }
  .mb-md-n3 {
    margin-bottom: -1.5rem !important; }
  .mb-md-n4 {
    margin-bottom: -2.25rem !important; }
  .mb-md-n5 {
    margin-bottom: -4.5rem !important; }
  .ms-md-n1 {
    margin-left: -0.375rem !important; }
  .ms-md-n2 {
    margin-left: -0.75rem !important; }
  .ms-md-n3 {
    margin-left: -1.5rem !important; }
  .ms-md-n4 {
    margin-left: -2.25rem !important; }
  .ms-md-n5 {
    margin-left: -4.5rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.375rem !important; }
  .p-md-2 {
    padding: 0.75rem !important; }
  .p-md-3 {
    padding: 1.5rem !important; }
  .p-md-4 {
    padding: 2.25rem !important; }
  .p-md-5 {
    padding: 4.5rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important; }
  .px-md-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important; }
  .px-md-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important; }
  .px-md-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important; }
  .py-md-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important; }
  .py-md-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important; }
  .py-md-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.375rem !important; }
  .pt-md-2 {
    padding-top: 0.75rem !important; }
  .pt-md-3 {
    padding-top: 1.5rem !important; }
  .pt-md-4 {
    padding-top: 2.25rem !important; }
  .pt-md-5 {
    padding-top: 4.5rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.375rem !important; }
  .pe-md-2 {
    padding-right: 0.75rem !important; }
  .pe-md-3 {
    padding-right: 1.5rem !important; }
  .pe-md-4 {
    padding-right: 2.25rem !important; }
  .pe-md-5 {
    padding-right: 4.5rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.375rem !important; }
  .pb-md-2 {
    padding-bottom: 0.75rem !important; }
  .pb-md-3 {
    padding-bottom: 1.5rem !important; }
  .pb-md-4 {
    padding-bottom: 2.25rem !important; }
  .pb-md-5 {
    padding-bottom: 4.5rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.375rem !important; }
  .ps-md-2 {
    padding-left: 0.75rem !important; }
  .ps-md-3 {
    padding-left: 1.5rem !important; }
  .ps-md-4 {
    padding-left: 2.25rem !important; }
  .ps-md-5 {
    padding-left: 4.5rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.375rem !important; }
  .gap-lg-2 {
    gap: 0.75rem !important; }
  .gap-lg-3 {
    gap: 1.5rem !important; }
  .gap-lg-4 {
    gap: 2.25rem !important; }
  .gap-lg-5 {
    gap: 4.5rem !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.375rem !important; }
  .m-lg-2 {
    margin: 0.75rem !important; }
  .m-lg-3 {
    margin: 1.5rem !important; }
  .m-lg-4 {
    margin: 2.25rem !important; }
  .m-lg-5 {
    margin: 4.5rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important; }
  .mx-lg-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important; }
  .mx-lg-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important; }
  .mx-lg-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important; }
  .my-lg-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important; }
  .my-lg-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important; }
  .my-lg-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.375rem !important; }
  .mt-lg-2 {
    margin-top: 0.75rem !important; }
  .mt-lg-3 {
    margin-top: 1.5rem !important; }
  .mt-lg-4 {
    margin-top: 2.25rem !important; }
  .mt-lg-5 {
    margin-top: 4.5rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.375rem !important; }
  .me-lg-2 {
    margin-right: 0.75rem !important; }
  .me-lg-3 {
    margin-right: 1.5rem !important; }
  .me-lg-4 {
    margin-right: 2.25rem !important; }
  .me-lg-5 {
    margin-right: 4.5rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.375rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.75rem !important; }
  .mb-lg-3 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-4 {
    margin-bottom: 2.25rem !important; }
  .mb-lg-5 {
    margin-bottom: 4.5rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.375rem !important; }
  .ms-lg-2 {
    margin-left: 0.75rem !important; }
  .ms-lg-3 {
    margin-left: 1.5rem !important; }
  .ms-lg-4 {
    margin-left: 2.25rem !important; }
  .ms-lg-5 {
    margin-left: 4.5rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .m-lg-n1 {
    margin: -0.375rem !important; }
  .m-lg-n2 {
    margin: -0.75rem !important; }
  .m-lg-n3 {
    margin: -1.5rem !important; }
  .m-lg-n4 {
    margin: -2.25rem !important; }
  .m-lg-n5 {
    margin: -4.5rem !important; }
  .mx-lg-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important; }
  .mx-lg-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important; }
  .mx-lg-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-lg-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important; }
  .mx-lg-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important; }
  .my-lg-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important; }
  .my-lg-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important; }
  .my-lg-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-lg-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important; }
  .my-lg-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important; }
  .mt-lg-n1 {
    margin-top: -0.375rem !important; }
  .mt-lg-n2 {
    margin-top: -0.75rem !important; }
  .mt-lg-n3 {
    margin-top: -1.5rem !important; }
  .mt-lg-n4 {
    margin-top: -2.25rem !important; }
  .mt-lg-n5 {
    margin-top: -4.5rem !important; }
  .me-lg-n1 {
    margin-right: -0.375rem !important; }
  .me-lg-n2 {
    margin-right: -0.75rem !important; }
  .me-lg-n3 {
    margin-right: -1.5rem !important; }
  .me-lg-n4 {
    margin-right: -2.25rem !important; }
  .me-lg-n5 {
    margin-right: -4.5rem !important; }
  .mb-lg-n1 {
    margin-bottom: -0.375rem !important; }
  .mb-lg-n2 {
    margin-bottom: -0.75rem !important; }
  .mb-lg-n3 {
    margin-bottom: -1.5rem !important; }
  .mb-lg-n4 {
    margin-bottom: -2.25rem !important; }
  .mb-lg-n5 {
    margin-bottom: -4.5rem !important; }
  .ms-lg-n1 {
    margin-left: -0.375rem !important; }
  .ms-lg-n2 {
    margin-left: -0.75rem !important; }
  .ms-lg-n3 {
    margin-left: -1.5rem !important; }
  .ms-lg-n4 {
    margin-left: -2.25rem !important; }
  .ms-lg-n5 {
    margin-left: -4.5rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.375rem !important; }
  .p-lg-2 {
    padding: 0.75rem !important; }
  .p-lg-3 {
    padding: 1.5rem !important; }
  .p-lg-4 {
    padding: 2.25rem !important; }
  .p-lg-5 {
    padding: 4.5rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important; }
  .px-lg-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important; }
  .px-lg-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important; }
  .px-lg-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important; }
  .py-lg-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important; }
  .py-lg-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important; }
  .py-lg-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.375rem !important; }
  .pt-lg-2 {
    padding-top: 0.75rem !important; }
  .pt-lg-3 {
    padding-top: 1.5rem !important; }
  .pt-lg-4 {
    padding-top: 2.25rem !important; }
  .pt-lg-5 {
    padding-top: 4.5rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.375rem !important; }
  .pe-lg-2 {
    padding-right: 0.75rem !important; }
  .pe-lg-3 {
    padding-right: 1.5rem !important; }
  .pe-lg-4 {
    padding-right: 2.25rem !important; }
  .pe-lg-5 {
    padding-right: 4.5rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.375rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.75rem !important; }
  .pb-lg-3 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-4 {
    padding-bottom: 2.25rem !important; }
  .pb-lg-5 {
    padding-bottom: 4.5rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.375rem !important; }
  .ps-lg-2 {
    padding-left: 0.75rem !important; }
  .ps-lg-3 {
    padding-left: 1.5rem !important; }
  .ps-lg-4 {
    padding-left: 2.25rem !important; }
  .ps-lg-5 {
    padding-left: 4.5rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.375rem !important; }
  .gap-xl-2 {
    gap: 0.75rem !important; }
  .gap-xl-3 {
    gap: 1.5rem !important; }
  .gap-xl-4 {
    gap: 2.25rem !important; }
  .gap-xl-5 {
    gap: 4.5rem !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.375rem !important; }
  .m-xl-2 {
    margin: 0.75rem !important; }
  .m-xl-3 {
    margin: 1.5rem !important; }
  .m-xl-4 {
    margin: 2.25rem !important; }
  .m-xl-5 {
    margin: 4.5rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important; }
  .mx-xl-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important; }
  .mx-xl-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important; }
  .mx-xl-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important; }
  .my-xl-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important; }
  .my-xl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important; }
  .my-xl-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.375rem !important; }
  .mt-xl-2 {
    margin-top: 0.75rem !important; }
  .mt-xl-3 {
    margin-top: 1.5rem !important; }
  .mt-xl-4 {
    margin-top: 2.25rem !important; }
  .mt-xl-5 {
    margin-top: 4.5rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.375rem !important; }
  .me-xl-2 {
    margin-right: 0.75rem !important; }
  .me-xl-3 {
    margin-right: 1.5rem !important; }
  .me-xl-4 {
    margin-right: 2.25rem !important; }
  .me-xl-5 {
    margin-right: 4.5rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.375rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.75rem !important; }
  .mb-xl-3 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-4 {
    margin-bottom: 2.25rem !important; }
  .mb-xl-5 {
    margin-bottom: 4.5rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.375rem !important; }
  .ms-xl-2 {
    margin-left: 0.75rem !important; }
  .ms-xl-3 {
    margin-left: 1.5rem !important; }
  .ms-xl-4 {
    margin-left: 2.25rem !important; }
  .ms-xl-5 {
    margin-left: 4.5rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .m-xl-n1 {
    margin: -0.375rem !important; }
  .m-xl-n2 {
    margin: -0.75rem !important; }
  .m-xl-n3 {
    margin: -1.5rem !important; }
  .m-xl-n4 {
    margin: -2.25rem !important; }
  .m-xl-n5 {
    margin: -4.5rem !important; }
  .mx-xl-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important; }
  .mx-xl-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important; }
  .mx-xl-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-xl-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important; }
  .mx-xl-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important; }
  .my-xl-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important; }
  .my-xl-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important; }
  .my-xl-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-xl-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important; }
  .my-xl-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important; }
  .mt-xl-n1 {
    margin-top: -0.375rem !important; }
  .mt-xl-n2 {
    margin-top: -0.75rem !important; }
  .mt-xl-n3 {
    margin-top: -1.5rem !important; }
  .mt-xl-n4 {
    margin-top: -2.25rem !important; }
  .mt-xl-n5 {
    margin-top: -4.5rem !important; }
  .me-xl-n1 {
    margin-right: -0.375rem !important; }
  .me-xl-n2 {
    margin-right: -0.75rem !important; }
  .me-xl-n3 {
    margin-right: -1.5rem !important; }
  .me-xl-n4 {
    margin-right: -2.25rem !important; }
  .me-xl-n5 {
    margin-right: -4.5rem !important; }
  .mb-xl-n1 {
    margin-bottom: -0.375rem !important; }
  .mb-xl-n2 {
    margin-bottom: -0.75rem !important; }
  .mb-xl-n3 {
    margin-bottom: -1.5rem !important; }
  .mb-xl-n4 {
    margin-bottom: -2.25rem !important; }
  .mb-xl-n5 {
    margin-bottom: -4.5rem !important; }
  .ms-xl-n1 {
    margin-left: -0.375rem !important; }
  .ms-xl-n2 {
    margin-left: -0.75rem !important; }
  .ms-xl-n3 {
    margin-left: -1.5rem !important; }
  .ms-xl-n4 {
    margin-left: -2.25rem !important; }
  .ms-xl-n5 {
    margin-left: -4.5rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.375rem !important; }
  .p-xl-2 {
    padding: 0.75rem !important; }
  .p-xl-3 {
    padding: 1.5rem !important; }
  .p-xl-4 {
    padding: 2.25rem !important; }
  .p-xl-5 {
    padding: 4.5rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important; }
  .px-xl-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important; }
  .px-xl-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important; }
  .px-xl-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important; }
  .py-xl-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important; }
  .py-xl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important; }
  .py-xl-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.375rem !important; }
  .pt-xl-2 {
    padding-top: 0.75rem !important; }
  .pt-xl-3 {
    padding-top: 1.5rem !important; }
  .pt-xl-4 {
    padding-top: 2.25rem !important; }
  .pt-xl-5 {
    padding-top: 4.5rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.375rem !important; }
  .pe-xl-2 {
    padding-right: 0.75rem !important; }
  .pe-xl-3 {
    padding-right: 1.5rem !important; }
  .pe-xl-4 {
    padding-right: 2.25rem !important; }
  .pe-xl-5 {
    padding-right: 4.5rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.375rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.75rem !important; }
  .pb-xl-3 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-4 {
    padding-bottom: 2.25rem !important; }
  .pb-xl-5 {
    padding-bottom: 4.5rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.375rem !important; }
  .ps-xl-2 {
    padding-left: 0.75rem !important; }
  .ps-xl-3 {
    padding-left: 1.5rem !important; }
  .ps-xl-4 {
    padding-left: 2.25rem !important; }
  .ps-xl-5 {
    padding-left: 4.5rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.375rem !important; }
  .gap-xxl-2 {
    gap: 0.75rem !important; }
  .gap-xxl-3 {
    gap: 1.5rem !important; }
  .gap-xxl-4 {
    gap: 2.25rem !important; }
  .gap-xxl-5 {
    gap: 4.5rem !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.375rem !important; }
  .m-xxl-2 {
    margin: 0.75rem !important; }
  .m-xxl-3 {
    margin: 1.5rem !important; }
  .m-xxl-4 {
    margin: 2.25rem !important; }
  .m-xxl-5 {
    margin: 4.5rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important; }
  .mx-xxl-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important; }
  .mx-xxl-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important; }
  .mx-xxl-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important; }
  .my-xxl-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important; }
  .my-xxl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important; }
  .my-xxl-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.375rem !important; }
  .mt-xxl-2 {
    margin-top: 0.75rem !important; }
  .mt-xxl-3 {
    margin-top: 1.5rem !important; }
  .mt-xxl-4 {
    margin-top: 2.25rem !important; }
  .mt-xxl-5 {
    margin-top: 4.5rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.375rem !important; }
  .me-xxl-2 {
    margin-right: 0.75rem !important; }
  .me-xxl-3 {
    margin-right: 1.5rem !important; }
  .me-xxl-4 {
    margin-right: 2.25rem !important; }
  .me-xxl-5 {
    margin-right: 4.5rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.375rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.75rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-4 {
    margin-bottom: 2.25rem !important; }
  .mb-xxl-5 {
    margin-bottom: 4.5rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.375rem !important; }
  .ms-xxl-2 {
    margin-left: 0.75rem !important; }
  .ms-xxl-3 {
    margin-left: 1.5rem !important; }
  .ms-xxl-4 {
    margin-left: 2.25rem !important; }
  .ms-xxl-5 {
    margin-left: 4.5rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .m-xxl-n1 {
    margin: -0.375rem !important; }
  .m-xxl-n2 {
    margin: -0.75rem !important; }
  .m-xxl-n3 {
    margin: -1.5rem !important; }
  .m-xxl-n4 {
    margin: -2.25rem !important; }
  .m-xxl-n5 {
    margin: -4.5rem !important; }
  .mx-xxl-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important; }
  .mx-xxl-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important; }
  .mx-xxl-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-xxl-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important; }
  .mx-xxl-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important; }
  .my-xxl-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important; }
  .my-xxl-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important; }
  .my-xxl-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-xxl-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important; }
  .my-xxl-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important; }
  .mt-xxl-n1 {
    margin-top: -0.375rem !important; }
  .mt-xxl-n2 {
    margin-top: -0.75rem !important; }
  .mt-xxl-n3 {
    margin-top: -1.5rem !important; }
  .mt-xxl-n4 {
    margin-top: -2.25rem !important; }
  .mt-xxl-n5 {
    margin-top: -4.5rem !important; }
  .me-xxl-n1 {
    margin-right: -0.375rem !important; }
  .me-xxl-n2 {
    margin-right: -0.75rem !important; }
  .me-xxl-n3 {
    margin-right: -1.5rem !important; }
  .me-xxl-n4 {
    margin-right: -2.25rem !important; }
  .me-xxl-n5 {
    margin-right: -4.5rem !important; }
  .mb-xxl-n1 {
    margin-bottom: -0.375rem !important; }
  .mb-xxl-n2 {
    margin-bottom: -0.75rem !important; }
  .mb-xxl-n3 {
    margin-bottom: -1.5rem !important; }
  .mb-xxl-n4 {
    margin-bottom: -2.25rem !important; }
  .mb-xxl-n5 {
    margin-bottom: -4.5rem !important; }
  .ms-xxl-n1 {
    margin-left: -0.375rem !important; }
  .ms-xxl-n2 {
    margin-left: -0.75rem !important; }
  .ms-xxl-n3 {
    margin-left: -1.5rem !important; }
  .ms-xxl-n4 {
    margin-left: -2.25rem !important; }
  .ms-xxl-n5 {
    margin-left: -4.5rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.375rem !important; }
  .p-xxl-2 {
    padding: 0.75rem !important; }
  .p-xxl-3 {
    padding: 1.5rem !important; }
  .p-xxl-4 {
    padding: 2.25rem !important; }
  .p-xxl-5 {
    padding: 4.5rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important; }
  .px-xxl-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important; }
  .px-xxl-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important; }
  .px-xxl-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important; }
  .py-xxl-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important; }
  .py-xxl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important; }
  .py-xxl-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.375rem !important; }
  .pt-xxl-2 {
    padding-top: 0.75rem !important; }
  .pt-xxl-3 {
    padding-top: 1.5rem !important; }
  .pt-xxl-4 {
    padding-top: 2.25rem !important; }
  .pt-xxl-5 {
    padding-top: 4.5rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.375rem !important; }
  .pe-xxl-2 {
    padding-right: 0.75rem !important; }
  .pe-xxl-3 {
    padding-right: 1.5rem !important; }
  .pe-xxl-4 {
    padding-right: 2.25rem !important; }
  .pe-xxl-5 {
    padding-right: 4.5rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.375rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.75rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-4 {
    padding-bottom: 2.25rem !important; }
  .pb-xxl-5 {
    padding-bottom: 4.5rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.375rem !important; }
  .ps-xxl-2 {
    padding-left: 0.75rem !important; }
  .ps-xxl-3 {
    padding-left: 1.5rem !important; }
  .ps-xxl-4 {
    padding-left: 2.25rem !important; }
  .ps-xxl-5 {
    padding-left: 4.5rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.25rem !important; }
  .fs-2 {
    font-size: 1.875rem !important; }
  .fs-3 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

html {
  position: relative;
  min-height: 100%; }

body {
  overflow-x: hidden; }

@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer; } }

.logo {
  display: block;
  line-height: 70px;
  width: 260px;
  position: fixed;
  top: 0; }
  .logo span.logo-lg {
    display: block; }
  .logo span.logo-sm {
    display: none; }
  .logo.logo-light {
    display: block; }
  .logo.logo-dark {
    display: none; }

.wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%; }

.content-page {
  margin-left: 260px;
  overflow: hidden;
  padding: 70px 12px 65px;
  min-height: 100vh; }

.leftside-menu {
  width: 260px;
  z-index: 10;
  background: linear-gradient(135deg, #6379c3 0%, #546ee5 60%);
  bottom: 0;
  position: fixed;
  top: 0;
  padding-top: 70px;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15); }

.side-nav {
  padding-left: 0;
  list-style-type: none; }
  .side-nav ul {
    list-style-type: none; }
  .side-nav .side-nav-link {
    color: #cedce4;
    display: block;
    padding: 10px 30px;
    font-size: 0.9375rem;
    position: relative;
    transition: all 0.4s;
    list-style: none; }
    .side-nav .side-nav-link:hover, .side-nav .side-nav-link:focus, .side-nav .side-nav-link:active {
      color: #ffffff;
      text-decoration: none; }
    .side-nav .side-nav-link span {
      vertical-align: middle; }
    .side-nav .side-nav-link i {
      display: inline-block;
      line-height: 1.0625rem;
      margin: 0 10px 0 0;
      font-size: 1.1rem;
      vertical-align: middle;
      width: 20px; }
  .side-nav .menu-arrow {
    transition: transform .15s;
    position: absolute;
    right: 30px;
    display: inline-block;
    font-family: 'Material Design Icons';
    text-rendering: auto;
    line-height: 1.5rem;
    font-size: 1.1rem;
    transform: translate(0, 0); }
    .side-nav .menu-arrow:before {
      content: "\F0142"; }
  .side-nav .badge {
    margin-top: 3px; }
  .side-nav .side-nav-item > a[aria-expanded="true"] > span.menu-arrow {
    transform: rotate(90deg); }
  .side-nav .side-nav-item.menuitem-active > a:not(.collapsed) > span.menu-arrow {
    transform: rotate(90deg); }
  .side-nav .side-nav-title {
    padding: 12px 30px;
    letter-spacing: .05em;
    pointer-events: none;
    cursor: default;
    font-size: 0.6875rem;
    text-transform: uppercase;
    color: #cedce4;
    font-weight: 700; }
  .side-nav .menuitem-active > a {
    color: #ffffff !important; }

*[dir="ltr"] .side-nav .side-nav-item .menu-arrow:before {
  content: "\F0141" !important; }

.side-nav-second-level,
.side-nav-third-level,
.side-nav-forth-level {
  padding-left: 0; }
  .side-nav-second-level li a,
  .side-nav-third-level li a,
  .side-nav-forth-level li a {
    padding: 8px 30px 8px 65px;
    color: #cedce4;
    display: block;
    position: relative;
    transition: all 0.4s;
    font-size: 0.89rem; }
    .side-nav-second-level li a:focus, .side-nav-second-level li a:hover,
    .side-nav-third-level li a:focus,
    .side-nav-third-level li a:hover,
    .side-nav-forth-level li a:focus,
    .side-nav-forth-level li a:hover {
      color: #ffffff; }
    .side-nav-second-level li a .menu-arrow,
    .side-nav-third-level li a .menu-arrow,
    .side-nav-forth-level li a .menu-arrow {
      line-height: 1.3rem; }
  .side-nav-second-level li.active > a,
  .side-nav-third-level li.active > a,
  .side-nav-forth-level li.active > a {
    color: #ffffff; }

.side-nav-third-level li a {
  padding: 8px 30px 8px 80px; }

.side-nav-forth-level li a {
  padding: 8px 30px 8px 100px; }

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) {
  min-height: 1600px; }
  body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .leftside-menu {
    position: absolute;
    padding-top: 0;
    width: 70px;
    z-index: 5;
    padding-top: 70px; }
    body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .leftside-menu .simplebar-mask,
    body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .leftside-menu .simplebar-content-wrapper {
      overflow: visible !important; }
    body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .leftside-menu .simplebar-scrollbar {
      display: none !important; }
    body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .leftside-menu .simplebar-offset {
      bottom: 0 !important; }
    body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .leftside-menu .logo {
      width: 70px;
      z-index: 1;
      background: linear-gradient(135deg, #6379c3 0%, #546ee5 60%); }
  body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .help-box {
    display: none; }
  body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .content-page {
    margin-left: 70px; }
  body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .navbar-custom,
  body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .footer {
    left: 70px; }
  body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-title,
  body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .badge,
  body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .menu-arrow {
    display: none !important; }
  body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .collapse,
  body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .collapsing {
    display: none;
    height: inherit !important;
    transition: none !important; }
    body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .collapse .side-nav-second-level,
    body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .collapse .side-nav-third-level,
    body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .collapse .side-nav-forth-level,
    body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .collapsing .side-nav-second-level,
    body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .collapsing .side-nav-third-level,
    body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .collapsing .side-nav-forth-level {
      display: none !important; }
  body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item {
    position: relative;
    white-space: nowrap; }
    body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link {
      padding: 15px 20px;
      min-height: 54px;
      transition: none; }
      body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link:hover, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link:active, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link:focus {
        color: #ffffff; }
      body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link i {
        font-size: 1.125rem;
        margin-right: 20px;
        margin-left: 6px; }
      body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link span {
        display: none;
        padding-left: 10px; }
    body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover .side-nav-link {
      position: relative;
      width: 260px;
      color: #fff;
      background: linear-gradient(135deg, #6379c3 0%, #546ee5 60%); }
      body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover .side-nav-link span {
        display: inline; }
    body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > ul {
      display: block !important;
      left: 70px;
      position: absolute;
      width: 190px;
      height: auto !important;
      padding: 5px 0;
      z-index: 9999;
      background: linear-gradient(135deg, #6379c3 0%, #546ee5 60%);
      box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15); }
      body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > ul a {
        padding: 8px 20px;
        position: relative;
        width: 190px; }
        body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > ul a:hover {
          color: #ffffff; }
    body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse,
    body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing {
      display: block !important;
      transition: none !important; }
      body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse > ul,
      body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing > ul {
        display: block !important;
        left: 70px;
        position: absolute;
        width: 190px;
        background: linear-gradient(135deg, #6379c3 0%, #546ee5 60%);
        box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15); }
        body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse > ul a,
        body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing > ul a {
          box-shadow: none;
          padding: 8px 20px;
          position: relative;
          width: 190px;
          z-index: 6; }
          body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse > ul a:hover,
          body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing > ul a:hover {
            color: #ffffff; }
        body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse > ul li:hover > .collapse,
        body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing > ul li:hover > .collapse {
          display: block !important;
          height: auto !important;
          transition: none !important; }
          body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse > ul li:hover > .collapse > ul,
          body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing > ul li:hover > .collapse > ul {
            display: block;
            left: 190px;
            top: 0;
            position: absolute;
            width: 190px; }
  body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .logo span.logo-lg {
    display: none; }
  body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .logo span.logo-sm {
    display: block;
    line-height: 70px;
    color: #536de6; }

@media (max-width: 767.98px) {
  body {
    overflow-x: hidden; }
  .leftside-menu {
    box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
    display: none;
    z-index: 10 !important; }
  .sidebar-enable .leftside-menu {
    display: block; }
  .navbar-nav.navbar-right {
    float: right; }
  .content-page {
    margin-left: 0 !important;
    padding: 65px 10px 65px; }
  body[data-leftbar-compact-mode="condensed"] .leftside-menu {
    margin-left: 0px; }
  .logo span.logo-lg {
    display: block; }
  .logo span.logo-sm {
    display: none; } }

.help-box {
  border-radius: 5px;
  padding: 20px;
  margin: 65px 25px 25px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.07); }
  .help-box .close-btn {
    position: absolute;
    right: 10px;
    top: 10px; }

body[data-leftbar-theme="light"] .help-box {
  background-color: #536de6; }

body[data-leftbar-theme="light"] .logo.logo-light {
  display: none; }

body[data-leftbar-theme="light"] .logo.logo-dark {
  display: block; }

body[data-layout="topnav"] .content-page {
  margin-left: 0 !important;
  padding: 0 0 60px 0; }

body[data-layout-mode="boxed"] {
  background-color: #ffffff; }
  body[data-layout-mode="boxed"] .wrapper {
    max-width: 1300px;
    margin: 0 auto;
    background-color: #fafbfe;
    box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15); }
  body[data-layout-mode="boxed"][data-leftbar-compact-mode="condensed"] .logo {
    position: relative;
    margin-top: -70px; }

@media (min-width: 1200px) {
  body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"]) {
    padding-bottom: 0; }
    body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"]) .wrapper {
      display: flex; }
    body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"]) .leftside-menu {
      position: relative;
      min-width: 260px;
      max-width: 260px;
      padding-top: 0; }
    body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"]) .logo {
      position: relative;
      margin-top: 0; }
    body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"]) .content-page {
      margin-left: 0;
      width: 100%;
      padding-bottom: 60px; } }

body[data-layout="detached"] {
  padding-bottom: 0; }
  @media (min-width: 992px) {
    body[data-layout="detached"] .container-fluid, body[data-layout="detached"] .container-sm, body[data-layout="detached"] .container-md, body[data-layout="detached"] .container-lg, body[data-layout="detached"] .container-xl, body[data-layout="detached"] .container-xxl {
      max-width: 95%; } }
  body[data-layout="detached"][data-layout-mode="boxed"] .wrapper {
    max-width: 100%; }
  body[data-layout="detached"] .wrapper {
    display: flex;
    overflow: inherit; }
  body[data-layout="detached"] .content-page {
    margin-left: 0;
    overflow: hidden;
    padding: 0 15px 5px 30px;
    position: relative;
    margin-right: -15px;
    width: 100%;
    padding-bottom: 60px; }
  body[data-layout="detached"] .leftside-menu {
    position: relative;
    background: #ffffff !important;
    min-width: 260px;
    max-width: 260px;
    box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
    margin-top: 30px;
    padding-top: 0 !important;
    z-index: 1001 !important; }
    body[data-layout="detached"] .leftside-menu .side-nav .side-nav-link {
      color: #6c757d !important; }
      body[data-layout="detached"] .leftside-menu .side-nav .side-nav-link:hover, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-link:focus, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-link:active {
        color: #3d73dd !important; }
    body[data-layout="detached"] .leftside-menu .side-nav .side-nav-second-level li a,
    body[data-layout="detached"] .leftside-menu .side-nav .side-nav-third-level li a,
    body[data-layout="detached"] .leftside-menu .side-nav .side-nav-forth-level li a {
      color: #6c757d; }
      body[data-layout="detached"] .leftside-menu .side-nav .side-nav-second-level li a:focus, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-second-level li a:hover,
      body[data-layout="detached"] .leftside-menu .side-nav .side-nav-third-level li a:focus,
      body[data-layout="detached"] .leftside-menu .side-nav .side-nav-third-level li a:hover,
      body[data-layout="detached"] .leftside-menu .side-nav .side-nav-forth-level li a:focus,
      body[data-layout="detached"] .leftside-menu .side-nav .side-nav-forth-level li a:hover {
        color: #3d73dd; }
    body[data-layout="detached"] .leftside-menu .side-nav .side-nav-second-level li.mm-active > a,
    body[data-layout="detached"] .leftside-menu .side-nav .side-nav-third-level li.mm-active > a,
    body[data-layout="detached"] .leftside-menu .side-nav .side-nav-forth-level li.mm-active > a {
      color: #3d73dd; }
    body[data-layout="detached"] .leftside-menu .side-nav .menuitem-active > a {
      color: #3d73dd !important; }
    body[data-layout="detached"] .leftside-menu .side-nav .side-nav-title {
      color: #6c757d; }
  body[data-layout="detached"] .leftbar-user {
    background: url("../images/waves.png") no-repeat;
    padding: 30px 20px;
    text-align: center; }
    body[data-layout="detached"] .leftbar-user .leftbar-user-name {
      font-weight: 700;
      color: #323a46;
      margin-left: 12px;
      margin-top: 8px;
      display: block; }
  @media (max-width: 767.98px) {
    body[data-layout="detached"].sidebar-enable .leftside-menu {
      position: fixed;
      left: 0;
      overflow-y: auto;
      margin-top: 70px; }
    body[data-layout="detached"] .wrapper {
      max-width: 100%; }
    body[data-layout="detached"] .content-page {
      margin-left: 0 !important;
      padding: 0 10px 60px 10px; }
    body[data-layout="detached"] .lang-switch {
      display: none; }
    body[data-layout="detached"][data-leftbar-compact-mode="condensed"].sidebar-enable .leftside-menu {
      margin-top: 0px; } }
  body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .wrapper .leftside-menu {
    max-width: 70px;
    min-width: 70px;
    position: relative; }
  body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .wrapper .leftbar-user {
    display: none; }
  body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .wrapper .content-page {
    margin-left: 0; }
  body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .wrapper .footer {
    left: 0; }
  body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:hover, body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:active, body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:focus {
    color: #ffffff; }
  body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover .side-nav-link {
    background: #536de6;
    color: #fff !important;
    transition: none; }
  body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover ul {
    background: #ffffff !important;
    box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15); }
    body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover ul a:hover {
      color: #3d73dd !important; }

@media (min-width: 992px) {
  body[data-layout="detached"][data-leftbar-compact-mode="scrollable"] .wrapper {
    padding-top: 70px; } }

.button-menu-mobile .lines {
  width: 18px;
  display: block;
  position: relative;
  height: 16px;
  transition: all .5s ease;
  margin-top: 26px;
  margin-left: 10px; }

.button-menu-mobile span {
  height: 2px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: block;
  margin-bottom: 5px;
  transition: transform .5s ease; }
  .button-menu-mobile span:nth-of-type(2) {
    width: 24px; }

.button-menu-mobile.disable-btn {
  display: none; }

body[data-leftbar-theme="light"] .leftside-menu {
  background: #ffffff; }
  body[data-leftbar-theme="light"] .leftside-menu .logo {
    background: #ffffff !important; }

body[data-leftbar-theme="light"] .side-nav .side-nav-link {
  color: #6c757d; }
  body[data-leftbar-theme="light"] .side-nav .side-nav-link:hover, body[data-leftbar-theme="light"] .side-nav .side-nav-link:focus, body[data-leftbar-theme="light"] .side-nav .side-nav-link:active {
    color: #3d73dd; }

body[data-leftbar-theme="light"] .side-nav .menuitem-active > a {
  color: #3d73dd !important; }

body[data-leftbar-theme="light"] .side-nav .side-nav-title {
  color: #6c757d; }

body[data-leftbar-theme="light"] .side-nav .side-nav-second-level li a,
body[data-leftbar-theme="light"] .side-nav .side-nav-third-level li a,
body[data-leftbar-theme="light"] .side-nav .side-nav-forth-level li a {
  color: #6c757d; }
  body[data-leftbar-theme="light"] .side-nav .side-nav-second-level li a:focus, body[data-leftbar-theme="light"] .side-nav .side-nav-second-level li a:hover,
  body[data-leftbar-theme="light"] .side-nav .side-nav-third-level li a:focus,
  body[data-leftbar-theme="light"] .side-nav .side-nav-third-level li a:hover,
  body[data-leftbar-theme="light"] .side-nav .side-nav-forth-level li a:focus,
  body[data-leftbar-theme="light"] .side-nav .side-nav-forth-level li a:hover {
    color: #3d73dd; }

body[data-leftbar-theme="light"] .side-nav .side-nav-second-level li.active > a,
body[data-leftbar-theme="light"] .side-nav .side-nav-third-level li.active > a,
body[data-leftbar-theme="light"] .side-nav .side-nav-forth-level li.active > a {
  color: #3d73dd; }

body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:hover, body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:active, body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:focus {
  color: #ffffff; }

body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover .side-nav-link {
  background: #536de6;
  color: #fff !important;
  transition: none; }

body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > ul {
  background: #ffffff;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15); }
  body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > ul a:hover {
    color: #3d73dd; }

body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > .collapse > ul,
body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > .collapsing > ul {
  background: #ffffff; }
  body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > .collapse > ul a:hover,
  body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > .collapsing > ul a:hover {
    color: #3d73dd; }

body[data-leftbar-theme="dark"] .leftside-menu {
  background: #313a46; }
  body[data-leftbar-theme="dark"] .leftside-menu .logo {
    background: #313a46 !important; }

body[data-leftbar-theme="dark"] .side-nav .side-nav-link {
  color: #8391a2; }
  body[data-leftbar-theme="dark"] .side-nav .side-nav-link:hover, body[data-leftbar-theme="dark"] .side-nav .side-nav-link:focus, body[data-leftbar-theme="dark"] .side-nav .side-nav-link:active {
    color: #bccee4; }

body[data-leftbar-theme="dark"] .side-nav .menuitem-active > a {
  color: #ffffff !important; }

body[data-leftbar-theme="dark"] .side-nav .side-nav-title {
  color: #8391a2; }

body[data-leftbar-theme="dark"] .side-nav .side-nav-second-level li a,
body[data-leftbar-theme="dark"] .side-nav .side-nav-third-level li a,
body[data-leftbar-theme="dark"] .side-nav .side-nav-forth-level li a {
  color: #8391a2; }
  body[data-leftbar-theme="dark"] .side-nav .side-nav-second-level li a:focus, body[data-leftbar-theme="dark"] .side-nav .side-nav-second-level li a:hover,
  body[data-leftbar-theme="dark"] .side-nav .side-nav-third-level li a:focus,
  body[data-leftbar-theme="dark"] .side-nav .side-nav-third-level li a:hover,
  body[data-leftbar-theme="dark"] .side-nav .side-nav-forth-level li a:focus,
  body[data-leftbar-theme="dark"] .side-nav .side-nav-forth-level li a:hover {
    color: #bccee4; }

body[data-leftbar-theme="dark"] .side-nav .side-nav-second-level li.active > a,
body[data-leftbar-theme="dark"] .side-nav .side-nav-third-level li.active > a,
body[data-leftbar-theme="dark"] .side-nav .side-nav-forth-level li.active > a {
  color: #ffffff; }

body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:hover, body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:active, body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:focus {
  color: #bccee4; }

body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover .side-nav-link {
  background: #536de6;
  color: #fff !important;
  transition: none; }

body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > ul {
  background: #313a46;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15); }
  body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > ul a:hover {
    color: #bccee4; }

body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > .collapse > ul,
body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > .collapsing > ul {
  background: #313a46; }

.navbar-custom {
  padding: 0 24px;
  background-color: #ffffff;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  min-height: 70px;
  position: fixed;
  left: 260px;
  top: 0;
  right: 0;
  z-index: 1001; }
  .navbar-custom .topbar-left {
    background-color: #ffffff;
    height: 70px;
    position: fixed;
    z-index: 1;
    width: 260px;
    text-align: center;
    top: 0;
    left: 0; }
    .navbar-custom .topbar-left .logo {
      line-height: 70px; }
      .navbar-custom .topbar-left .logo i {
        display: none; }
  .navbar-custom .topbar-menu {
    position: relative;
    z-index: 1; }
    .navbar-custom .topbar-menu li {
      float: left;
      max-height: 70px; }
      .navbar-custom .topbar-menu li .show.nav-link {
        color: #6c757d; }
    .navbar-custom .topbar-menu .nav-link {
      padding: 0;
      color: #98a6ad;
      min-width: 32px;
      display: block;
      text-align: center;
      margin: 0 10px;
      position: relative; }
  .navbar-custom .app-search {
    position: static;
    overflow-y: hidden; }
    .navbar-custom .app-search form {
      padding: calc(32px / 2) 5px calc(32px / 2) 0;
      overflow: hidden;
      max-width: 320px; }

.topbar-dropdown .nav-link {
  line-height: 70px; }

/* Search */
.app-search .form-control {
  border: none;
  height: calc(1.5em + (0.9rem + 2px));
  padding-left: 40px;
  padding-right: 20px;
  background-color: #f1f3fa;
  box-shadow: none; }

.app-search span.search-icon {
  position: absolute;
  z-index: 9;
  font-size: 20px;
  line-height: 38px;
  left: 10px;
  top: 0; }

.app-search .input-group-text {
  margin-left: 0;
  z-index: 4; }

/* Notification */
.notification-list {
  margin-left: 0; }
  .notification-list .noti-title {
    background-color: transparent;
    padding: 15px 20px; }
  .notification-list .noti-icon {
    font-size: 22px;
    vertical-align: middle;
    line-height: 70px; }
  .notification-list .noti-icon-badge {
    display: inline-block;
    position: absolute;
    top: 22px;
    right: 6px;
    border-radius: 50%;
    height: 7px;
    width: 7px;
    background-color: #ff5b5b; }
  .notification-list .notify-item {
    padding: 10px 20px; }
    .notification-list .notify-item .notify-icon {
      float: left;
      height: 36px;
      width: 36px;
      line-height: 36px;
      text-align: center;
      margin-right: 10px;
      border-radius: 50%;
      color: #fff; }
    .notification-list .notify-item .notify-details {
      margin-bottom: 0;
      overflow: hidden;
      margin-left: 45px;
      text-overflow: ellipsis;
      white-space: nowrap; }
      .notification-list .notify-item .notify-details b {
        font-weight: 500; }
      .notification-list .notify-item .notify-details small, .notification-list .notify-item .notify-details .small {
        display: block; }
      .notification-list .notify-item .notify-details span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px; }
    .notification-list .notify-item .user-msg {
      margin-left: 45px;
      white-space: normal;
      line-height: 16px; }
  .notification-list .topbar-dropdown-menu .notify-item {
    padding: 7px 20px; }

.profile-dropdown {
  min-width: 170px; }
  .profile-dropdown i, .profile-dropdown span {
    vertical-align: middle; }

.nav-user {
  padding: calc(31px / 2) 20px calc(31px / 2) 57px !important;
  text-align: left !important;
  position: relative;
  background-color: #fafbfd;
  border: 1px solid #f1f3fa;
  border-width: 0 1px;
  min-height: 70px; }
  .nav-user .account-user-avatar {
    position: absolute;
    top: calc(38px / 2);
    left: 15px; }
    .nav-user .account-user-avatar img {
      height: 32px;
      width: 32px; }
  .nav-user .account-position {
    display: block;
    font-size: 12px;
    margin-top: -3px; }
  .nav-user .account-user-name {
    display: block;
    font-weight: 600; }

.button-menu-mobile {
  border: none;
  color: #323a46;
  height: 70px;
  line-height: 70px;
  width: 60px;
  background-color: transparent;
  font-size: 24px;
  cursor: pointer;
  float: left;
  z-index: 1;
  position: relative;
  margin-left: -24px; }
  .button-menu-mobile.disable-btn {
    display: none; }
  @media (max-width: 767.98px) {
    .button-menu-mobile {
      margin: 0 !important; } }

[data-keep-enlarged="true"] .navbar-custom {
  padding-left: 0; }

[data-keep-enlarged="true"] .button-menu-mobile {
  margin: 0; }
  [data-keep-enlarged="true"] .button-menu-mobile.disable-btn {
    display: inline-block; }

@media (max-width: 600px) {
  .navbar-custom .topbar-menu {
    position: initial; }
  .navbar-custom .dropdown {
    position: static; }
    .navbar-custom .dropdown .dropdown-menu {
      left: 10px !important;
      right: 10px !important; } }

@media (max-width: 1200px) {
  .navbar-custom {
    right: 0; }
    .navbar-custom .app-search {
      display: none; } }

@media (max-width: 991.98px) {
  .nav-user {
    padding: 17px 5px 17px 57px !important; }
    .nav-user .account-position,
    .nav-user .account-user-name {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-custom {
    left: 0 !important;
    padding: 0; }
  .button-menu-mobile.disable-btn {
    display: inline-block; } }

@media (max-width: 375px) {
  .navbar-custom .topbar-menu .nav-link {
    margin: 0 6px; }
  .navbar-custom.topnav-navbar .container-fluid, .navbar-custom.topnav-navbar .container-sm, .navbar-custom.topnav-navbar .container-md, .navbar-custom.topnav-navbar .container-lg, .navbar-custom.topnav-navbar .container-xl, .navbar-custom.topnav-navbar .container-xxl {
    padding-right: 12px;
    padding-left: 12px; }
  .navbar-custom.topnav-navbar .navbar-toggle {
    margin: 27px 3px; }
  .navbar-custom.topnav-navbar .button-menu-mobile {
    width: auto; } }

@media (min-width: 992px) {
  body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"]) .navbar-custom {
    position: absolute; } }

.topnav-navbar-dark {
  background-color: #313a46; }
  .topnav-navbar-dark .nav-user {
    background-color: #3c4655;
    border: 1px solid #414d5d; }
  .topnav-navbar-dark .topbar-menu li .show.nav-link {
    color: #fff !important; }
  .topnav-navbar-dark .app-search .form-control {
    background-color: #3c4655;
    color: #fff; }
  .topnav-navbar-dark .app-search span {
    color: #98a6ad; }
  .topnav-navbar-dark .navbar-toggle span {
    background-color: rgba(255, 255, 255, 0.8) !important; }

body[data-layout-mode="boxed"] .navbar-custom {
  position: relative;
  left: 0 !important;
  margin: -70px -12px 0; }

body[data-layout-mode="boxed"][data-layout="topnav"] .navbar-custom {
  margin: 0; }

/* rtl:begin:options: {
  "autoRename": true,
  "stringMap":[]
} */
.end-bar {
  background-color: #ffffff;
  box-shadow: 0 0 24px 0 rgba(50, 58, 70, 0.1), 0 1px 0 0 rgba(50, 58, 70, 0.08);
  display: block;
  position: fixed;
  transition: all 200ms ease-out;
  width: 280px;
  z-index: 9999;
  float: right !important;
  right: -290px;
  top: 0;
  bottom: 0;
  padding-bottom: 80px; }
  .end-bar .rightbar-title {
    background-color: #313a46;
    padding: 27px 25px;
    color: #ffffff; }
  .end-bar .end-bar-toggle {
    background-color: #444e5a;
    height: 24px;
    width: 24px;
    line-height: 22px;
    color: #ffffff;
    text-align: center;
    border-radius: 50%;
    margin-top: -4px; }

.rightbar-overlay {
  background-color: #37404a;
  opacity: 0.1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 9998;
  transition: all .2s ease-out; }

.end-bar-enabled .end-bar {
  right: 0; }

.end-bar-enabled .rightbar-overlay {
  display: block; }

@media (max-width: 767.98px) {
  .end-bar {
    overflow: auto; } }

.page-title-box .page-title {
  font-size: 18px;
  margin: 0;
  line-height: 75px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: inherit; }

.page-title-box .page-title-right {
  float: right;
  margin-top: 20px; }

.page-title-box .breadcrumb {
  padding-top: 8px; }

.page-title-box-sm .page-title {
  line-height: 1 !important;
  margin-bottom: 25px; }

.page-title-box-sm .page-title-right {
  float: right;
  margin-top: 0; }

.page-title-box-sm .breadcrumb {
  padding-top: 0;
  margin-top: -3px !important; }

.text-title {
  color: #6c757d; }
  .text-title:hover {
    color: #6c757d; }

@media (max-width: 767.98px) {
  .page-title-box .page-title {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 70px; }
  .page-title-box .breadcrumb {
    display: none; }
  .page-title-box .page-title-right {
    display: none; } }

@media (max-width: 419px) {
  .page-title-box .breadcrumb {
    display: none; } }

.footer {
  border-top: 1px solid rgba(152, 166, 173, 0.2);
  bottom: 0;
  padding: 19px 24px 20px;
  position: absolute;
  right: 0;
  color: #98a6ad;
  left: 260px; }
  .footer .footer-links a {
    color: #98a6ad;
    margin-left: 1.5rem;
    transition: all .4s; }
    .footer .footer-links a:hover {
      color: #323a46; }
    .footer .footer-links a:first-of-type {
      margin-left: 0; }

@media (max-width: 767.98px) {
  .footer {
    left: 0 !important;
    text-align: center; } }

.footer-alt {
  left: 0;
  border: none;
  text-align: center; }

body[data-layout="topnav"] .footer {
  left: 0 !important;
  padding: 19px 0 20px; }

body[data-layout="topnav"][data-layout-mode="boxed"] .footer {
  max-width: 1300px; }

body[data-layout-mode="boxed"] .footer {
  border: none;
  margin: 0 auto;
  background-color: #fafbfe;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  max-width: calc(1300px - 260px); }

body[data-layout-mode="boxed"][data-leftbar-compact-mode="condensed"] .footer {
  max-width: calc(1300px - 70px); }

body[data-layout="detached"] .footer {
  left: 0; }
  body[data-layout="detached"] .footer .container-fluid, body[data-layout="detached"] .footer .container-sm, body[data-layout="detached"] .footer .container-md, body[data-layout="detached"] .footer .container-lg, body[data-layout="detached"] .footer .container-xl, body[data-layout="detached"] .footer .container-xxl {
    max-width: 100%;
    padding: 0; }

.topnav-navbar {
  padding: 0;
  margin: 0;
  min-height: 70px;
  position: relative;
  left: 0 !important;
  z-index: 1002; }
  .topnav-navbar .topnav-logo {
    line-height: 70px;
    float: left;
    padding-right: 20px;
    min-width: 160px; }
    .topnav-navbar .topnav-logo .topnav-logo-sm {
      display: none; }
  .topnav-navbar .navbar-toggle {
    position: relative;
    cursor: pointer;
    float: left;
    margin: 27px 20px;
    padding: 0;
    background-color: transparent;
    border: none; }
    .topnav-navbar .navbar-toggle .lines {
      width: 25px;
      display: block;
      position: relative;
      height: 16px;
      transition: all .5s ease; }
    .topnav-navbar .navbar-toggle span {
      height: 2px;
      width: 100%;
      background-color: rgba(50, 58, 70, 0.8);
      display: block;
      margin-bottom: 5px;
      transition: transform .5s ease; }
    .topnav-navbar .navbar-toggle.open span {
      position: absolute; }
      .topnav-navbar .navbar-toggle.open span:first-child {
        top: 7px;
        transform: rotate(45deg); }
      .topnav-navbar .navbar-toggle.open span:nth-child(2) {
        visibility: hidden; }
      .topnav-navbar .navbar-toggle.open span:last-child {
        width: 100%;
        top: 7px;
        transform: rotate(-45deg); }
  .topnav-navbar .app-search {
    float: left; }

.topnav {
  background: linear-gradient(to bottom, #6379c3, #546ee5); }
  .topnav .topnav-menu {
    margin: 0;
    padding: 0; }
  .topnav .navbar-nav .nav-link {
    font-size: .9375rem;
    position: relative;
    padding: 1rem 1.3rem; }
  .topnav .nav-item.active > a {
    color: #3d73dd; }
  .topnav .navbar-dark .dropdown.active > .nav-link, .topnav .navbar-dark .dropdown:active > .nav-link {
    color: #fff; }

.arrow-down {
  display: inline-block; }
  .arrow-down:after {
    border-color: initial;
    border-style: solid;
    border-width: 0 0 1px 1px;
    content: "";
    height: .4em;
    display: inline-block;
    right: 5px;
    top: 50%;
    margin-left: 10px;
    transform: rotate(-45deg) translateY(-50%);
    transform-origin: top;
    transition: all .3s ease-out;
    width: .4em; }

body[data-layout="topnav"] .container-fluid, body[data-layout="topnav"] .container-sm, body[data-layout="topnav"] .container-md, body[data-layout="topnav"] .container-lg, body[data-layout="topnav"] .container-xl, body[data-layout="topnav"] .container-xxl {
  padding-right: 24px;
  padding-left: 24px; }

@media (min-width: 992px) {
  body[data-layout="topnav"] .container-fluid, body[data-layout="topnav"] .container-sm, body[data-layout="topnav"] .container-md, body[data-layout="topnav"] .container-lg, body[data-layout="topnav"] .container-xl, body[data-layout="topnav"] .container-xxl {
    max-width: 95%; }
  body[data-layout="topnav"][data-layout-mode="boxed"] .container-fluid, body[data-layout="topnav"][data-layout-mode="boxed"] .container-sm, body[data-layout="topnav"][data-layout-mode="boxed"] .container-md, body[data-layout="topnav"][data-layout-mode="boxed"] .container-lg, body[data-layout="topnav"][data-layout-mode="boxed"] .container-xl, body[data-layout="topnav"][data-layout-mode="boxed"] .container-xxl {
    max-width: 97%; }
  .topnav .navbar-nav .nav-item:first-of-type .nav-link {
    padding-left: 0; }
  .topnav .dropdown .dropdown-menu {
    margin-top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    min-width: calc(10rem + 1.5rem);
    font-size: calc(0.9rem - 0.01rem); }
    .topnav .dropdown .dropdown-menu .arrow-down::after {
      right: 15px;
      transform: rotate(-135deg) translateY(-50%);
      position: absolute; }
    .topnav .dropdown .dropdown-menu .dropdown .dropdown-menu {
      position: absolute;
      top: 0;
      left: 100%;
      display: none; }
  .topnav .dropdown:hover > .dropdown-menu {
    display: block; }
  .topnav .dropdown:hover > .dropdown-menu > .dropdown:hover > .dropdown-menu {
    display: block; }
  .dropdown.active > a.dropdown-item {
    color: #323a46;
    background-color: #f2f5f9; } }

@media (min-width: 1400px) {
  body[data-layout="topnav"] .container-fluid, body[data-layout="topnav"] .container-sm, body[data-layout="topnav"] .container-md, body[data-layout="topnav"] .container-lg, body[data-layout="topnav"] .container-xl, body[data-layout="topnav"] .container-xxl {
    max-width: 85%; } }

@media (min-width: 992px) {
  .navbar-toggle {
    display: none; } }

@media (max-width: 991.98px) {
  .topnav-navbar .topnav-logo-lg {
    display: none; }
  .topnav-navbar .topnav-logo {
    min-width: 50px;
    padding-right: 0;
    text-align: center; }
  .topnav-navbar .topnav-logo-sm {
    display: block !important; }
  .topnav .navbar-nav .nav-link {
    padding: 0.75rem 1.3rem; }
  .topnav .dropdown .dropdown-menu {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding-left: 15px; }
  .topnav .dropdown .dropdown-item {
    position: relative;
    background-color: transparent; }
  .topnav .navbar-dark .dropdown .dropdown-item {
    color: rgba(255, 255, 255, 0.5); }
    .topnav .navbar-dark .dropdown .dropdown-item.active, .topnav .navbar-dark .dropdown .dropdown-item:active {
      color: #fff; }
  .topnav .arrow-down::after {
    right: 15px;
    position: absolute; } }

@font-face {
  font-family: 'Nunito';
  src: url("../fonts/Nunito-Light.eot");
  src: local("Nunito Light"), local("Nunito-Light"), url("../fonts/Nunito-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Nunito-Light.woff") format("woff"), url("../fonts/Nunito-Light.ttf") format("truetype"), url("../fonts/Nunito-Light.svg#Roboto") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Nunito';
  src: url("../fonts/Nunito-Regular.eot");
  src: local("Nunito Regular"), local("Nunito-Regular"), url("../fonts/Nunito-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Nunito-Regular.woff") format("woff"), url("../fonts/Nunito-Regular.ttf") format("truetype"), url("../fonts/Nunito-Regular.svg#Roboto") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Nunito';
  src: url("../fonts/Nunito-SemiBold.eot");
  src: local("Nunito SemiBold"), local("Nunito-SemiBold"), url("../fonts/Nunito-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Nunito-SemiBold.woff") format("woff"), url("../fonts/Nunito-SemiBold.ttf") format("truetype"), url("../fonts/Nunito-SemiBold.svg#Roboto") format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Nunito';
  src: url("../fonts/Nunito-Bold.eot");
  src: local("Nunito Bold"), local("Nunito-Bold"), url("../fonts/Nunito-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Nunito-Bold.woff") format("woff"), url("../fonts/Nunito-Bold.ttf") format("truetype"), url("../fonts/Nunito-Bold.svg#Roboto") format("svg");
  font-weight: 700;
  font-style: normal; }

.custom-accordion .card {
  box-shadow: none; }

.custom-accordion .card-header {
  background-color: #f1f3fa; }

.custom-accordion .card-body {
  border: 1px solid #f1f3fa; }

.custom-accordion .accordion-arrow {
  font-size: 1.2rem;
  position: absolute;
  right: 0; }

.custom-accordion a.collapsed i.accordion-arrow:before {
  content: "\F142"; }

.custom-accordion-title {
  color: #313a46;
  position: relative; }
  .custom-accordion-title:hover {
    color: #414d5d; }

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px; }

.avatar-xs {
  height: 1.5rem;
  width: 1.5rem; }

.avatar-sm {
  height: 3rem;
  width: 3rem; }

.avatar-md {
  height: 4.5rem;
  width: 4.5rem; }

.avatar-lg {
  height: 6rem;
  width: 6rem; }

.avatar-xl {
  height: 7.5rem;
  width: 7.5rem; }

.avatar-title {
  align-items: center;
  background-color: #536de6;
  color: #fff;
  display: flex;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  width: 100%; }

.breadcrumb-item + .breadcrumb-item::before {
  font-family: "Material Design Icons";
  font-size: 16px;
  line-height: 1.3; }

.btn .mdi:before {
  line-height: initial; }

.btn-rounded {
  border-radius: 2em; }

.btn-primary {
  box-shadow: 0 0 0 rgba(83, 109, 230, 0.5); }

.btn-secondary {
  box-shadow: 0 0 0 rgba(108, 117, 125, 0.5); }

.btn-success {
  box-shadow: 0 0 0 rgba(16, 196, 105, 0.5); }

.btn-info {
  box-shadow: 0 0 0 rgba(53, 184, 224, 0.5); }

.btn-warning {
  box-shadow: 0 0 0 rgba(249, 200, 81, 0.5); }

.btn-danger {
  box-shadow: 0 0 0 rgba(255, 91, 91, 0.5); }

.btn-light {
  box-shadow: 0 0 0 rgba(238, 242, 247, 0.5); }

.btn-dark {
  box-shadow: 0 0 0 rgba(50, 58, 70, 0.5); }

.badge-primary-lighten {
  color: #536de6;
  background-color: rgba(83, 109, 230, 0.18); }
  .badge-primary-lighten[href] {
    color: #536de6;
    text-decoration: none;
    background-color: rgba(83, 109, 230, 0.18); }
  .badge-primary-lighten[href]:hover, .badge-primary-lighten[href]:focus {
    color: #536de6;
    text-decoration: none;
    background-color: rgba(83, 109, 230, 0.4); }

.badge-secondary-lighten {
  color: #6c757d;
  background-color: rgba(108, 117, 125, 0.18); }
  .badge-secondary-lighten[href] {
    color: #6c757d;
    text-decoration: none;
    background-color: rgba(108, 117, 125, 0.18); }
  .badge-secondary-lighten[href]:hover, .badge-secondary-lighten[href]:focus {
    color: #6c757d;
    text-decoration: none;
    background-color: rgba(108, 117, 125, 0.4); }

.badge-success-lighten {
  color: #10c469;
  background-color: rgba(16, 196, 105, 0.18); }
  .badge-success-lighten[href] {
    color: #10c469;
    text-decoration: none;
    background-color: rgba(16, 196, 105, 0.18); }
  .badge-success-lighten[href]:hover, .badge-success-lighten[href]:focus {
    color: #10c469;
    text-decoration: none;
    background-color: rgba(16, 196, 105, 0.4); }

.badge-info-lighten {
  color: #35b8e0;
  background-color: rgba(53, 184, 224, 0.18); }
  .badge-info-lighten[href] {
    color: #35b8e0;
    text-decoration: none;
    background-color: rgba(53, 184, 224, 0.18); }
  .badge-info-lighten[href]:hover, .badge-info-lighten[href]:focus {
    color: #35b8e0;
    text-decoration: none;
    background-color: rgba(53, 184, 224, 0.4); }

.badge-warning-lighten {
  color: #f9c851;
  background-color: rgba(249, 200, 81, 0.18); }
  .badge-warning-lighten[href] {
    color: #f9c851;
    text-decoration: none;
    background-color: rgba(249, 200, 81, 0.18); }
  .badge-warning-lighten[href]:hover, .badge-warning-lighten[href]:focus {
    color: #f9c851;
    text-decoration: none;
    background-color: rgba(249, 200, 81, 0.4); }

.badge-danger-lighten {
  color: #ff5b5b;
  background-color: rgba(255, 91, 91, 0.18); }
  .badge-danger-lighten[href] {
    color: #ff5b5b;
    text-decoration: none;
    background-color: rgba(255, 91, 91, 0.18); }
  .badge-danger-lighten[href]:hover, .badge-danger-lighten[href]:focus {
    color: #ff5b5b;
    text-decoration: none;
    background-color: rgba(255, 91, 91, 0.4); }

.badge-light-lighten {
  color: #eef2f7;
  background-color: rgba(238, 242, 247, 0.18); }
  .badge-light-lighten[href] {
    color: #eef2f7;
    text-decoration: none;
    background-color: rgba(238, 242, 247, 0.18); }
  .badge-light-lighten[href]:hover, .badge-light-lighten[href]:focus {
    color: #eef2f7;
    text-decoration: none;
    background-color: rgba(238, 242, 247, 0.4); }

.badge-dark-lighten {
  color: #323a46;
  background-color: rgba(50, 58, 70, 0.18); }
  .badge-dark-lighten[href] {
    color: #323a46;
    text-decoration: none;
    background-color: rgba(50, 58, 70, 0.18); }
  .badge-dark-lighten[href]:hover, .badge-dark-lighten[href]:focus {
    color: #323a46;
    text-decoration: none;
    background-color: rgba(50, 58, 70, 0.4); }

.badge-outline-primary {
  color: #536de6;
  border: 1px solid #536de6;
  background-color: transparent; }
  .badge-outline-primary[href] {
    color: #536de6;
    text-decoration: none;
    background-color: rgba(83, 109, 230, 0.2); }
  .badge-outline-primary[href]:hover, .badge-outline-primary[href]:focus {
    color: #536de6;
    text-decoration: none;
    background-color: rgba(83, 109, 230, 0.2); }

.badge-outline-secondary {
  color: #6c757d;
  border: 1px solid #6c757d;
  background-color: transparent; }
  .badge-outline-secondary[href] {
    color: #6c757d;
    text-decoration: none;
    background-color: rgba(108, 117, 125, 0.2); }
  .badge-outline-secondary[href]:hover, .badge-outline-secondary[href]:focus {
    color: #6c757d;
    text-decoration: none;
    background-color: rgba(108, 117, 125, 0.2); }

.badge-outline-success {
  color: #10c469;
  border: 1px solid #10c469;
  background-color: transparent; }
  .badge-outline-success[href] {
    color: #10c469;
    text-decoration: none;
    background-color: rgba(16, 196, 105, 0.2); }
  .badge-outline-success[href]:hover, .badge-outline-success[href]:focus {
    color: #10c469;
    text-decoration: none;
    background-color: rgba(16, 196, 105, 0.2); }

.badge-outline-info {
  color: #35b8e0;
  border: 1px solid #35b8e0;
  background-color: transparent; }
  .badge-outline-info[href] {
    color: #35b8e0;
    text-decoration: none;
    background-color: rgba(53, 184, 224, 0.2); }
  .badge-outline-info[href]:hover, .badge-outline-info[href]:focus {
    color: #35b8e0;
    text-decoration: none;
    background-color: rgba(53, 184, 224, 0.2); }

.badge-outline-warning {
  color: #f9c851;
  border: 1px solid #f9c851;
  background-color: transparent; }
  .badge-outline-warning[href] {
    color: #f9c851;
    text-decoration: none;
    background-color: rgba(249, 200, 81, 0.2); }
  .badge-outline-warning[href]:hover, .badge-outline-warning[href]:focus {
    color: #f9c851;
    text-decoration: none;
    background-color: rgba(249, 200, 81, 0.2); }

.badge-outline-danger {
  color: #ff5b5b;
  border: 1px solid #ff5b5b;
  background-color: transparent; }
  .badge-outline-danger[href] {
    color: #ff5b5b;
    text-decoration: none;
    background-color: rgba(255, 91, 91, 0.2); }
  .badge-outline-danger[href]:hover, .badge-outline-danger[href]:focus {
    color: #ff5b5b;
    text-decoration: none;
    background-color: rgba(255, 91, 91, 0.2); }

.badge-outline-light {
  color: #eef2f7;
  border: 1px solid #eef2f7;
  background-color: transparent; }
  .badge-outline-light[href] {
    color: #eef2f7;
    text-decoration: none;
    background-color: rgba(238, 242, 247, 0.2); }
  .badge-outline-light[href]:hover, .badge-outline-light[href]:focus {
    color: #eef2f7;
    text-decoration: none;
    background-color: rgba(238, 242, 247, 0.2); }

.badge-outline-dark {
  color: #323a46;
  border: 1px solid #323a46;
  background-color: transparent; }
  .badge-outline-dark[href] {
    color: #323a46;
    text-decoration: none;
    background-color: rgba(50, 58, 70, 0.2); }
  .badge-outline-dark[href]:hover, .badge-outline-dark[href]:focus {
    color: #323a46;
    text-decoration: none;
    background-color: rgba(50, 58, 70, 0.2); }

.card {
  border: none;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  margin-bottom: 24px; }
  .card .header-title {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    font-size: 0.9rem;
    margin-top: 0; }
  .card .card-drop {
    font-size: 20px;
    line-height: 0;
    color: inherit; }
  .card .card-widgets {
    float: right;
    height: 16px; }
    .card .card-widgets > a {
      color: inherit;
      font-size: 18px;
      display: inline-block;
      line-height: 1; }
      .card .card-widgets > a.collapsed i:before {
        content: "\F0415"; }

.card-title,
.card-header {
  margin-top: 0; }

.card-disabled {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0.25rem;
  background: rgba(255, 255, 255, 0.8);
  cursor: progress; }
  .card-disabled .card-portlets-loader {
    background-color: #313a46;
    animation: rotatebox 1.2s infinite ease-in-out;
    height: 30px;
    width: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -12px; }

@keyframes rotatebox {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg); }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg); }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg); } }

.card-pricing {
  position: relative; }
  .card-pricing .card-pricing-plan-name {
    padding-bottom: 20px; }
  .card-pricing .card-pricing-icon {
    font-size: 22px;
    box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
    height: 60px;
    display: inline-block;
    width: 60px;
    line-height: 56px;
    border-radius: 50%; }
  .card-pricing .card-pricing-price {
    padding: 30px 0 0; }
    .card-pricing .card-pricing-price span {
      font-size: 40%;
      color: #98a6ad;
      letter-spacing: 2px;
      text-transform: uppercase; }
  .card-pricing .card-pricing-features {
    color: #98a6ad;
    list-style: none;
    margin: 0;
    padding: 20px 0 0 0; }
    .card-pricing .card-pricing-features li {
      padding: 15px; }

@media (min-width: 992px) {
  .card-pricing-recommended {
    margin-top: -1.9375rem; } }

.card-pricing-recommended .card-pricing-plan-tag {
  background-color: rgba(255, 91, 91, 0.2);
  color: #ff5b5b;
  padding: 5px 0;
  font-weight: 700;
  border-radius: .25rem .25rem 0 0;
  margin: -1.5rem -1.5rem 1.5rem -1.5rem; }

.card-h-100 {
  height: calc(100% - 24px); }

.form-checkbox-primary .form-check-input:checked,
.form-radio-primary .form-check-input:checked {
  background-color: #536de6;
  border-color: #536de6; }

.form-checkbox-secondary .form-check-input:checked,
.form-radio-secondary .form-check-input:checked {
  background-color: #6c757d;
  border-color: #6c757d; }

.form-checkbox-success .form-check-input:checked,
.form-radio-success .form-check-input:checked {
  background-color: #10c469;
  border-color: #10c469; }

.form-checkbox-info .form-check-input:checked,
.form-radio-info .form-check-input:checked {
  background-color: #35b8e0;
  border-color: #35b8e0; }

.form-checkbox-warning .form-check-input:checked,
.form-radio-warning .form-check-input:checked {
  background-color: #f9c851;
  border-color: #f9c851; }

.form-checkbox-danger .form-check-input:checked,
.form-radio-danger .form-check-input:checked {
  background-color: #ff5b5b;
  border-color: #ff5b5b; }

.form-checkbox-light .form-check-input:checked,
.form-radio-light .form-check-input:checked {
  background-color: #eef2f7;
  border-color: #eef2f7; }

.form-checkbox-dark .form-check-input:checked,
.form-radio-dark .form-check-input:checked {
  background-color: #323a46;
  border-color: #323a46; }

.dropdown-menu {
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15); }

.dropdown-menu-animated.dropdown-menu-end[style] {
  left: auto !important;
  right: 0 !important; }

.dropdown-menu-animated {
  animation-name: DropDownSlide;
  animation-duration: .3s;
  animation-fill-mode: both;
  position: absolute;
  margin: 0;
  z-index: 1000; }
  .dropdown-menu-animated.show {
    top: 100% !important; }
  .dropdown-menu-animated i {
    display: inline-block; }
  .dropdown-menu-animated.dropdown-menu[data-popper-placement^=right], .dropdown-menu-animated.dropdown-menu[data-popper-placement^=top], .dropdown-menu-animated.dropdown-menu[data-popper-placement^=left] {
    top: auto !important;
    animation: none !important; }

@keyframes DropDownSlide {
  100% {
    transform: translateY(0); }
  0% {
    transform: translateY(10px); } }

@media (min-width: 600px) {
  .dropdown-lg {
    width: 320px; } }

.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  border: 1px solid transparent;
  color: #6c757d; }
  .dropdown-icon-item img {
    height: 24px; }
  .dropdown-icon-item span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .dropdown-icon-item:hover {
    background-color: #f4f6fb;
    color: #2d343f; }

.arrow-none:after {
  display: none; }

.hljs {
  display: block;
  overflow-x: auto;
  padding: 2em;
  color: #323a46;
  max-height: 420px;
  margin: -10px 0 -30px;
  border: 1px solid rgba(152, 166, 173, 0.2); }

.hljs-keyword,
.hljs-selector-tag,
.hljs-subst {
  color: #323a46;
  font-weight: bold; }

.hljs-number,
.hljs-literal,
.hljs-variable,
.hljs-template-variable,
.hljs-tag .hljs-attr {
  color: #02a8b5; }

.hljs-string,
.hljs-doctag {
  color: #ff5b5b; }

.hljs-title,
.hljs-section,
.hljs-selector-id {
  color: #ff5b5b;
  font-weight: bold; }

.hljs-subst {
  font-weight: normal; }

.hljs-tag,
.hljs-name,
.hljs-attribute {
  color: #1f4faf;
  font-weight: normal; }

.hljs-regexp,
.hljs-link {
  color: #02a8b5; }

.hljs-symbol,
.hljs-bullet {
  color: #ff679b; }

.hljs-built_in,
.hljs-builtin-name {
  color: #35b8e0; }

.hljs-meta {
  color: #343a40;
  font-weight: bold; }

.hljs-deletion {
  background: #fdd; }

.hljs-addition {
  background: #dfd; }

.hljs-emphasis {
  font-style: italic; }

.hljs-strong {
  font-weight: bold; }

.hljs-comment {
  color: #ced4da; }

.form-control-light {
  background-color: #f1f3fa !important;
  border-color: #f1f3fa !important; }

input.form-control[type="color"],
input.form-control[type="range"] {
  min-height: 39px; }

.custom-select.is-invalid:focus, .form-control.is-invalid:focus, .custom-select:invalid:focus, .form-control:invalid:focus,
.custom-select.is-valid:focus, .form-control.is-valid:focus, .custom-select:valid:focus, .form-control:valid:focus {
  box-shadow: none !important; }

select.form-control:not([size]):not([multiple]) {
  height: calc(1.5em + (0.9rem + 2px)); }

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.5em + (0.56rem + 2px)); }

.password-eye:before {
  font-family: "Material Design Icons";
  content: "\F06D0";
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  vertical-align: middle;
  line-height: 1.2;
  font-size: 16px; }

.show-password .password-eye:before {
  content: "\F06D1"; }

.modal-title {
  margin-top: 0; }

.modal-full-width {
  width: 95%;
  max-width: none; }

.modal-top {
  margin: 0 auto; }

.modal-right {
  position: absolute;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 100%;
  margin: 0;
  background-color: #fff;
  align-content: center;
  transform: translate(25%, 0) !important; }
  .modal-right button.btn-close {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1; }

.modal.show .modal-right, .modal.show .modal-left {
  transform: translate(0, 0) !important; }

.modal-bottom {
  display: flex;
  flex-flow: column nowrap;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 100%;
  margin: 0 auto;
  align-content: center; }

.modal-colored-header {
  color: #fff;
  border-radius: 0; }
  .modal-colored-header .btn-close {
    color: #fff !important; }

.modal-filled {
  color: #fff; }
  .modal-filled .modal-header {
    background-color: rgba(255, 255, 255, 0.07); }
  .modal-filled .modal-header, .modal-filled .modal-footer {
    border: none; }
  .modal-filled .btn-close {
    color: #fff !important; }

.modal-open {
  padding-right: 17px !important;
  padding-left: 0px !important; }

.nav-tabs > li > a, .nav-pills > li > a {
  color: #6c757d;
  font-weight: 600; }

.nav-pills > a {
  color: #6c757d;
  font-weight: 600; }

.bg-nav-pills {
  background-color: #eef2f7; }

.nav-tabs.nav-bordered {
  border-bottom: 2px solid rgba(152, 166, 173, 0.2); }
  .nav-tabs.nav-bordered .nav-item {
    margin-bottom: -1px; }
  .nav-tabs.nav-bordered li a {
    border: 0;
    padding: 0.625rem 1.25rem; }
  .nav-tabs.nav-bordered li a.active {
    border-bottom: 2px solid #536de6; }

.pagination-rounded .page-link {
  border-radius: 30px !important;
  margin: 0 3px !important;
  border: none; }

.popover-header {
  margin-top: 0; }

@media print {
  .leftside-menu,
  .end-bar,
  .page-title-box,
  .navbar-custom,
  .footer {
    display: none; }
  .card-body,
  .content-page,
  .end-bar,
  .content,
  body {
    padding: 0;
    margin: 0; } }

.progress-sm {
  height: 5px; }

.progress-md {
  height: 8px; }

.progress-lg {
  height: 12px; }

.progress-xl {
  height: 15px; }

body.loading {
  visibility: hidden; }

button, a {
  outline: none !important; }

label {
  font-weight: 600; }

address.address-lg {
  line-height: 24px; }

b,
strong {
  font-weight: 700; }

.ribbon-box {
  position: relative;
  /* Ribbon two */ }
  .ribbon-box .ribbon {
    position: relative;
    clear: both;
    padding: 5px 12px;
    margin-bottom: 15px;
    box-shadow: 2px 5px 10px rgba(50, 58, 70, 0.15);
    color: #fff;
    font-size: 13px;
    font-weight: 600; }
    .ribbon-box .ribbon:before {
      content: " ";
      border-style: solid;
      border-width: 10px;
      display: block;
      position: absolute;
      bottom: -10px;
      left: 0;
      margin-bottom: -10px;
      z-index: -1; }
    .ribbon-box .ribbon.float-start {
      margin-left: -30px;
      border-radius: 0 3px 3px 0; }
    .ribbon-box .ribbon.float-end {
      margin-right: -30px;
      border-radius: 3px 0 0 3px; }
      .ribbon-box .ribbon.float-end:before {
        right: 0; }
    .ribbon-box .ribbon.float-center span {
      margin: 0 auto 20px auto; }
  .ribbon-box .ribbon-content {
    clear: both; }
  .ribbon-box .ribbon-primary {
    background: #536de6; }
    .ribbon-box .ribbon-primary:before {
      border-color: #3d5ae3 transparent transparent; }
  .ribbon-box .ribbon-secondary {
    background: #6c757d; }
    .ribbon-box .ribbon-secondary:before {
      border-color: #60686f transparent transparent; }
  .ribbon-box .ribbon-success {
    background: #10c469; }
    .ribbon-box .ribbon-success:before {
      border-color: #0eac5c transparent transparent; }
  .ribbon-box .ribbon-info {
    background: #35b8e0; }
    .ribbon-box .ribbon-info:before {
      border-color: #21afda transparent transparent; }
  .ribbon-box .ribbon-warning {
    background: #f9c851; }
    .ribbon-box .ribbon-warning:before {
      border-color: #f8c038 transparent transparent; }
  .ribbon-box .ribbon-danger {
    background: #ff5b5b; }
    .ribbon-box .ribbon-danger:before {
      border-color: #ff4242 transparent transparent; }
  .ribbon-box .ribbon-light {
    background: #eef2f7; }
    .ribbon-box .ribbon-light:before {
      border-color: #dde5ef transparent transparent; }
  .ribbon-box .ribbon-dark {
    background: #323a46; }
    .ribbon-box .ribbon-dark:before {
      border-color: #272e37 transparent transparent; }
  .ribbon-box .ribbon-two {
    position: absolute;
    left: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right; }
    .ribbon-box .ribbon-two span {
      font-size: 13px;
      color: #fff;
      text-align: center;
      line-height: 20px;
      transform: rotate(-45deg);
      width: 100px;
      display: block;
      box-shadow: 0 0 8px 0 rgba(50, 58, 70, 0.08), 0 1px 0 0 rgba(50, 58, 70, 0.03);
      position: absolute;
      top: 19px;
      left: -21px;
      font-weight: 600; }
      .ribbon-box .ribbon-two span:before {
        content: "";
        position: absolute;
        left: 0;
        top: 100%;
        z-index: -1;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent; }
      .ribbon-box .ribbon-two span:after {
        content: "";
        position: absolute;
        right: 0;
        top: 100%;
        z-index: -1;
        border-left: 3px solid transparent;
        border-bottom: 3px solid transparent; }
  .ribbon-box .ribbon-two-primary span {
    background: #536de6; }
    .ribbon-box .ribbon-two-primary span:before {
      border-left: 3px solid #3d5ae3;
      border-top: 3px solid #3d5ae3; }
    .ribbon-box .ribbon-two-primary span:after {
      border-right: 3px solid #3d5ae3;
      border-top: 3px solid #3d5ae3; }
  .ribbon-box .ribbon-two-secondary span {
    background: #6c757d; }
    .ribbon-box .ribbon-two-secondary span:before {
      border-left: 3px solid #60686f;
      border-top: 3px solid #60686f; }
    .ribbon-box .ribbon-two-secondary span:after {
      border-right: 3px solid #60686f;
      border-top: 3px solid #60686f; }
  .ribbon-box .ribbon-two-success span {
    background: #10c469; }
    .ribbon-box .ribbon-two-success span:before {
      border-left: 3px solid #0eac5c;
      border-top: 3px solid #0eac5c; }
    .ribbon-box .ribbon-two-success span:after {
      border-right: 3px solid #0eac5c;
      border-top: 3px solid #0eac5c; }
  .ribbon-box .ribbon-two-info span {
    background: #35b8e0; }
    .ribbon-box .ribbon-two-info span:before {
      border-left: 3px solid #21afda;
      border-top: 3px solid #21afda; }
    .ribbon-box .ribbon-two-info span:after {
      border-right: 3px solid #21afda;
      border-top: 3px solid #21afda; }
  .ribbon-box .ribbon-two-warning span {
    background: #f9c851; }
    .ribbon-box .ribbon-two-warning span:before {
      border-left: 3px solid #f8c038;
      border-top: 3px solid #f8c038; }
    .ribbon-box .ribbon-two-warning span:after {
      border-right: 3px solid #f8c038;
      border-top: 3px solid #f8c038; }
  .ribbon-box .ribbon-two-danger span {
    background: #ff5b5b; }
    .ribbon-box .ribbon-two-danger span:before {
      border-left: 3px solid #ff4242;
      border-top: 3px solid #ff4242; }
    .ribbon-box .ribbon-two-danger span:after {
      border-right: 3px solid #ff4242;
      border-top: 3px solid #ff4242; }
  .ribbon-box .ribbon-two-light span {
    background: #eef2f7; }
    .ribbon-box .ribbon-two-light span:before {
      border-left: 3px solid #dde5ef;
      border-top: 3px solid #dde5ef; }
    .ribbon-box .ribbon-two-light span:after {
      border-right: 3px solid #dde5ef;
      border-top: 3px solid #dde5ef; }
  .ribbon-box .ribbon-two-dark span {
    background: #323a46; }
    .ribbon-box .ribbon-two-dark span:before {
      border-left: 3px solid #272e37;
      border-top: 3px solid #272e37; }
    .ribbon-box .ribbon-two-dark span:after {
      border-right: 3px solid #272e37;
      border-top: 3px solid #272e37; }

input[data-switch] {
  display: none; }
  input[data-switch] + label {
    width: 56px;
    height: 24px;
    background-color: #f1f3fa;
    background-image: none;
    border-radius: 2rem;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    position: relative;
    transition: all 0.1s ease-in-out; }
    input[data-switch] + label:before {
      color: #323a46;
      content: attr(data-off-label);
      display: block;
      font-family: inherit;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 24px;
      position: absolute;
      right: 3px;
      margin: 0 .21667rem;
      top: 0;
      text-align: center;
      min-width: 1.66667rem;
      overflow: hidden;
      transition: all 0.1s ease-in-out; }
    input[data-switch] + label:after {
      content: '';
      position: absolute;
      left: 4px;
      background-color: #adb5bd;
      box-shadow: none;
      border-radius: 2rem;
      height: 18px;
      width: 18px;
      top: 3px;
      transition: all 0.1s ease-in-out; }
  input[data-switch]:checked + label {
    background-color: #536de6; }
    input[data-switch]:checked + label:before {
      color: #fff;
      content: attr(data-on-label);
      right: auto;
      left: 4px; }
    input[data-switch]:checked + label:after {
      left: 34px;
      background-color: #f1f3fa; }

input[data-switch="bool"] + label {
  background-color: #ff5b5b; }

input:disabled + label {
  opacity: 0.5;
  cursor: default; }

input[data-switch="bool"] + label:before,
input[data-switch="bool"]:checked + label:before {
  color: #fff !important; }

input[data-switch="bool"] + label:after {
  background-color: #f1f3fa; }

input[data-switch="primary"]:checked + label {
  background-color: #536de6; }

input[data-switch="secondary"]:checked + label {
  background-color: #6c757d; }

input[data-switch="success"]:checked + label {
  background-color: #10c469; }

input[data-switch="info"]:checked + label {
  background-color: #35b8e0; }

input[data-switch="warning"]:checked + label {
  background-color: #f9c851; }

input[data-switch="danger"]:checked + label {
  background-color: #ff5b5b; }

input[data-switch="light"]:checked + label {
  background-color: #eef2f7; }

input[data-switch="dark"]:checked + label {
  background-color: #323a46; }

.table-centered th, .table-centered td {
  vertical-align: middle !important; }

.table .table-user img {
  height: 30px;
  width: 30px; }

.table .action-icon {
  color: #98a6ad;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0 3px; }
  .table .action-icon:hover {
    color: #6c757d; }

.table-nowrap th, .table-nowrap td {
  white-space: nowrap; }

.h1, .h2, .h3, .h4, .h5, .h6, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin: 10px 0;
  font-weight: 700; }

.font-10 {
  font-size: 10px !important; }

.font-11 {
  font-size: 11px !important; }

.font-12 {
  font-size: 12px !important; }

.font-13 {
  font-size: 13px !important; }

.font-14 {
  font-size: 14px !important; }

.font-15 {
  font-size: 15px !important; }

.font-16 {
  font-size: 16px !important; }

.font-18 {
  font-size: 18px !important; }

.font-20 {
  font-size: 20px !important; }

.font-22 {
  font-size: 22px !important; }

.font-24 {
  font-size: 24px !important; }

.bg-primary-lighten {
  background-color: rgba(83, 109, 230, 0.25) !important; }

.bg-secondary-lighten {
  background-color: rgba(108, 117, 125, 0.25) !important; }

.bg-success-lighten {
  background-color: rgba(16, 196, 105, 0.25) !important; }

.bg-info-lighten {
  background-color: rgba(53, 184, 224, 0.25) !important; }

.bg-warning-lighten {
  background-color: rgba(249, 200, 81, 0.25) !important; }

.bg-danger-lighten {
  background-color: rgba(255, 91, 91, 0.25) !important; }

.bg-light-lighten {
  background-color: rgba(238, 242, 247, 0.25) !important; }

.bg-dark-lighten {
  background-color: rgba(50, 58, 70, 0.25) !important; }

.fw-semibold {
  font-weight: 600 !important; }

.progress-w-percent {
  min-height: 20px;
  margin-bottom: 20px; }
  .progress-w-percent .progress {
    width: calc(100% - 50px);
    float: left;
    margin-top: 8px; }
  .progress-w-percent .progress-value {
    width: 40px;
    float: right;
    text-align: right;
    line-height: 20px; }

.widget-flat {
  position: relative;
  overflow: hidden; }
  @media (min-width: 1200px) and (max-width: 1500px) {
    .widget-flat i.widget-icon {
      display: none; } }

.widget-icon {
  color: #536de6;
  font-size: 20px;
  background-color: rgba(83, 109, 230, 0.25);
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 3px;
  display: inline-block; }

.dash-item-overlay {
  position: absolute;
  /*rtl:ignore*/
  text-align: left;
  /*rtl:ignore*/
  left: 8%;
  max-width: 350px;
  padding: 20px;
  z-index: 1; }

.chart-content-bg {
  background-color: #f9f9fd; }

.chart-content-border {
  border: 1px solid #eef2f7; }

.chart-widget-list p {
  border-bottom: 1px solid #f1f3fa;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem; }

.timeline-alt {
  padding: 20px 0;
  position: relative; }
  .timeline-alt .timeline-item {
    position: relative; }
    .timeline-alt .timeline-item:before {
      background-color: #f1f3fa;
      bottom: 0;
      content: "";
      left: 9px;
      position: absolute;
      top: 20px;
      width: 2px;
      z-index: 0; }
    .timeline-alt .timeline-item .timeline-icon {
      float: left;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 2px solid transparent;
      font-size: 12px;
      text-align: center;
      line-height: 16px;
      background-color: #fff; }
    .timeline-alt .timeline-item .timeline-item-info {
      margin-left: 30px; }

.inbox-widget .inbox-item {
  border-bottom: 1px solid white;
  overflow: hidden;
  padding: 0.625rem 0;
  position: relative; }
  .inbox-widget .inbox-item:last-of-type {
    border-bottom: none; }
  .inbox-widget .inbox-item .inbox-item-img {
    display: block;
    float: left;
    margin-right: 15px;
    width: 40px; }
    .inbox-widget .inbox-item .inbox-item-img img {
      width: 40px; }
  .inbox-widget .inbox-item .inbox-item-author {
    color: #343a40;
    display: block;
    margin-bottom: 3px; }
  .inbox-widget .inbox-item .inbox-item-text {
    color: #adb5bd;
    display: block;
    font-size: 0.8125rem;
    margin: 0; }
  .inbox-widget .inbox-item .inbox-item-date {
    color: #98a6ad;
    font-size: 0.6875rem;
    position: absolute;
    right: 5px;
    top: 10px; }

.tilebox-one i {
  position: absolute;
  right: 1.5rem;
  font-size: 2rem;
  opacity: 0.3; }

.toll-free-box i {
  position: absolute;
  left: 0;
  bottom: -15px;
  font-size: 4rem;
  opacity: 0.4;
  transform: rotate(30deg); }

.cta-box {
  background-image: url(../images/bg-pattern.png);
  background-size: cover; }
  .cta-box .cta-box-title {
    font-size: 20px;
    line-height: 30px; }

.conversation-list {
  list-style: none;
  padding: 0 15px; }
  .conversation-list li {
    margin-bottom: 20px; }
    .conversation-list li .conversation-actions {
      float: right;
      display: none; }
    .conversation-list li:hover .conversation-actions {
      display: block; }
  .conversation-list .chat-avatar {
    float: left;
    text-align: center;
    width: 42px; }
    .conversation-list .chat-avatar img {
      border-radius: 100%;
      width: 100%; }
    .conversation-list .chat-avatar i {
      font-size: 12px;
      font-style: normal; }
  .conversation-list .ctext-wrap {
    background: #f1f3fa;
    border-radius: 3px;
    display: inline-block;
    padding: 12px;
    position: relative; }
    .conversation-list .ctext-wrap i {
      display: block;
      font-size: 12px;
      font-style: normal;
      font-weight: bold;
      position: relative; }
    .conversation-list .ctext-wrap p {
      margin: 0;
      padding-top: 3px; }
    .conversation-list .ctext-wrap:after {
      left: -11px;
      top: 0;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-top-color: #f1f3fa;
      border-width: 6px;
      margin-right: -1px;
      border-right-color: #f1f3fa; }
  .conversation-list .conversation-text {
    float: left;
    font-size: 13px;
    margin-left: 12px;
    width: 70%; }
  .conversation-list .odd .chat-avatar {
    float: right !important; }
  .conversation-list .odd .conversation-text {
    float: right !important;
    margin-right: 12px;
    text-align: right;
    width: 70% !important; }
  .conversation-list .odd .ctext-wrap {
    background-color: #fef5e4; }
    .conversation-list .odd .ctext-wrap:after {
      border-color: transparent;
      border-left-color: #fef5e4;
      border-top-color: #fef5e4;
      right: -10px;
      left: auto; }
  .conversation-list .odd .conversation-actions {
    float: left; }

.calendar-widget .datepicker-inline,
.calendar-widget table {
  width: 100%; }
  .calendar-widget .datepicker-inline tr td, .calendar-widget .datepicker-inline tr th, .calendar-widget .datepicker-inline tr td.active.day, .calendar-widget .datepicker-inline tr td.today.day,
  .calendar-widget table tr td,
  .calendar-widget table tr th,
  .calendar-widget table tr td.active.day,
  .calendar-widget table tr td.today.day {
    background-color: transparent !important; }
  .calendar-widget .datepicker-inline tr td.active.day,
  .calendar-widget .datepicker-inline tr td.today.day,
  .calendar-widget table tr td.active.day,
  .calendar-widget table tr td.today.day {
    color: #ff5b5b !important;
    text-shadow: none;
    font-weight: 700; }
    .calendar-widget .datepicker-inline tr td.active.day:hover,
    .calendar-widget .datepicker-inline tr td.today.day:hover,
    .calendar-widget table tr td.active.day:hover,
    .calendar-widget table tr td.today.day:hover {
      background-color: transparent !important; }
  .calendar-widget .datepicker-inline td, .calendar-widget .datepicker-inline th,
  .calendar-widget table td,
  .calendar-widget table th {
    height: 43px; }
  .calendar-widget .datepicker-inline .datepicker-switch,
  .calendar-widget .datepicker-inline .prev,
  .calendar-widget .datepicker-inline .next,
  .calendar-widget table .datepicker-switch,
  .calendar-widget table .prev,
  .calendar-widget table .next {
    font-size: 1.1rem;
    background-color: rgba(83, 109, 230, 0.1) !important;
    border-radius: 0;
    color: #536de6; }

.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 2px);
  display: block;
  border: 2px solid #adb5bd;
  border-radius: 50%;
  color: #adb5bd; }
  .social-list-item:hover {
    color: #98a6ad;
    border-color: #98a6ad; }

.horizontal-steps {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%; }
  .horizontal-steps:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: .2em;
    background-color: #eef2f7; }
  .horizontal-steps .process-line {
    display: block;
    position: absolute;
    width: 50%;
    height: .2em;
    background-color: #536de6; }
  .horizontal-steps .horizontal-steps-content {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%; }
    .horizontal-steps .horizontal-steps-content .step-item {
      display: block;
      position: relative;
      bottom: calc(100% + 1em);
      height: 8px;
      width: 8px;
      margin: 0 2em;
      box-sizing: content-box;
      color: #536de6;
      background-color: currentColor;
      border: 0.25em solid #fafbfe;
      border-radius: 50%;
      z-index: 5; }
      .horizontal-steps .horizontal-steps-content .step-item:first-child {
        margin-left: 0; }
      .horizontal-steps .horizontal-steps-content .step-item:last-child {
        margin-right: 0;
        color: #10c469; }
      .horizontal-steps .horizontal-steps-content .step-item span {
        position: absolute;
        top: calc(100% + 1em);
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        color: #adb5bd; }
      .horizontal-steps .horizontal-steps-content .step-item.current:before {
        content: '';
        display: block;
        position: absolute;
        top: 47.5%;
        left: 51%;
        padding: 1em;
        background-color: currentColor;
        border-radius: 50%;
        opacity: 0;
        z-index: -1;
        animation-name: animation-steps-current;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-out; }
      .horizontal-steps .horizontal-steps-content .step-item.current span {
        color: #536de6; }

@keyframes animation-steps-current {
  from {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1; }
  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0; } }

@media (max-width: 767.98px) {
  .horizontal-steps .horizontal-steps-content .step-item span {
    white-space: inherit; } }

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f1f3fa;
  z-index: 9999; }

#status {
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -40px 0 0 -40px; }

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -16px, 0); } }

.bouncing-loader {
  display: flex;
  justify-content: center; }
  .bouncing-loader > div {
    width: 13px;
    height: 13px;
    margin: 32px 3px;
    background: #536de6;
    border-radius: 50%;
    animation: bouncing-loader 0.6s infinite alternate; }
    .bouncing-loader > div:nth-child(2) {
      animation-delay: 0.2s;
      background: #ff5b5b; }
    .bouncing-loader > div:nth-child(3) {
      animation-delay: 0.4s;
      background: #10c469; }

.hero-section {
  position: relative;
  padding: 80px 0 120px 0; }
  .hero-section:after {
    content: ' ';
    background-image: linear-gradient(to bottom, #6379c3, #546ee5);
    position: absolute;
    top: -400px;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    border-radius: 0;
    transform: skewY(-3deg); }
  .hero-section .hero-title {
    line-height: 42px; }

body.authentication-bg {
  background-image: url("../images/bg-pattern-light.svg");
  background-size: cover;
  background-position: center; }
  body.authentication-bg .account-pages {
    align-items: center;
    display: flex;
    min-height: 100vh; }

.authentication-bg.enlarged,
.auth-fluid-pages.enlarged,
body.auth-fluid-pages[data-leftbar-compact-mode=condensed] {
  min-height: 100px; }

.logout-icon {
  width: 140px; }

.auth-fluid {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100vh;
  flex-direction: row;
  align-items: stretch;
  background: url("../images/bg-auth.jpg") center;
  background-size: cover; }
  .auth-fluid .auth-fluid-form-box {
    max-width: 480px;
    border-radius: 0;
    z-index: 2;
    padding: 3rem 2rem;
    background-color: #ffffff;
    position: relative;
    width: 100%; }
  .auth-fluid .auth-fluid-right, .auth-fluid .auth-fluid-left {
    padding: 6rem 3rem;
    flex: 1;
    position: relative;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.3); }

.auth-brand {
  margin-bottom: 2rem; }
  .auth-brand .logo-dark {
    display: block; }
  .auth-brand .logo-light {
    display: none; }

.auth-user-testimonial {
  position: absolute;
  margin: 0 auto;
  padding: 0 1.75rem;
  bottom: 3rem;
  left: 0;
  right: 0; }
  .auth-user-testimonial p.lead {
    font-size: 1.125rem;
    margin: 0 auto 20px auto;
    max-width: 700px; }

@media (min-width: 992px) {
  .auth-brand {
    position: absolute;
    top: 3rem; } }

@media (max-width: 991.98px) {
  .auth-fluid {
    display: block; }
    .auth-fluid .auth-fluid-form-box {
      max-width: 100%;
      min-height: 100vh; }
    .auth-fluid .auth-fluid-right {
      display: none; } }

.button-list {
  margin-left: -8px;
  margin-bottom: -12px; }
  .button-list .btn {
    margin-bottom: 12px;
    margin-left: 8px; }

.scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: .5rem;
  overflow: auto; }

.grid-structure .grid-container {
  background-color: #f1f3fa;
  margin-bottom: 10px;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 10px 20px; }

.icons-list-demo div {
  cursor: pointer;
  line-height: 45px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden; }
  .icons-list-demo div p {
    margin-bottom: 0;
    line-height: inherit; }

.icons-list-demo i {
  text-align: center;
  vertical-align: middle;
  font-size: 22px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin-right: 12px;
  border-radius: 3px;
  display: inline-block;
  transition: all 0.2s; }

.icons-list-demo .col-md-4 {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  background-clip: padding-box;
  margin-bottom: 10px; }
  .icons-list-demo .col-md-4:hover,
  .icons-list-demo .col-md-4:hover i {
    color: #536de6; }

.text-error {
  color: #536de6;
  text-shadow: rgba(83, 109, 230, 0.3) 5px 1px, rgba(83, 109, 230, 0.2) 10px 3px;
  font-size: 5.25rem;
  line-height: 5.625rem; }

.faq-question-q-box {
  height: 30px;
  width: 30px;
  color: #536de6;
  background-color: rgba(83, 109, 230, 0.25);
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  text-align: center;
  border-radius: 50%;
  float: left;
  font-weight: 700;
  line-height: 30px; }

.faq-question {
  margin-top: 0;
  margin-left: 50px;
  font-weight: 600;
  font-size: 16px;
  color: #323a46; }

.faq-answer {
  margin-left: 50px; }

.maintenance-icon {
  font-size: 22px;
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  height: 60px;
  display: inline-block;
  width: 60px;
  line-height: 58px;
  border-radius: 50%; }

.board {
  display: block;
  white-space: nowrap;
  overflow-x: auto; }

.tasks {
  display: inline-block;
  width: 22rem;
  padding: 0 1rem 1rem 1rem;
  border: 1px solid #eef2f7;
  vertical-align: top;
  margin-bottom: 24px;
  border-radius: 0.25rem; }
  .tasks.tasks:not(:last-child) {
    margin-right: 1.25rem; }
  .tasks .card {
    white-space: normal;
    margin-top: 1rem; }
  .tasks .task-header {
    background-color: #f1f3fa;
    padding: 1rem;
    margin: 0 -1rem; }

.task-list-items {
  min-height: 100px;
  position: relative; }
  .task-list-items:before {
    content: "No Tasks";
    position: absolute;
    line-height: 110px;
    width: 100%;
    text-align: center;
    font-weight: 600; }

.task-modal-content .form-control-light {
  background-color: #f7f9fb !important;
  border-color: #f7f9fb !important; }

.gantt-task-details {
  min-width: 220px; }

.page-aside-left {
  width: 240px;
  float: left;
  padding: 0 20px 20px 10px;
  position: relative; }
  .page-aside-left:before {
    content: "";
    background-color: #fafbfe;
    width: 5px;
    position: absolute;
    right: -15px;
    height: 100%;
    bottom: -1.5rem; }

.page-aside-right {
  margin: -1.5rem 0 -1.5rem 250px;
  border-left: 5px solid #fafbfe;
  padding: 1.5rem 0 1.5rem 25px; }

.email-list {
  display: block;
  padding-left: 0;
  overflow: hidden; }
  .email-list > li {
    position: relative;
    display: block;
    height: 51px;
    line-height: 50px;
    cursor: default;
    transition-duration: .3s; }
    .email-list > li a {
      color: #6c757d; }
      .email-list > li a:hover {
        color: #343a40; }
    .email-list > li .col-mail {
      float: left;
      position: relative; }
    .email-list > li .email-sender-info {
      width: 320px; }
      .email-list > li .email-sender-info .star-toggle,
      .email-list > li .email-sender-info .checkbox-wrapper-mail {
        display: block;
        float: left; }
      .email-list > li .email-sender-info .checkbox-wrapper-mail {
        margin: 15px 10px 0 20px;
        cursor: pointer;
        height: 20px;
        width: 20px; }
      .email-list > li .email-sender-info .star-toggle {
        color: #adb5bd;
        margin-left: 10px;
        font-size: 18px; }
      .email-list > li .email-sender-info .email-title {
        position: absolute;
        top: 0;
        left: 100px;
        right: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-bottom: 0;
        line-height: 50px; }
    .email-list > li .email-content {
      position: absolute;
      top: 0;
      left: 320px;
      right: 0;
      bottom: 0; }
      .email-list > li .email-content .email-subject,
      .email-list > li .email-content .email-date {
        position: absolute;
        top: 0; }
      .email-list > li .email-content .email-subject {
        left: 0;
        right: 110px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
      .email-list > li .email-content .email-date {
        right: 0;
        width: 100px;
        text-align: right;
        padding-left: 10px; }
    .email-list > li.active, .email-list > li.mail-selected {
      background: #f1f3fa;
      transition-duration: .05s; }
    .email-list > li.unread a {
      font-weight: 600;
      color: #272e37; }
    .email-list > li .email-action-icons {
      opacity: 0; }
      .email-list > li .email-action-icons ul {
        display: flex;
        position: absolute;
        transition: all 0.5s;
        right: -180px; }
        .email-list > li .email-action-icons ul li {
          margin: 0 10px; }
        .email-list > li .email-action-icons ul .email-action-icons-item {
          font-size: 20px;
          transition: all 0.5s; }
          .email-list > li .email-action-icons ul .email-action-icons-item:hover {
            color: #ff5b5b; }
    .email-list > li:hover {
      background: #f1f3fa;
      transition-duration: .05s; }
      .email-list > li:hover .email-action-icons {
        opacity: 1; }
        .email-list > li:hover .email-action-icons ul {
          transition: all 0.5s;
          right: 10px; }
      .email-list > li:hover .email-content .email-date {
        opacity: 0; }
      .email-list > li:hover .email-content .email-subject {
        right: 180px;
        transition: all 0.5s; }

.email-menu-list a {
  color: #6c757d;
  padding: 12px 5px;
  display: block;
  font-size: 15px; }
  .email-menu-list a:hover {
    color: #343a40; }
  .email-menu-list a .badge {
    margin-top: 3px; }

.labels-list a {
  padding: 7px 5px; }

.write-mdg-box .CodeMirror {
  height: 150px; }

@media (max-width: 648px) {
  .page-aside-left {
    width: 100%;
    float: none;
    padding: 0 10px 20px 10px; }
    .page-aside-left:before {
      width: 0; }
  .page-aside-right {
    margin-left: 0;
    border: 0;
    padding-left: 0; }
  .email-list li .email-sender-info .checkbox-wrapper-mail {
    margin-left: 0; } }

@media (max-width: 520px) {
  .page-aside-right > .btn-group {
    margin-bottom: 10px; }
  .email-list li .email-sender-info {
    width: 150px; }
    .email-list li .email-sender-info .email-title {
      left: 80px; }
  .email-list li .email-content {
    display: none; } }

.timeline {
  margin-bottom: 50px;
  position: relative; }
  .timeline:before {
    background-color: #dee2e6;
    bottom: 0;
    content: "";
    left: 50%;
    position: absolute;
    top: 30px;
    width: 2px;
    z-index: 0; }

.timeline-show {
  position: relative; }
  .timeline-show .time-show-name {
    display: inline-block;
    border-radius: 4px;
    background-color: #eef2f7;
    padding: 7px 15px; }

.timeline-box {
  background-color: #fff;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  display: block;
  margin: 15px 0;
  position: relative;
  padding: 1.5rem;
  border-radius: 0.25rem; }

.timeline-album {
  margin-top: 12px; }
  .timeline-album a {
    display: inline-block;
    margin-right: 5px; }
  .timeline-album img {
    height: 36px;
    width: auto;
    border-radius: 3px; }

@media (min-width: 768px) {
  .timeline .timeline-box {
    margin-left: 45px; }
  .timeline .timeline-icon {
    background: #dee2e6;
    border-radius: 50%;
    display: block;
    height: 24px;
    left: -56px;
    margin-top: -12px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 24px; }
    .timeline .timeline-icon i {
      color: #98a6ad;
      font-size: 1rem;
      vertical-align: middle; }
  .timeline .timeline-desk {
    display: table-cell;
    vertical-align: top;
    width: 50%; }
  .timeline-lg-item {
    display: table-row; }
    .timeline-lg-item:before {
      content: "";
      display: block;
      width: 50%; }
    .timeline-lg-item .timeline-desk .arrow {
      border-bottom: 12px solid transparent;
      border-right: 12px solid #fff !important;
      border-top: 12px solid transparent;
      display: block;
      height: 0;
      left: -12px;
      margin-top: -12px;
      position: absolute;
      top: 50%;
      width: 0; }
    .timeline-lg-item.timeline-item-left:after {
      content: "";
      display: block;
      width: 50%; }
    .timeline-lg-item.timeline-item-left .timeline-desk .arrow-alt {
      border-bottom: 12px solid transparent;
      border-left: 12px solid #fff !important;
      border-top: 12px solid transparent;
      display: block;
      height: 0;
      left: auto;
      margin-top: -12px;
      position: absolute;
      right: -12px;
      top: 50%;
      width: 0; }
    .timeline-lg-item.timeline-item-left .timeline-desk .album {
      float: right;
      margin-top: 20px; }
      .timeline-lg-item.timeline-item-left .timeline-desk .album a {
        float: right;
        margin-left: 5px; }
    .timeline-lg-item.timeline-item-left .timeline-icon {
      left: auto;
      right: -58px; }
    .timeline-lg-item.timeline-item-left:before {
      display: none; }
    .timeline-lg-item.timeline-item-left .timeline-box {
      margin-right: 45px;
      margin-left: 0; } }

@media (max-width: 767.98px) {
  .timeline .timeline-icon {
    display: none; } }

.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: arial;
  font-size: 15px;
  line-height: 1em; }

.daterangepicker:before, .daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: ''; }

.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc; }

.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent; }

.daterangepicker.opensleft:before {
  right: 9px; }

.daterangepicker.opensleft:after {
  right: 10px; }

.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto; }

.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto; }

.daterangepicker.opensright:before {
  left: 9px; }

.daterangepicker.opensright:after {
  left: 10px; }

.daterangepicker.drop-up {
  margin-top: -7px; }

.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc; }

.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff; }

.daterangepicker.single .daterangepicker .ranges, .daterangepicker.single .drp-calendar {
  float: none; }

.daterangepicker.single .drp-selected {
  display: none; }

.daterangepicker.show-calendar .drp-calendar {
  display: block; }

.daterangepicker.show-calendar .drp-buttons {
  display: block; }

.daterangepicker.auto-apply .drp-buttons {
  display: none; }

.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px; }

.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px; }

.daterangepicker .drp-calendar.right {
  padding: 8px; }

.daterangepicker .drp-calendar.single .calendar-table {
  border: none; }

.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px; }

.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer; }

.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff; }

.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse; }

.daterangepicker td.available:hover, .daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit; }

.daterangepicker td.week, .daterangepicker th.week {
  font-size: 80%;
  color: #ccc; }

.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #999; }

.daterangepicker td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0; }

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px; }

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0; }

.daterangepicker td.start-date.end-date {
  border-radius: 4px; }

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #357ebd;
  border-color: transparent;
  color: #fff; }

.daterangepicker th.month {
  width: auto; }

.daterangepicker td.disabled, .daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through; }

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default; }

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%; }

.daterangepicker select.yearselect {
  width: 40%; }

.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px; }

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative; }

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed; }

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  line-height: 12px;
  vertical-align: middle; }

.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px; }

.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px; }

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd; }

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd; }

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd; }

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd; }

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0; }

.daterangepicker.show-calendar .ranges {
  margin-top: 8px; }

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%; }

.daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer; }

.daterangepicker .ranges li:hover {
  background-color: #eee; }

.daterangepicker .ranges li.active {
  background-color: #08c;
  color: #fff; }

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto; }
  .daterangepicker .ranges ul {
    width: 140px; }
  .daterangepicker.single .ranges ul {
    width: 100%; }
  .daterangepicker.single .drp-calendar.left {
    clear: none; }
  .daterangepicker.single .ranges, .daterangepicker.single .drp-calendar {
    float: left; }
  .daterangepicker {
    direction: ltr;
    text-align: left; }
  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0; }
  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .daterangepicker .drp-calendar.right {
    margin-left: 0; }
  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px; }
  .daterangepicker .ranges, .daterangepicker .drp-calendar {
    float: left; } }

@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto; }
  .daterangepicker .ranges {
    float: left; }
  .daterangepicker.rtl .ranges {
    float: right; }
  .daterangepicker .drp-calendar.left {
    clear: none !important; } }

/*!
 * Datepicker for Bootstrap v1.9.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */
.datepicker {
  padding: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  direction: ltr; }

.datepicker-inline {
  width: 220px; }

.datepicker-rtl {
  direction: rtl; }

.datepicker-rtl.dropdown-menu {
  left: auto; }

.datepicker-rtl table tr td span {
  float: right; }

.datepicker-dropdown {
  top: 0;
  left: 0; }

.datepicker-dropdown:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #999;
  border-top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute; }

.datepicker-dropdown:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-top: 0;
  position: absolute; }

.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px; }

.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px; }

.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px; }

.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px; }

.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px; }

.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px; }

.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #999; }

.datepicker-dropdown.datepicker-orient-top:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff; }

.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.datepicker td, .datepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: none; }

.table-striped .datepicker table tr td, .table-striped .datepicker table tr th {
  background-color: transparent; }

.datepicker table tr td.day.focused, .datepicker table tr td.day:hover {
  background: #eee;
  cursor: pointer; }

.datepicker table tr td.new, .datepicker table tr td.old {
  color: #999; }

.datepicker table tr td.disabled, .datepicker table tr td.disabled:hover {
  background: 0 0;
  color: #999;
  cursor: default; }

.datepicker table tr td.highlighted {
  background: #d9edf7;
  border-radius: 0; }

.datepicker table tr td.today, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today:hover {
  background-color: #fde19a;
  background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
  background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
  border-color: #fdf59a #fdf59a #fbed50;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #000; }

.datepicker table tr td.today.active, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled.active, .datepicker table tr td.today.disabled.disabled, .datepicker table tr td.today.disabled:active, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today.disabled:hover.active, .datepicker table tr td.today.disabled:hover.disabled, .datepicker table tr td.today.disabled:hover:active, .datepicker table tr td.today.disabled:hover:hover, .datepicker table tr td.today.disabled:hover[disabled], .datepicker table tr td.today.disabled[disabled], .datepicker table tr td.today:active, .datepicker table tr td.today:hover, .datepicker table tr td.today:hover.active, .datepicker table tr td.today:hover.disabled, .datepicker table tr td.today:hover:active, .datepicker table tr td.today:hover:hover, .datepicker table tr td.today:hover[disabled], .datepicker table tr td.today[disabled] {
  background-color: #fdf59a; }

.datepicker table tr td.today.active, .datepicker table tr td.today.disabled.active, .datepicker table tr td.today.disabled:active, .datepicker table tr td.today.disabled:hover.active, .datepicker table tr td.today.disabled:hover:active, .datepicker table tr td.today:active, .datepicker table tr td.today:hover.active, .datepicker table tr td.today:hover:active {
  background-color: #fbf069\9; }

.datepicker table tr td.today:hover:hover {
  color: #000; }

.datepicker table tr td.today.active:hover {
  color: #fff; }

.datepicker table tr td.range, .datepicker table tr td.range.disabled, .datepicker table tr td.range.disabled:hover, .datepicker table tr td.range:hover {
  background: #eee;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.datepicker table tr td.range.today, .datepicker table tr td.range.today.disabled, .datepicker table tr td.range.today.disabled:hover, .datepicker table tr td.range.today:hover {
  background-color: #f3d17a;
  background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
  background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
  border-color: #f3e97a #f3e97a #edde34;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.datepicker table tr td.range.today.active, .datepicker table tr td.range.today.disabled, .datepicker table tr td.range.today.disabled.active, .datepicker table tr td.range.today.disabled.disabled, .datepicker table tr td.range.today.disabled:active, .datepicker table tr td.range.today.disabled:hover, .datepicker table tr td.range.today.disabled:hover.active, .datepicker table tr td.range.today.disabled:hover.disabled, .datepicker table tr td.range.today.disabled:hover:active, .datepicker table tr td.range.today.disabled:hover:hover, .datepicker table tr td.range.today.disabled:hover[disabled], .datepicker table tr td.range.today.disabled[disabled], .datepicker table tr td.range.today:active, .datepicker table tr td.range.today:hover, .datepicker table tr td.range.today:hover.active, .datepicker table tr td.range.today:hover.disabled, .datepicker table tr td.range.today:hover:active, .datepicker table tr td.range.today:hover:hover, .datepicker table tr td.range.today:hover[disabled], .datepicker table tr td.range.today[disabled] {
  background-color: #f3e97a; }

.datepicker table tr td.range.today.active, .datepicker table tr td.range.today.disabled.active, .datepicker table tr td.range.today.disabled:active, .datepicker table tr td.range.today.disabled:hover.active, .datepicker table tr td.range.today.disabled:hover:active, .datepicker table tr td.range.today:active, .datepicker table tr td.range.today:hover.active, .datepicker table tr td.range.today:hover:active {
  background-color: #efe24b\9; }

.datepicker table tr td.selected, .datepicker table tr td.selected.disabled, .datepicker table tr td.selected.disabled:hover, .datepicker table tr td.selected:hover {
  background-color: #9e9e9e;
  background-image: -moz-linear-gradient(to bottom, #b3b3b3, grey);
  background-image: -ms-linear-gradient(to bottom, #b3b3b3, grey);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(grey));
  background-image: -webkit-linear-gradient(to bottom, #b3b3b3, grey);
  background-image: -o-linear-gradient(to bottom, #b3b3b3, grey);
  background-image: linear-gradient(to bottom, #b3b3b3, grey);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
  border-color: grey grey #595959;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

.datepicker table tr td.selected.active, .datepicker table tr td.selected.disabled, .datepicker table tr td.selected.disabled.active, .datepicker table tr td.selected.disabled.disabled, .datepicker table tr td.selected.disabled:active, .datepicker table tr td.selected.disabled:hover, .datepicker table tr td.selected.disabled:hover.active, .datepicker table tr td.selected.disabled:hover.disabled, .datepicker table tr td.selected.disabled:hover:active, .datepicker table tr td.selected.disabled:hover:hover, .datepicker table tr td.selected.disabled:hover[disabled], .datepicker table tr td.selected.disabled[disabled], .datepicker table tr td.selected:active, .datepicker table tr td.selected:hover, .datepicker table tr td.selected:hover.active, .datepicker table tr td.selected:hover.disabled, .datepicker table tr td.selected:hover:active, .datepicker table tr td.selected:hover:hover, .datepicker table tr td.selected:hover[disabled], .datepicker table tr td.selected[disabled] {
  background-color: grey; }

.datepicker table tr td.selected.active, .datepicker table tr td.selected.disabled.active, .datepicker table tr td.selected.disabled:active, .datepicker table tr td.selected.disabled:hover.active, .datepicker table tr td.selected.disabled:hover:active, .datepicker table tr td.selected:active, .datepicker table tr td.selected:hover.active, .datepicker table tr td.selected:hover:active {
  background-color: #666\9; }

.datepicker table tr td.active, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active:hover {
  background-color: #006dcc;
  background-image: -moz-linear-gradient(to bottom, #08c, #04c);
  background-image: -ms-linear-gradient(to bottom, #08c, #04c);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#04c));
  background-image: -webkit-linear-gradient(to bottom, #08c, #04c);
  background-image: -o-linear-gradient(to bottom, #08c, #04c);
  background-image: linear-gradient(to bottom, #08c, #04c);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08c', endColorstr='#0044cc', GradientType=0);
  border-color: #04c #04c #002a80;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

.datepicker table tr td.active.active, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled.disabled, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active.disabled:hover.active, .datepicker table tr td.active.disabled:hover.disabled, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active.disabled:hover:hover, .datepicker table tr td.active.disabled:hover[disabled], .datepicker table tr td.active.disabled[disabled], .datepicker table tr td.active:active, .datepicker table tr td.active:hover, .datepicker table tr td.active:hover.active, .datepicker table tr td.active:hover.disabled, .datepicker table tr td.active:hover:active, .datepicker table tr td.active:hover:hover, .datepicker table tr td.active:hover[disabled], .datepicker table tr td.active[disabled] {
  background-color: #04c; }

.datepicker table tr td.active.active, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover.active, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active:active, .datepicker table tr td.active:hover.active, .datepicker table tr td.active:hover:active {
  background-color: #039\9; }

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

.datepicker table tr td span.focused, .datepicker table tr td span:hover {
  background: #eee; }

.datepicker table tr td span.disabled, .datepicker table tr td span.disabled:hover {
  background: 0 0;
  color: #999;
  cursor: default; }

.datepicker table tr td span.active, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active:hover {
  background-color: #006dcc;
  background-image: -moz-linear-gradient(to bottom, #08c, #04c);
  background-image: -ms-linear-gradient(to bottom, #08c, #04c);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#04c));
  background-image: -webkit-linear-gradient(to bottom, #08c, #04c);
  background-image: -o-linear-gradient(to bottom, #08c, #04c);
  background-image: linear-gradient(to bottom, #08c, #04c);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08c', endColorstr='#0044cc', GradientType=0);
  border-color: #04c #04c #002a80;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

.datepicker table tr td span.active.active, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled.disabled, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active.disabled:hover.active, .datepicker table tr td span.active.disabled:hover.disabled, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active.disabled:hover:hover, .datepicker table tr td span.active.disabled:hover[disabled], .datepicker table tr td span.active.disabled[disabled], .datepicker table tr td span.active:active, .datepicker table tr td span.active:hover, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active:hover.disabled, .datepicker table tr td span.active:hover:active, .datepicker table tr td span.active:hover:hover, .datepicker table tr td span.active:hover[disabled], .datepicker table tr td span.active[disabled] {
  background-color: #04c; }

.datepicker table tr td span.active.active, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover.active, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active:active, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active:hover:active {
  background-color: #039\9; }

.datepicker table tr td span.new, .datepicker table tr td span.old {
  color: #999; }

.datepicker .datepicker-switch {
  width: 145px; }

.datepicker .datepicker-switch, .datepicker .next, .datepicker .prev, .datepicker tfoot tr th {
  cursor: pointer; }

.datepicker .datepicker-switch:hover, .datepicker .next:hover, .datepicker .prev:hover, .datepicker tfoot tr th:hover {
  background: #eee; }

.datepicker .next.disabled, .datepicker .prev.disabled {
  visibility: hidden; }

.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle; }

.input-append.date .add-on, .input-prepend.date .add-on {
  cursor: pointer; }

.input-append.date .add-on i, .input-prepend.date .add-on i {
  margin-top: 3px; }

.input-daterange input {
  text-align: center; }

.input-daterange input:first-child {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px; }

.input-daterange input:last-child {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0; }

.input-daterange .add-on {
  display: inline-block;
  width: auto;
  min-width: 16px;
  height: 18px;
  padding: 4px 5px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  vertical-align: middle;
  background-color: #eee;
  border: 1px solid #ccc;
  margin-left: -5px;
  margin-right: -5px; }

.jq-toast-wrap, .jq-toast-wrap * {
  margin: 0;
  padding: 0; }

.jq-toast-wrap {
  display: block;
  position: fixed;
  width: 250px;
  pointer-events: none !important;
  letter-spacing: normal;
  z-index: 9000 !important; }

.jq-toast-wrap.bottom-left {
  bottom: 20px;
  left: 20px; }

.jq-toast-wrap.bottom-right {
  bottom: 20px;
  right: 40px; }

.jq-toast-wrap.top-left {
  top: 20px;
  left: 20px; }

.jq-toast-wrap.top-right {
  top: 20px;
  right: 40px; }

.jq-toast-single {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 0 0 5px;
  border-radius: 4px;
  font-size: 12px;
  font-family: arial,sans-serif;
  line-height: 17px;
  position: relative;
  pointer-events: all !important;
  background-color: #444;
  color: #fff; }

.jq-toast-single h2, .jq-toast-single .h2 {
  font-family: arial,sans-serif;
  font-size: 14px;
  margin: 0 0 7px;
  background: 0 0;
  color: inherit;
  line-height: inherit;
  letter-spacing: normal; }

.jq-toast-single a {
  color: #eee;
  text-decoration: none;
  font-weight: 700;
  border-bottom: 1px solid #fff;
  padding-bottom: 3px;
  font-size: 12px; }

.jq-toast-single ul {
  margin: 0 0 0 15px;
  background: 0 0;
  padding: 0; }

.jq-toast-single ul li {
  list-style-type: disc !important;
  line-height: 17px;
  background: 0 0;
  margin: 0;
  padding: 0;
  letter-spacing: normal; }

.close-jq-toast-single {
  position: absolute;
  top: 3px;
  right: 7px;
  font-size: 14px;
  cursor: pointer; }

.jq-toast-loader {
  display: block;
  position: absolute;
  top: -2px;
  height: 5px;
  width: 0;
  left: 0;
  border-radius: 5px;
  background: red; }

.jq-toast-loaded {
  width: 100%; }

.jq-has-icon {
  padding: 10px 10px 10px 50px;
  background-repeat: no-repeat;
  background-position: 10px; }

.jq-icon-info {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=);
  background-color: #31708f;
  color: #d9edf7;
  border-color: #bce8f1; }

.jq-icon-warning {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=);
  background-color: #8a6d3b;
  color: #fcf8e3;
  border-color: #faebcc; }

.jq-icon-error {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=);
  background-color: #a94442;
  color: #f2dede;
  border-color: #ebccd1; }

.jq-icon-success {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==);
  color: #dff0d8;
  background-color: #3c763d;
  border-color: #d6e9c6; }

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative; }

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px; }

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap; }

.select2-container .select2-search--inline {
  float: left; }

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0; }

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none; }

.select2-results__option[aria-selected] {
  cursor: pointer; }

.select2-container--open .select2-dropdown {
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display: block;
  padding: 4px; }

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box; }

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-search--dropdown.select2-search--hide {
  display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; }

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold; }

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px; }

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text; }

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%; }

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none; }

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
  padding: 1px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--default .select2-results__option[role=group] {
  padding: 0; }

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd; }

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white; }

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: -o-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: linear-gradient(to bottom, #fff 50%, #eee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px; }

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999; }

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #ccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto; }

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: -o-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #fff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0; }

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px; }

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0; }

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none; }

.select2-container--classic .select2-dropdown {
  background-color: #fff;
  border: 1px solid transparent; }

.select2-container--classic .select2-dropdown--above {
  border-bottom: none; }

.select2-container--classic .select2-dropdown--below {
  border-top: none; }

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--classic .select2-results__option[role=group] {
  padding: 0; }

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey; }

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: #fff; }

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb; }

.jq-toast-wrap, .jq-toast-wrap * {
  margin: 0;
  padding: 0; }

.jq-toast-wrap {
  display: block;
  position: fixed;
  width: 250px;
  pointer-events: none !important;
  letter-spacing: normal;
  z-index: 9000 !important; }

.jq-toast-wrap.bottom-left {
  bottom: 20px;
  left: 20px; }

.jq-toast-wrap.bottom-right {
  bottom: 20px;
  right: 40px; }

.jq-toast-wrap.top-left {
  top: 20px;
  left: 20px; }

.jq-toast-wrap.top-right {
  top: 20px;
  right: 40px; }

.jq-toast-single {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 0 0 5px;
  border-radius: 4px;
  font-size: 12px;
  font-family: arial,sans-serif;
  line-height: 17px;
  position: relative;
  pointer-events: all !important;
  background-color: #444;
  color: #fff; }

.jq-toast-single h2, .jq-toast-single .h2 {
  font-family: arial,sans-serif;
  font-size: 14px;
  margin: 0 0 7px;
  background: 0 0;
  color: inherit;
  line-height: inherit;
  letter-spacing: normal; }

.jq-toast-single a {
  color: #eee;
  text-decoration: none;
  font-weight: 700;
  border-bottom: 1px solid #fff;
  padding-bottom: 3px;
  font-size: 12px; }

.jq-toast-single ul {
  margin: 0 0 0 15px;
  background: 0 0;
  padding: 0; }

.jq-toast-single ul li {
  list-style-type: disc !important;
  line-height: 17px;
  background: 0 0;
  margin: 0;
  padding: 0;
  letter-spacing: normal; }

.close-jq-toast-single {
  position: absolute;
  top: 3px;
  right: 7px;
  font-size: 14px;
  cursor: pointer; }

.jq-toast-loader {
  display: block;
  position: absolute;
  top: -2px;
  height: 5px;
  width: 0;
  left: 0;
  border-radius: 5px;
  background: red; }

.jq-toast-loaded {
  width: 100%; }

.jq-has-icon {
  padding: 10px 10px 10px 50px;
  background-repeat: no-repeat;
  background-position: 10px; }

.jq-icon-info {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=);
  background-color: #31708f;
  color: #d9edf7;
  border-color: #bce8f1; }

.jq-icon-warning {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=);
  background-color: #8a6d3b;
  color: #fcf8e3;
  border-color: #faebcc; }

.jq-icon-error {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=);
  background-color: #a94442;
  color: #f2dede;
  border-color: #ebccd1; }

.jq-icon-success {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==);
  color: #dff0d8;
  background-color: #3c763d;
  border-color: #d6e9c6; }

/*!
 * Timepicker Component for Twitter Bootstrap
 *
 * Copyright 2013 Joris de Wit
 *
 * Contributors https://github.com/jdewit/bootstrap-timepicker/graphs/contributors
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
.bootstrap-timepicker {
  position: relative; }

.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu {
  left: auto;
  right: 0; }

.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:before {
  left: auto;
  right: 12px; }

.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:after {
  left: auto;
  right: 13px; }

.bootstrap-timepicker .input-group-addon {
  cursor: pointer; }

.bootstrap-timepicker .input-group-addon i {
  display: inline-block;
  width: 16px;
  height: 16px; }

.bootstrap-timepicker-widget.dropdown-menu {
  padding: 4px; }

.bootstrap-timepicker-widget.dropdown-menu.open {
  display: inline-block; }

.bootstrap-timepicker-widget.dropdown-menu:before {
  border-bottom: 7px solid rgba(0, 0, 0, 0.2);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  content: "";
  display: inline-block;
  position: absolute; }

.bootstrap-timepicker-widget.dropdown-menu:after {
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  content: "";
  display: inline-block;
  position: absolute; }

.bootstrap-timepicker-widget.timepicker-orient-left:before {
  left: 6px; }

.bootstrap-timepicker-widget.timepicker-orient-left:after {
  left: 7px; }

.bootstrap-timepicker-widget.timepicker-orient-right:before {
  right: 6px; }

.bootstrap-timepicker-widget.timepicker-orient-right:after {
  right: 7px; }

.bootstrap-timepicker-widget.timepicker-orient-top:before {
  top: -7px; }

.bootstrap-timepicker-widget.timepicker-orient-top:after {
  top: -6px; }

.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #999; }

.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff; }

.bootstrap-timepicker-widget a.btn, .bootstrap-timepicker-widget input {
  border-radius: 4px; }

.bootstrap-timepicker-widget table {
  width: 100%;
  margin: 0; }

.bootstrap-timepicker-widget table td {
  text-align: center;
  height: 30px;
  margin: 0;
  padding: 2px; }

.bootstrap-timepicker-widget table td:not(.separator) {
  min-width: 30px; }

.bootstrap-timepicker-widget table td span {
  width: 100%; }

.bootstrap-timepicker-widget table td a {
  border: 1px transparent solid;
  width: 100%;
  display: inline-block;
  margin: 0;
  padding: 8px 0;
  outline: 0;
  color: #333; }

.bootstrap-timepicker-widget table td a:hover {
  text-decoration: none;
  background-color: #eee;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border-color: #ddd; }

.bootstrap-timepicker-widget table td a i {
  margin-top: 2px;
  font-size: 18px; }

.bootstrap-timepicker-widget table td input {
  width: 25px;
  margin: 0;
  text-align: center; }

.bootstrap-timepicker-widget .modal-content {
  padding: 4px; }

@media (min-width: 767px) {
  .bootstrap-timepicker-widget.modal {
    width: 200px;
    margin-left: -100px; } }

@media (max-width: 767px) {
  .bootstrap-timepicker {
    width: 100%; }
  .bootstrap-timepicker .dropdown-menu {
    width: 100%; } }

.bootstrap-touchspin .input-group-btn-vertical {
  position: absolute;
  right: 0;
  height: 100%;
  z-index: 11; }

.bootstrap-touchspin .input-group-btn-vertical > .btn {
  position: absolute;
  right: 0;
  height: 50%;
  padding: 0;
  width: 2em;
  text-align: center;
  line-height: 1; }

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0 4px 0 0;
  top: 0; }

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  border-radius: 0 0 4px 0;
  bottom: 0; }

.apex-charts {
  min-height: 10px !important; }
  .apex-charts text {
    font-family: "Nunito", sans-serif !important;
    fill: #adb5bd; }
  .apex-charts .apexcharts-canvas {
    margin: 0 auto; }

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
  font-family: "Nunito", sans-serif !important; }

.apexcharts-legend-series {
  font-weight: 600; }

.apexcharts-gridline {
  pointer-events: none;
  stroke: #f9f9fd; }

.apexcharts-legend-text {
  color: #98a6ad !important;
  font-family: "Nunito", sans-serif !important; }

.apexcharts-yaxis text,
.apexcharts-xaxis text {
  font-family: "Nunito", sans-serif !important;
  fill: #adb5bd; }

.apexcharts-point-annotations text,
.apexcharts-xaxis-annotations text,
.apexcharts-yaxis-annotations text {
  fill: #fff; }

.apexcharts-radar-series polygon {
  fill: transparent;
  stroke: #dee2e6; }

.apexcharts-radar-series line {
  stroke: #dee2e6; }

.apexcharts-pie-label,
.apexcharts-datalabel,
.apexcharts-datalabel-label,
.apexcharts-datalabel-value {
  fill: #fff !important; }

.apexcharts-datalabels-group text {
  fill: #adb5bd !important; }

.scatter-images-chart .apexcharts-legend {
  overflow: hidden !important;
  min-height: 17px; }

.scatter-images-chart .apexcharts-legend-marker {
  background: none !important;
  margin-right: 7px !important; }

.scatter-images-chart .apexcharts-legend-series {
  align-items: flex-start !important; }

.apexcharts-pie-series path {
  stroke: transparent !important; }

.apexcharts-track path {
  stroke: #edeff1; }

.britechart, .tick text {
  font-family: "Nunito", sans-serif;
  font-size: 0.75rem; }

.horizontal-grid-line,
.vertical-grid-line,
.extended-x-line,
.extended-y-line {
  stroke: #dee2e6; }

.tick text,
.bar-chart .percentage-label,
.donut-text,
.legend-entry-name,
.legend-entry-value {
  fill: #98a6ad; }

.calendar {
  float: left;
  margin-bottom: 0; }

.fc-view {
  margin-top: 30px; }

.none-border .modal-footer {
  border-top: none; }

.fc-toolbar {
  margin: 6px 0 5px 0 !important; }
  .fc-toolbar h2, .fc-toolbar .h2 {
    font-size: 1.25rem !important;
    line-height: 1.875rem;
    text-transform: uppercase; }

.fc-day-grid-event .fc-time {
  font-weight: 600; }

th.fc-day-header {
  padding: 0.5rem 0; }

.fc-day {
  background: transparent; }

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0; }

.fc th.fc-widget-header {
  background: #dee2e6;
  font-size: 13px;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
  font-weight: 600; }

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #dee2e6; }

.fc-unthemed td.fc-today,
.fc-unthemed .fc-divider {
  background: #dee2e6; }

.fc-button {
  background: #dee2e6;
  border: none;
  color: #6c757d;
  text-transform: capitalize;
  box-shadow: none;
  border-radius: 3px;
  margin: 0 3px;
  padding: 6px 12px;
  height: auto; }

.fc-text-arrow {
  font-family: inherit;
  font-size: 1rem; }

.fc-state-hover,
.fc-state-highlight,
.fc-cell-overlay {
  background: #dee2e6; }

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: #536de6;
  color: #fff;
  text-shadow: none; }

.fc-unthemed .fc-today {
  background: #fff; }

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center;
  color: #fff; }

.external-event {
  cursor: move;
  margin: 10px 0;
  padding: 8px 10px;
  color: #fff;
  border-radius: 4px; }

.fc-basic-view td.fc-week-number span {
  padding-right: 8px; }

.fc-basic-view td.fc-day-number {
  padding-right: 8px; }

.fc-basic-view .fc-content {
  color: #fff; }

.fc-time-grid-event .fc-content {
  color: #fff; }

.fc-daygrid-day-number {
  float: right;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 20px;
  background-color: #f1f3fa;
  border-radius: 50%;
  margin: 5px;
  font-size: 11px;
  padding: 0 !important; }

.fc-daygrid-event-dot {
  border-color: #fff; }

.fc-event-time,
.fc-event-title {
  color: #fff; }

.table-active, .table-active > td, .table-active > th,
.fc .fc-list-sticky .fc-list-day > * {
  background-color: transparent; }

.fc .fc-list-event:hover td {
  background-color: inherit; }

@media (max-width: 767.98px) {
  .fc-toolbar {
    display: block; }
    .fc-toolbar .fc-left,
    .fc-toolbar .fc-right,
    .fc-toolbar .fc-center {
      float: none;
      display: block;
      clear: both;
      margin: 10px 0; }
  .fc .fc-toolbar > * > * {
    float: none; }
  .fc-today-button {
    display: none; } }

.fc-toolbar .btn {
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  border-radius: 0.15rem; }

.fc-list-item-title,
.fc-list-item-time {
  color: #fff; }

#calendar .table-bordered td, #calendar .table-bordered th {
  border: 1px solid #eef2f7; }

[dir="rtl"] .fc-toolbar-chunk .btn-group .btn:first-child {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-radius: 0.15rem; }

[dir="rtl"] .fc-toolbar-chunk .btn-group .btn:last-child {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-radius: 0.15rem; }

.chartjs-chart {
  margin: auto;
  position: relative;
  width: 100%; }

.dataTables_wrapper.container-fluid, .dataTables_wrapper.container-sm, .dataTables_wrapper.container-md, .dataTables_wrapper.container-lg, .dataTables_wrapper.container-xl, .dataTables_wrapper.container-xxl {
  padding: 0; }

table.dataTable {
  border-collapse: collapse !important;
  margin-bottom: 15px !important; }
  table.dataTable thead .sorting:before,
  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_desc:before,
  table.dataTable thead .sorting_asc_disabled:before,
  table.dataTable thead .sorting_desc_disabled:before {
    right: 0.5rem;
    left: auto;
    content: "\F0360";
    font-family: "Material Design Icons";
    font-size: 1rem;
    top: 12px; }
  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:after {
    right: 0.5em;
    left: auto;
    content: "\F035D";
    font-family: "Material Design Icons";
    top: 18px;
    font-size: 1rem; }
  table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting {
    padding-right: 30px;
    padding-left: 0.95rem; }
  table.dataTable tbody > tr.selected, table.dataTable tbody > tr > .selected {
    background-color: #536de6; }
    table.dataTable tbody > tr.selected td, table.dataTable tbody > tr > .selected td {
      border-color: #536de6; }
  table.dataTable tbody td:focus {
    outline: none !important; }
  table.dataTable tbody th.focus, table.dataTable tbody td.focus {
    outline: 2px solid #536de6 !important;
    outline-offset: -1px;
    background-color: rgba(83, 109, 230, 0.15); }

.dataTables_info {
  font-weight: 600; }

table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > th:first-child:before {
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  background-color: #10c469;
  top: auto;
  bottom: auto; }

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  background-color: #ff5b5b; }

div.dt-button-info {
  background-color: #536de6;
  border: none;
  color: #fff;
  box-shadow: none;
  border-radius: 3px;
  text-align: center;
  z-index: 21; }
  div.dt-button-info h2, div.dt-button-info .h2 {
    border-bottom: none;
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff; }

@media (max-width: 767.98px) {
  li.paginate_button.previous, li.paginate_button.next {
    display: inline-block;
    font-size: 1.5rem; }
  li.paginate_button {
    display: none; }
  .dataTables_paginate ul {
    text-align: center;
    display: block;
    margin: 1.5rem 0 0 !important; }
  div.dt-buttons {
    display: inline-table;
    margin-bottom: 1.5rem; } }

.activate-select .sorting_1 {
  background-color: #f1f3fa; }

div.dataTables_wrapper div.dataTables_filter {
  text-align: right; }
  @media (max-width: 767px) {
    div.dataTables_wrapper div.dataTables_filter {
      text-align: center; } }
  div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em;
    margin-right: 0; }

div.table-responsive >
div.dataTables_wrapper > div.row > div[class^="col-"]:last-child {
  padding-right: 0; }

div.table-responsive >
div.dataTables_wrapper > div.row > div[class^="col-"]:first-child {
  padding-left: 0; }

.daterangepicker {
  font-family: "Nunito", sans-serif;
  border: 1px solid #e7ebf0;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  background-color: #fff; }
  .daterangepicker .calendar-table {
    border: 1px solid #fff;
    background-color: #fff; }
    .daterangepicker .calendar-table td, .daterangepicker .calendar-table th {
      color: #98a6ad; }
    .daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
      border-color: #adb5bd; }
  .daterangepicker .ranges li:hover {
    background-color: #f4f6fb; }
  .daterangepicker .ranges li.active {
    background-color: #536de6; }
  .daterangepicker td.in-range {
    background-color: #edeff1;
    color: #6c757d; }
  .daterangepicker td.off, .daterangepicker td.off.end-date, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date {
    background-color: #fff;
    color: #6c757d;
    opacity: 0.5; }
  .daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: #536de6;
    color: #fff; }
  .daterangepicker td.available:hover, .daterangepicker th.available:hover {
    background-color: #536de6;
    color: #fff; }
  .daterangepicker:after {
    border-bottom: 6px solid #fff; }
  .daterangepicker:before {
    border-bottom: 7px solid #e7ebf0; }
  .daterangepicker .drp-buttons {
    border-top: 1px solid #e7ebf0; }
  .daterangepicker select.ampmselect, .daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect {
    background: white;
    border: 1px solid white;
    color: #6c757d; }
  .daterangepicker.show-ranges .drp-calendar.left {
    border-left: 1px solid #e7ebf0; }

.datepicker {
  padding: 10px !important; }
  .datepicker td, .datepicker th {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 13px;
    line-height: 28px; }
  .datepicker table tr td.active.active, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled.disabled, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active.disabled:hover.active, .datepicker table tr td.active.disabled:hover.disabled, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active.disabled:hover:hover,
  .datepicker table tr td .active.disabled:hover[disabled],
  .datepicker table tr td .active.disabled[disabled],
  .datepicker table tr td .active:active,
  .datepicker table tr td .active:hover,
  .datepicker table tr td .active:hover.active,
  .datepicker table tr td .active:hover.disabled,
  .datepicker table tr td .active:hover:active,
  .datepicker table tr td .active:hover:hover,
  .datepicker table tr td .active:hover[disabled],
  .datepicker table tr td .active[disabled],
  .datepicker table tr td span.active.active,
  .datepicker table tr td span.active.disabled,
  .datepicker table tr td span.active.disabled.active,
  .datepicker table tr td span.active.disabled.disabled,
  .datepicker table tr td span.active.disabled:active,
  .datepicker table tr td span.active.disabled:hover,
  .datepicker table tr td span.active.disabled:hover.active,
  .datepicker table tr td span.active.disabled:hover.disabled,
  .datepicker table tr td span.active.disabled:hover:active,
  .datepicker table tr td span.active.disabled:hover:hover,
  .datepicker table tr td span.active.disabled:hover[disabled],
  .datepicker table tr td span.active.disabled[disabled],
  .datepicker table tr td span.active:active,
  .datepicker table tr td span.active:hover,
  .datepicker table tr td span.active:hover.active,
  .datepicker table tr td span.active:hover.disabled,
  .datepicker table tr td span.active:hover:active,
  .datepicker table tr td span.active:hover:hover,
  .datepicker table tr td span.active:hover[disabled],
  .datepicker table tr td span.active[disabled], .datepicker table tr td.today, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today:hover {
    background-color: #536de6 !important;
    background-image: none !important;
    color: #fff; }
  .datepicker table tr td.day.focused, .datepicker table tr td.day:hover,
  .datepicker table tr td span.focused,
  .datepicker table tr td span:hover {
    background: #eef2f7; }
  .datepicker table tr td.new, .datepicker table tr td.old,
  .datepicker table tr td span.new,
  .datepicker table tr td span.old {
    color: #6c757d;
    opacity: 0.4; }
  .datepicker .datepicker-switch:hover, .datepicker .next:hover, .datepicker .prev:hover, .datepicker tfoot tr th:hover {
    background: #eef2f7; }
  .datepicker .datepicker-switch:hover {
    background: none; }

.datepicker-dropdown:after {
  border-bottom: 6px solid #fff; }

.datepicker-dropdown:before {
  border-bottom-color: #e7ebf0; }

.datepicker-dropdown.datepicker-orient-top:before {
  border-top: 7px solid #e7ebf0; }

.datepicker-dropdown.datepicker-orient-top:after {
  border-top: 6px solid #fff; }

[dir="rtl"] .daterangepicker.opensright:after {
  right: 10px;
  left: auto; }

[dir="rtl"] .daterangepicker.opensright:before {
  right: 9px;
  left: auto; }

.bg-dragula {
  background-color: #f7f9fb; }

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80); }

.gu-hide {
  display: none !important; }

.gu-unselectable {
  user-select: none !important; }

.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20); }

.dragula-handle {
  position: relative;
  width: 36px;
  height: 36px;
  font-size: 24px;
  text-align: center;
  cursor: move; }
  .dragula-handle:before {
    content: "\F01DB";
    font-family: "Material Design Icons";
    position: absolute; }

.dropzone {
  border: 2px dashed #dee2e6;
  background: #fff;
  border-radius: 6px;
  cursor: pointer;
  min-height: 150px;
  padding: 20px; }
  .dropzone .dz-message {
    text-align: center;
    margin: 2rem 0; }
  .dropzone.dz-started .dz-message {
    display: none; }

.form-wizard-header {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  background-color: #eef2f7; }

.gmaps,
.gmaps-panaroma {
  height: 300px;
  background: #f1f3fa;
  border-radius: 3px; }

.jvectormap-label {
  border: none;
  background: #343a40;
  color: #f1f3fa;
  font-family: "Nunito", sans-serif;
  font-size: 0.9rem;
  padding: 5px 8px; }

.ql-editor {
  text-align: left; }

.ql-container {
  font-family: "Nunito", sans-serif; }
  .ql-container.ql-snow {
    border-color: #dee2e6; }

.ql-bubble {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem; }

.ql-toolbar {
  font-family: "Nunito", sans-serif !important; }
  .ql-toolbar span {
    outline: none !important;
    color: #6c757d; }
    .ql-toolbar span:hover {
      color: #536de6 !important; }
  .ql-toolbar.ql-snow {
    border-color: #dee2e6; }
    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
      border-color: transparent; }

.ql-snow .ql-stroke,
.ql-snow .ql-script,
.ql-snow .ql-strike svg {
  stroke: #6c757d; }

.ql-snow .ql-fill {
  fill: #6c757d; }

.ql-snow .ql-picker-options {
  background-color: #fff;
  border-color: #e7ebf0 !important; }

.select2-container {
  width: 100% !important; }
  .select2-container .select2-selection--single {
    border: 1px solid #dee2e6;
    height: calc(1.5em + (0.9rem + 2px));
    background-color: #fff;
    outline: none; }
    .select2-container .select2-selection--single .select2-selection__rendered {
      line-height: 36px;
      padding-left: 12px;
      color: #6c757d; }
    .select2-container .select2-selection--single .select2-selection__arrow {
      height: 34px;
      width: 34px;
      /*rtl:ignore*/
      right: 3px; }
      .select2-container .select2-selection--single .select2-selection__arrow b {
        border-color: #98a6ad transparent transparent transparent;
        border-width: 6px 6px 0 6px; }

.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #98a6ad transparent !important;
  border-width: 0 6px 6px 6px !important; }

.select2-results__option {
  padding: 6px 12px; }

.select2-dropdown {
  border: 1px solid #e7ebf0;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  background-color: #fff; }

.select2-container--default .select2-search--dropdown {
  padding: 10px;
  background-color: white; }
  .select2-container--default .select2-search--dropdown .select2-search__field {
    outline: none;
    border: 1px solid #dee2e6;
    background-color: #fff;
    color: #6c757d; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #536de6; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: white;
  color: #323a46; }
  .select2-container--default .select2-results__option[aria-selected=true]:hover {
    background-color: #536de6;
    color: #fff; }

.select2-container .select2-selection--multiple {
  min-height: calc(1.5em + (0.9rem + 2px));
  border: 1px solid #dee2e6 !important;
  background-color: #fff; }
  .select2-container .select2-selection--multiple .select2-selection__rendered {
    padding: 1px 10px; }
  .select2-container .select2-selection--multiple .select2-search__field {
    border: 0;
    color: #6c757d; }
  .select2-container .select2-selection--multiple .select2-selection__choice {
    background-color: #536de6;
    border: none;
    color: #fff;
    border-radius: 3px;
    padding: 0 7px;
    margin-top: 6px; }
  .select2-container .select2-selection--multiple .select2-selection__choice__remove {
    color: #fff;
    margin-right: 5px; }
    .select2-container .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #fff; }

.select2-container .select2-search--inline .select2-search__field {
  margin-top: 7px; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  /*rtl:ignore*/
  right: 1px;
  /*rtl:ignore*/
  left: auto; }

[dir="rtl"] .select2-container--open .select2-dropdown {
  left: auto;
  right: 0; }

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start; }

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit; }

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0; }

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch; }

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  overflow: auto;
  /* Scroll on this element otherwise element can't have a padding applied properly */
  max-width: 100%;
  /* Not required for horizontal scroll to trigger */
  max-height: 100%;
  /* Needed for vertical scroll to trigger */
  scrollbar-width: none; }

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none; }

.simplebar-content:before,
.simplebar-content:after {
  content: ' ';
  display: table; }

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none; }

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0; }

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1; }

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden; }

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none; }

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all; }

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 5px;
  min-height: 10px; }
  .simplebar-scrollbar:before {
    position: absolute;
    content: '';
    background: #a2adb7;
    border-radius: 7px;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.2s linear; }

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear; }

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px; }
  .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    top: 2px;
    bottom: 2px; }

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px; }
  .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    right: auto;
    left: 0;
    top: 2px;
    height: 7px;
    min-height: 0;
    min-width: 10px;
    width: auto; }
    .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
      height: 100%;
      left: 2px;
      right: 2px; }

/* Rtl support */
[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0; }

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll; }

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none; }

.custom-scroll {
  height: 100%; }

[data-simplebar-lg] .simplebar-scrollbar {
  right: 1px;
  width: 10px; }

[data-simplebar-primary] .simplebar-scrollbar:before {
  background: #536de6; }

.jq-toast-single {
  text-align: left !important;
  padding: 15px;
  font-family: "Nunito", sans-serif;
  background-color: #536de6;
  font-size: 13px;
  line-height: 22px; }
  .jq-toast-single h2, .jq-toast-single .h2 {
    font-family: "Nunito", sans-serif; }
  .jq-toast-single a {
    font-size: 0.9rem; }
    .jq-toast-single a:hover {
      color: #fff; }

.jq-has-icon {
  padding: 10px; }

.close-jq-toast-single {
  position: absolute;
  top: -12px;
  right: -12px;
  font-size: 20px;
  cursor: pointer;
  height: 32px;
  width: 32px;
  background: #343a40;
  color: #f1f3fa;
  border-radius: 50%;
  text-align: center;
  line-height: 32px; }

.jq-toast-loader {
  height: 3px;
  top: 0;
  border-radius: 0; }

.jq-icon-primary {
  background-color: #536de6;
  color: #fff;
  border-color: #536de6; }

.jq-icon-secondary {
  background-color: #6c757d;
  color: #fff;
  border-color: #6c757d; }

.jq-icon-success {
  background-color: #10c469;
  color: #fff;
  border-color: #10c469; }

.jq-icon-info {
  background-color: #35b8e0;
  color: #fff;
  border-color: #35b8e0; }

.jq-icon-warning {
  background-color: #f9c851;
  color: #fff;
  border-color: #f9c851; }

.jq-icon-danger {
  background-color: #ff5b5b;
  color: #fff;
  border-color: #ff5b5b; }

.jq-icon-light {
  background-color: #eef2f7;
  color: #fff;
  border-color: #eef2f7; }

.jq-icon-dark {
  background-color: #323a46;
  color: #fff;
  border-color: #323a46; }

.jq-icon-error {
  background-color: #ff5b5b;
  color: #fff;
  border-color: #ff5b5b; }

.jq-icon-info, .jq-icon-warning, .jq-icon-error, .jq-icon-success {
  background-image: none; }

.bootstrap-touchspin .btn .input-group-text {
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit; }

.bootstrap-timepicker-widget table td input {
  height: 32px;
  width: 32px;
  color: #fff;
  background-color: #536de6;
  border-radius: 50%;
  border: 0;
  outline: none !important; }

.bootstrap-timepicker-widget table td a {
  color: #6c757d; }
  .bootstrap-timepicker-widget table td a:hover {
    background-color: transparent;
    border: 1px solid transparent;
    color: #536de6; }

.bootstrap-timepicker-widget.dropdown-menu:before {
  border-bottom: 7px solid #e7ebf0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent; }

.bootstrap-timepicker-widget.dropdown-menu:after {
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent; }

.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff; }

.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #e7ebf0; }

.editor-toolbar.fullscreen, .CodeMirror-fullscreen {
  z-index: 1000; }

.editor-preview, .editor-preview-side {
  background: #dee2e6; }

.editor-preview-active {
  background: #e5e8eb; }

.editor-toolbar {
  border: 1px solid #dee2e6;
  border-bottom: 0; }
  .editor-toolbar a {
    color: #6c757d !important; }
    .editor-toolbar a:hover, .editor-toolbar a.active {
      background-color: transparent;
      color: #536de6 !important;
      border-color: transparent; }
  .editor-toolbar i.separator {
    display: none; }
  .editor-toolbar.disabled-for-preview a:not(.no-disable) {
    background: transparent; }

.CodeMirror {
  border: 1px solid #dee2e6;
  background: #fff;
  color: #6c757d;
  min-height: 100px; }

.CodeMirror-cursor {
  border-left: 1px solid #343a40; }

.editor-statusbar {
  color: #343a40; }

.twitter-typeahead {
  display: inherit !important; }

.tt-query,
.tt-hint {
  outline: none; }

.tt-query {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.tt-hint {
  color: #6c757d;
  background: #fff !important; }

.tt-menu {
  width: 100%;
  padding: 8px 0;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  border: 1px solid #e7ebf0; }

.tt-suggestion {
  padding: 3px 20px;
  line-height: 24px; }
  .tt-suggestion:hover, .tt-suggestion.tt-cursor {
    cursor: pointer;
    color: #fff;
    background-color: #536de6; }
  .tt-suggestion p {
    margin: 0; }

.tt-highlight {
  font-family: "Nunito", sans-serif; }

.typeahead-empty-message {
  padding: 5px 10px;
  color: #ff5b5b; }

.league-name {
  padding: 3px 20px; }

.jqstooltip {
  box-sizing: content-box;
  width: auto !important;
  height: auto !important;
  background-color: #fff !important;
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  padding: 5px 10px !important;
  border-radius: 3px;
  border-color: #fff !important; }

.jqsfield {
  color: #000 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  font-family: "Nunito", sans-serif !important;
  font-weight: 700 !important; }

/* Timepicker */
.timepicker-orient-bottom {
  top: auto !important;
  bottom: calc(1.5em + (0.9rem + 2px)) !important; }

.bootstrap-timepicker-widget {
  left: 0 !important;
  right: auto !important; }

.bootstrap-timepicker-widget.timepicker-orient-left:before {
  left: 6px;
  right: auto; }

.bootstrap-timepicker-widget.timepicker-orient-left::after {
  left: 7px;
  right: auto; }

.rateit {
  display: -moz-inline-box;
  display: inline-block;
  position: relative;
  user-select: none;
  -webkit-touch-callout: none; }
  .rateit .rateit-range * {
    display: block; }
  .rateit .rateit-hover,
  .rateit .rateit-selected {
    position: absolute;
    left: 0;
    top: 0;
    width: 0; }
  .rateit .rateit-hover-rtl,
  .rateit .rateit-selected-rtl {
    left: auto;
    right: 0; }
  .rateit .rateit-hover {
    color: #f9c851; }
  .rateit .rateit-hover-rtl {
    background-position: right -32px; }
  .rateit .rateit-selected {
    color: #ff5b5b; }
  .rateit .rateit-selected-rtl {
    background-position: right -16px; }
  .rateit .rateit-preset {
    color: #fd7e14; }
  .rateit button.rateit-reset {
    width: 16px;
    height: 16px;
    float: left;
    outline: none;
    border: none;
    padding: 0; }
  .rateit .rateit-reset span {
    display: none; }
  .rateit .rateit-range {
    position: relative;
    display: -moz-inline-box;
    display: inline-block;
    height: 16px;
    outline: none; }
  .rateit.rateit-font .rateit-reset {
    width: .6em;
    height: .6em;
    margin-right: 5px;
    margin-top: 5px;
    background: #dee2e6;
    border-radius: 50%;
    position: relative; }
    .rateit.rateit-font .rateit-reset span {
      display: block;
      height: calc(50% - 0.045em);
      top: 2px;
      position: absolute;
      border-bottom: 2px solid #6c757d;
      width: 50%;
      margin-left: 25%;
      margin-right: 25%; }
  .rateit.rateit-font .rateit-reset:hover,
  .rateit.rateit-font button.rateit-reset:focus {
    background: #ff5b5b; }
    .rateit.rateit-font .rateit-reset:hover span,
    .rateit.rateit-font button.rateit-reset:focus span {
      border-color: #fff; }

.rateit-mdi {
  font-family: 'Material Design Icons'; }

.rateit-font {
  font-size: 24px;
  line-height: 1em; }
  .rateit-font .rateit-range {
    background: none;
    height: auto; }
    .rateit-font .rateit-range > div {
      background: none;
      overflow: hidden;
      cursor: default;
      white-space: nowrap; }
  .rateit-font .rateit-empty {
    color: #ced4da; }

.rateit .rateit-hover-rtl,
.rateit .rateit-selected-rtl {
  right: auto; }

.irs {
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 12px; }

.irs-line {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none !important; }

.irs-bar {
  position: absolute;
  display: block;
  left: 0;
  width: 0; }

.irs-shadow {
  position: absolute;
  display: none;
  left: 0;
  width: 0; }

.irs-handle {
  position: absolute;
  display: block;
  box-sizing: border-box;
  cursor: default;
  z-index: 1; }
  .irs-handle.type_last {
    z-index: 2; }

.irs-min,
.irs-max {
  position: absolute;
  display: block;
  cursor: default; }

.irs-min {
  left: 0;
  right: auto; }

.irs-max {
  right: 0;
  left: auto; }

[dir="rtl"] .irs-min {
  right: 0;
  left: auto; }

[dir="rtl"] .irs-max {
  left: 0;
  right: auto; }

.irs-from,
.irs-to,
.irs-single {
  position: absolute;
  display: block;
  top: 0;
  cursor: default;
  white-space: nowrap; }

.irs-grid {
  position: absolute;
  display: none;
  bottom: 0;
  /*rtl:ignore*/
  left: 0;
  width: 100%;
  height: 20px; }

.irs-with-grid .irs-grid {
  display: block; }

.irs-grid-pol {
  position: absolute;
  top: 0;
  /*rtl:ignore*/
  left: 0;
  width: 1px;
  height: 8px;
  background: #6c757d; }
  .irs-grid-pol.small {
    height: 4px; }

.irs-grid-text {
  position: absolute;
  bottom: 0;
  /*rtl:ignore*/
  left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 9px;
  line-height: 9px;
  padding: 0 3px;
  color: #6c757d; }

.irs-disable-mask {
  position: absolute;
  display: block;
  top: 0;
  left: -1%;
  width: 102%;
  height: 100%;
  cursor: default;
  background: rgba(0, 0, 0, 0);
  z-index: 2; }

.lt-ie9 .irs-disable-mask {
  background: #6c757d;
  filter: alpha(opacity=0);
  cursor: not-allowed; }

.irs-disabled {
  opacity: 0.4; }

.irs-hidden-input {
  position: absolute !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  outline: none !important;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: transparent !important; }

.irs--flat {
  height: 40px; }
  .irs--flat.irs-with-grid {
    height: 60px; }
  .irs--flat .irs-line {
    top: 25px;
    height: 12px;
    background-color: #eef2f7;
    border-radius: 4px; }
  .irs--flat .irs-bar {
    top: 25px;
    height: 12px;
    background-color: #536de6; }
  .irs--flat .irs-bar--single {
    border-radius: 4px 0 0 4px; }
  .irs--flat .irs-shadow {
    height: 1px;
    bottom: 16px;
    background-color: #eef2f7; }
  .irs--flat .irs-handle {
    top: 22px;
    width: 16px;
    height: 18px;
    background-color: transparent; }
  .irs--flat .irs-handle > i:first-child {
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    margin-left: -1px;
    background-color: #3453e1; }
  .irs--flat .irs-min,
  .irs--flat .irs-max {
    top: 0;
    padding: 1px 3px;
    color: #6c757d;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    background-color: #eef2f7;
    border-radius: 4px; }
  .irs--flat .irs-from,
  .irs--flat .irs-to,
  .irs--flat .irs-single {
    color: #fff;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    background-color: #536de6;
    border-radius: 4px; }
  .irs--flat .irs-from:before,
  .irs--flat .irs-to:before,
  .irs--flat .irs-single:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #536de6; }
  .irs--flat .irs-grid-pol {
    background-color: #eef2f7; }
  .irs--flat .irs-grid-text {
    color: #6c757d; }

[dir="rtl"] .irs-bar--single {
  border-radius: 0 4px 4px 0 !important; }

.jstree-default {
  padding: 2px 6px;
  height: auto; }
  .jstree-default .jstree-clicked, .jstree-default .jstree-hovered {
    background: #eef2f7;
    box-shadow: none; }
  .jstree-default .jstree-anchor, .jstree-default .jstree-icon, .jstree-default .jstree-icon:empty {
    line-height: 20px; }

.jstree-wholerow.jstree-wholerow-clicked, .jstree-wholerow.jstree-wholerow-hovered {
  background: #eef2f7; }

.gantt .bar-progress {
  fill: #536de6; }

.gantt .bar-wrapper:hover .bar-progress {
  fill: #6980e9; }

.gantt .bar, .gantt .bar-wrapper:hover .bar {
  fill: #eef2f7; }

.gantt .bar-label, .gantt .bar-label.big {
  fill: #6c757d; }

.gantt .grid-header {
  fill: #f1f3fa;
  stroke: #f1f3fa; }

.gantt .grid-row {
  fill: transparent; }
  .gantt .grid-row:nth-child(even) {
    fill: #f1f3fa; }

.gantt .lower-text, .gantt .upper-text {
  fill: #6c757d; }

.gantt .row-line {
  stroke: #eef2f7; }

.gantt .today-highlight {
  fill: #eef2f7; }

.gantt-container .popup-wrapper .pointer {
  display: none; }
